import { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import { Button, Collapse, Descriptions, Dropdown, Select, Space } from "antd";
import {
  CaretRightOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";

import styles from "./CourierTasks.module.scss";

import useTasks from "@/hooks/useTasks";
import { Block, Loader, Pagination } from "@/components/common";
import { useAppDispatch } from "@/hooks/redux";
import { logout } from "@/store/reducers/user/asyncActionCreators";
import { pages } from "@/root/consts";
import { TaskStatusEnum } from "@/root/types";
import { TaskActions } from "@/modules/CourierTasks/components";

const { Panel } = Collapse;
const { Option } = Select;

const CourierTasks: FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const {
    tasks,
    pages: pageCount,
    getTasksLoading,
  } = useTasks({ page, pageLimit: 25 });
  const [dateSort, setDateSort] = useState("asc");
  const [status, setStatus] = useState<string[]>([
    TaskStatusEnum["New"],
    TaskStatusEnum["In progress"],
  ]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const filteredByStatus = tasks.filter((task) => {
    if (!status.length) {
      return true;
    }
    return status.includes(task.taskStatus.taskStatusUUID);
  });

  const sortedByDate = filteredByStatus
    .slice()
    .sort(
      (a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
    );

  const handleLogout = () => {
    dispatch(logout());
    navigate(pages.login.path);
  };

  const getTaskStatusColor = (status: TaskStatusEnum, dueDate: string) => {
    if (
      status !== TaskStatusEnum.Completed &&
      new Date(dueDate).getTime() < new Date().getTime()
    ) {
      return "red";
    }
    if (status === TaskStatusEnum.New) {
      return "orange";
    }
    if (status === TaskStatusEnum["In progress"]) {
      return "blue";
    }
    if (status === TaskStatusEnum.Completed) {
      return "green";
    }

    return "grey";
  };

  const paginationRange = Math.floor(
    (window.innerWidth - 8 - 30 - 39 * 4) / 39
  );

  return (
    <>
      {getTasksLoading ? (
        <div style={{ height: "100vh" }}>
          <Loader center />
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.nav}>
            <Dropdown
              trigger={["click"]}
              menu={{
                items: [
                  {
                    key: "logout",
                    label: (
                      <Button
                        type="link"
                        icon={<LogoutOutlined />}
                        onClick={handleLogout}
                      >
                        {t("Вийти")}
                      </Button>
                    ),
                  },
                ],
              }}
            >
              <MenuUnfoldOutlined style={{ fontSize: 26 }} />
            </Dropdown>
            <Space size="small">
              {t("Сортувати за") + ":"}
              <Select
                style={{ minWidth: 100 }}
                value={dateSort}
                onSelect={setDateSort}
              >
                <Option value="desc">{t("За спаданням")}</Option>
                <Option value="asc">{t("За зростанням")}</Option>
              </Select>
            </Space>
            <Space size="small">
              {t("Статус") + ":"}
              <Select
                mode="multiple"
                style={{ minWidth: 110 }}
                value={status}
                onChange={setStatus}
              >
                <Option
                  style={{
                    background: "orange",
                    color: "rgba(255,255,255, .9)",
                  }}
                  value={TaskStatusEnum["New"]}
                >
                  {t("Нові")}
                </Option>
                <Option
                  style={{ background: "blue", color: "rgba(255,255,255, .9)" }}
                  value={TaskStatusEnum["In progress"]}
                >
                  {t("В процесі")}
                </Option>
                <Option
                  style={{
                    background: "green",
                    color: "rgba(255,255,255, .9)",
                  }}
                  value={TaskStatusEnum["Completed"]}
                >
                  {t("Завершені")}
                </Option>
              </Select>
            </Space>
          </div>
          <Collapse
            className={styles.collapse}
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            {(dateSort === "asc"
              ? sortedByDate
              : sortedByDate.slice().reverse()
            ).map((task) => {
              const color = getTaskStatusColor(
                task.taskStatus.taskStatusUUID as TaskStatusEnum,
                task.dueDate
              );
              return (
                <Fragment key={task.taskNumber}>
                  <Panel
                    key={task.taskNumber}
                    className={classNames({
                      [styles.panelGreen]: true,
                      [styles.panelOrange]: color === "orange",
                      [styles.panelRed]: color === "red",
                      [styles.panelBlue]: color === "blue",
                    })}
                    header={
                      <div className={styles.panelHeader}>
                        {task.dueDate && task.dueDate.slice(0, 16) + ", "}
                        {task.case.client.name && task.case.client.name + ", "}
                        {task.case.client.address &&
                          task.case.client.address + ", "}
                        {task.trackingStatus && task.trackingStatus.name + ","}
                      </div>
                    }
                  >
                    <Descriptions
                      key={task.taskNumber}
                      bordered
                      column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                    >
                      <Descriptions.Item label={t("Дата завершення")}>
                        {task.dueDate.slice(0, 16)}
                      </Descriptions.Item>
                      <Descriptions.Item label={t("Номер завдання")}>
                        {task.taskNumber}
                      </Descriptions.Item>
                      <Descriptions.Item label={t("Статус відстеження")}>
                        {task.trackingStatus.name}
                      </Descriptions.Item>
                      <Descriptions.Item label={t("Контрагент")}>
                        {task.case.client.name}
                      </Descriptions.Item>
                      <Descriptions.Item label={t("Номер замовлення")}>
                        {task.case.caseNumber}
                      </Descriptions.Item>
                      <Descriptions.Item label={t("Пацієнт")}>
                        {task.case.patient}
                      </Descriptions.Item>
                      <Descriptions.Item label={t("Статус завдання")}>
                        {task.taskStatus.name}
                      </Descriptions.Item>
                    </Descriptions>
                    <TaskActions task={task} page={page} />
                  </Panel>
                </Fragment>
              );
            })}
          </Collapse>
          <Block style={{ margin: "0 8px" }}>
            <Pagination
              currentPage={page}
              pages={pageCount || 1}
              paginationRange={paginationRange}
              setCurrentPage={setPage}
            />
          </Block>
        </div>
      )}
    </>
  );
};

export default CourierTasks;
