import React, { FC } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { QRCodeSVG } from "qrcode.react";

import styles from "./LaboratoryCase.module.scss";

import { Case } from "@/root/models/case";
import { TeethWidget } from "@/components";
import { TeethFormulaTypeEnum } from "@/components/CaseForm/components/Teeth";
import { JobItem } from "@/components/LaboratoryCase/components";
import useCombinedJobTeeth from "@/hooks/useCombinedJobTeeth";

interface LaboratoryCaseProps {
  medCase: Case;
}

const LaboratoryCase: FC<LaboratoryCaseProps> = ({ medCase }) => {
  const { t } = useTranslation();
  const allTeeth = useCombinedJobTeeth(medCase.jobs || []);

  if (!medCase) {
    return null;
  }

  return (
    <div style={{ margin: 10 }}>
      <h4 className={styles.title}>{t("Деталі замовлення")}:</h4>
      <div className={styles.container}>
        <div className={styles.details}>
          <div>
            <div className={styles.row}>
              <div className={styles.column}>
                {t("Дата створення")}: {medCase.arrival.slice(0, 10)}
              </div>
              <div className={styles.column}>
                {t("Дата завершення")}: {medCase.dueDate.slice(0, 10)}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.column}>
                {t("Номер замовлення")}: {medCase.caseNumber}
              </div>
              <div className={styles.column}>
                {t("Назва клінікі")}: {medCase.client?.name}
              </div>
            </div>
            <div className={styles.row}>
              {t("Лікар")}: {medCase.clientsEmployee?.name}
            </div>
            <div className={styles.row}>
              {t("Пацієнт")}: {medCase.patient?.name}
            </div>
          </div>
          <div>
            <QRCodeSVG value={medCase.caseNumber} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <TeethWidget
              formulaType={
                medCase.teethFormula.teethFormulaUUID ===
                TeethFormulaTypeEnum.child
                  ? "child"
                  : "adult"
              }
              chosenTeeth={allTeeth}
            />
          </div>
          <div className={classNames(styles.column, styles.withRows)}>
            {medCase.jobs.map((job) => (
              <JobItem key={job.jobUUID} job={job} />
            ))}
          </div>
        </div>
      </div>

      <div className={styles.container}>
        <strong>Description: {medCase.description}</strong>
      </div>

      {!!medCase.attachments.length && (
        <div>
          <strong>
            Case has files, watch it on online case. (Якщо містить файли)
          </strong>
        </div>
      )}
    </div>
  );
};

export default LaboratoryCase;
