import { FC } from "react";
import { useTranslation } from "react-i18next";

import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import DateSelect from "../DateSelect";

const DueDate: FC = ({}) => {
  const { t } = useTranslation();
  const { state, dispatch } = useCaseFormContext();

  const handleSelect = (value: string) => {
    dispatch({
      type: ActionCreatorTypes.SetDueDate,
      payload: value,
    });
  };

  return (
    <DateSelect
      name="dueDate"
      label={t("Дата завершення")}
      value={state.dueDate}
      onSelect={handleSelect}
    />
  );
};

export default DueDate;
