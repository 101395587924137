import { apiBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";
import { Patient } from "@/root/models/patient";

export interface CreatePatientPayload {
  name: string;
  birthDate: string;
  mail: string;
  phone: string;
}

export const PatientsApi = {
  getPatients: async (
    clientUUID?: string,
    name?: string
  ): Promise<Patient[]> => {
    const params: { clientUUID?: string; name?: string } = {};

    if (clientUUID) {
      params.clientUUID = clientUUID;
      params.name = name;
    }

    const data = await makeRequest<Patient[]>({
      url: apiBaseurl + "/patients",
      params: params,
    });
    return data;
  },

  create: async (payload: CreatePatientPayload): Promise<Patient> => {
    const data = await makeRequest<Patient>({
      url: apiBaseurl + "/patients",
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async ({
    id,
    payload,
  }: {
    id: string;
    payload: CreatePatientPayload;
  }): Promise<Patient> => {
    const data = await makeRequest<Patient>({
      url: apiBaseurl + `/patients/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/patients/${id}`,
      method: "delete",
    });
  },
};
