import { FC, useState } from "react";
import { t } from "i18next";

import { Button, Form, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import styles from "./SearchClientEmployee.module.scss";
import { CreateClientEmployeeModal } from "@/components";
import useClientEmployees from "@/hooks/useClientEmployees";
import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";

const { Option } = Select;

interface SearchClientEmployeeProps {}

const SearchClientEmployee: FC<SearchClientEmployeeProps> = ({}) => {
  const { clientEmployees, getClientEmployeesLoading } = useClientEmployees({});
  const [modal, setModal] = useState(false);
  const { state, dispatch } = useCaseFormContext();

  const handleSearch = (clientsEmployeeUUID: string, value: string) => {
    const employee = clientEmployees.find(
      (p) => p.clientsEmployeeUUID === clientsEmployeeUUID
    );
    if (employee) {
      if (
        employee.name.toLowerCase().includes(value.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  };

  const handleSelect = (value: string) => {
    const clientEmployee = clientEmployees.find(
      (e) => e.clientsEmployeeUUID === value
    );
    if (!clientEmployee) {
      return;
    }
    dispatch({
      type: ActionCreatorTypes.SetClientsEmployee,
      payload: clientEmployee,
    });
  };

  const handleClear = () => {
    dispatch({
      type: ActionCreatorTypes.SetClientsEmployee,
      payload: null,
    });
  };

  return (
    <div className={styles.container}>
      <Form.Item
        className={styles.formItem}
        labelCol={{ span: 10 }}
        label={t("Співробітник контрагента")}
      >
        <Select
          listHeight={1000}
          showSearch
          allowClear
          loading={getClientEmployeesLoading}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.value ? handleSearch(option.value as string, input) : false
          }
          value={state.clientsEmployee?.clientsEmployeeUUID}
          onSelect={handleSelect}
          onClear={handleClear}
        >
          {clientEmployees.map((employee) => (
            <Option
              key={employee.clientsEmployeeUUID}
              value={employee.clientsEmployeeUUID}
            >
              {employee.name}
            </Option>
          ))}
          {!clientEmployees.find(
            (e) =>
              e.clientsEmployeeUUID ===
              state.clientsEmployee?.clientsEmployeeUUID
          ) &&
            state.clientsEmployee && (
              <Option
                key={state.clientsEmployee.clientsEmployeeUUID}
                value={state.clientsEmployee.clientsEmployeeUUID}
              >
                {state.clientsEmployee.name}
              </Option>
            )}
        </Select>
      </Form.Item>
      <Button
        className="add-item-button"
        icon={<PlusOutlined />}
        onClick={() => setModal(true)}
      />
      <CreateClientEmployeeModal
        open={modal}
        onCancel={() => setModal(false)}
      />
    </div>
  );
};

export default SearchClientEmployee;
