import React, { FC, useState } from "react";

import styles from "../DashboardTable.module.scss";

import TasksTable from "../TasksTable";
import CasesTable from "../CasesTable";

interface ContentTablesProps {
  date: string;
}

const ContentTables: FC<ContentTablesProps> = ({ date }) => {
  const [chosenCaseUUID, setChosenCaseUUID] = useState<string>();

  return (
    <div className={styles.content}>
      <CasesTable
        date={date}
        chosenCaseUUID={chosenCaseUUID}
        onChoose={setChosenCaseUUID}
      />
      {chosenCaseUUID && <TasksTable caseUUID={chosenCaseUUID} />}
    </div>
  );
};

export default ContentTables;
