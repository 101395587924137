import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Alert, Form, FormInstance, Input } from "antd";
import { CreateAdminClientPayload } from "@/api/admin/clients";
import useFormAutoFocusInput from "@/hooks/useFormAutoFocusInput";

interface AdminClientFormProps {
  form: FormInstance<CreateAdminClientPayload>;
  handleSubmit: (values: CreateAdminClientPayload) => void;
  error: string;
}

const AdminClientForm: FC<AdminClientFormProps> = ({
  form,
  handleSubmit,
  error,
}) => {
  const { t } = useTranslation();
  const focusInputRef = useFormAutoFocusInput();

  return (
    <Form requiredMark={false} form={form} onFinish={handleSubmit}>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Введіть ім'я") as string,
          },
        ]}
        name="name"
        label={t("Ім'я")}
      >
        <Input
          ref={focusInputRef}
          placeholder={t("Введіть значення") as string}
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Введіть ім'я") as string,
          },
        ]}
        name="address"
        label={t("Адреса")}
      >
        <Input placeholder={t("Введіть значення") as string} />
      </Form.Item>

      {!!error && <Alert message={error} type="error" />}
    </Form>
  );
};

export default AdminClientForm;
