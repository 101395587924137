import React, { FC, useEffect, useRef, useState } from "react";
import { Button, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";

import styles from "../DashboardTable.module.scss";

import { Block, Pagination } from "@/components/common";
import { ColumnsType } from "antd/es/table";
import useTasks from "@/hooks/useTasks";
import { Task } from "@/root/models/task";
import { isLaptop } from "@/utils/isLaptop";
import { CreateTaskModal } from "@/components";
import { TableActions } from "@/modules/Tasks/components";
import { useAppSelector } from "@/hooks/redux";
import { UserTypeEnum } from "@/root/types";

const { Title } = Typography;

interface TasksTableProps {
  caseUUID: string;
}

const TasksTable: FC<TasksTableProps> = ({ caseUUID }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const userType = useAppSelector((state) => state.userReducer.user?.userType);
  const { tasks, pages, getTasksLoading } = useTasks({
    caseUUID,
    page,
    pageLimit: 25,
  });

  const [createTaskModal, setCreateTaskModal] = useState(false);

  const tableRef = useRef<HTMLDivElement>(null);
  const [paginationRange, setPaginationRange] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      setPaginationRange(
        tableRef.current
          ? Math.floor(
              (tableRef.current.getBoundingClientRect().width - 8 - 39 * 4) / 39
            )
          : 1
      );
    });
  }, [tableRef.current, getTasksLoading]);

  const tasksColumns: ColumnsType<Task> = [
    {
      width: 88,
      title: t("Дата завершення"),
      dataIndex: "dueDate",
      key: "dueDate",
      render: (date: Task["dueDate"]) => {
        if (date.slice(11) === "00:00:00") {
          return <span>{date.slice(0, 10)}</span>;
        }

        return (
          <div style={{ textAlign: "center" }}>
            <span>{date.slice(0, 10)}</span>
            <span style={{ fontSize: 13 }}>{date.slice(10)}</span>
          </div>
        );
      },
    },
    {
      title: t("Кур'єр"),
      dataIndex: "courier",
      key: "courier",
      render: (courier: Task["courier"]) => <span>{courier.name}</span>,
    },
    {
      title: t("Статус відстеження"),
      dataIndex: "trackingStatus",
      key: "trackingStatus",
      render: (trackingStatus: Task["trackingStatus"]) => (
        <span>{trackingStatus.name}</span>
      ),
    },
    {
      title: t("Статус завдання"),
      dataIndex: "taskStatus",
      key: "taskStatus",
      render: (status: Task["taskStatus"]) => <span>{status.name}</span>,
    },
  ];

  if (
    userType?.userTypeUUID === UserTypeEnum["Employee"] ||
    userType?.userTypeUUID === UserTypeEnum["Lab's admin"]
  ) {
    tasksColumns.push({
      width: 35,
      title: "",
      key: "action",
      render: (_: any, task: Task) => (
        <TableActions
          direction="vertical"
          task={task}
          caseUUID={caseUUID}
          page={page}
        />
      ),
    });
  }

  return (
    <Block style={{ paddingLeft: 5, paddingRight: 5, width: "40%" }}>
      <div className={styles.container}>
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "33%" }}>
              <Button onClick={() => setCreateTaskModal(true)}>
                {t("Створити завдання")}
              </Button>
            </div>
            <Title level={3} style={{ textAlign: "center" }}>
              {t("Завдання")}
            </Title>
          </div>
          <Table
            size="small"
            scroll={{
              y: isLaptop() ? "70vh" : "79vh",
            }}
            ref={tableRef}
            columns={tasksColumns}
            dataSource={tasks.map((c) => ({ ...c, key: c.taskUUID }))}
            pagination={false}
            loading={getTasksLoading}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            currentPage={page}
            pages={pages || 1}
            paginationRange={paginationRange}
            setCurrentPage={setPage}
          />
        </div>
      </div>
      <CreateTaskModal
        open={createTaskModal}
        onCancel={() => setCreateTaskModal(false)}
        caseUUID={caseUUID}
        page={page}
      />
    </Block>
  );
};

export default TasksTable;
