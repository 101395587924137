import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox, Collapse, Space } from "antd";

import styles from "./Paramaters.module.scss";

import {
  ActionCreatorTypes,
  CaseFormState,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import ParametersGroupItem from "./ParametersGroup";
import useFilters from "@/hooks/useFilters";
import Tooth from "@/components/CaseJobs/Tooth";

const { Panel } = Collapse;

interface ParametersProps {
  activeJobID: string;
  product: CaseFormState.Job.Product;
  activeTooth: string | null;
}

const Parameters: FC<ParametersProps> = ({
  activeJobID,
  product,
  activeTooth,
}) => {
  const { t } = useTranslation();
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const { state } = useCaseFormContext();
  const { dispatch } = useCaseFormContext();
  const { filters } = useFilters();

  useEffect(() => {
    if (product?.product) {
      setActiveKeys(
        product.product.productsParametersGroups.map(
          (group) => group.productsParametersGroupUUID
        )
      );
    }
  }, [product]);

  const isProduct = !!product;
  const isParameters =
    product &&
    product.product &&
    product.product.productsParametersGroups.length > 0;
  const shouldChooseTooth =
    product?.differentTeethParameters && !product.selectedTooth;

  const handleDifferentTeethParametersCheck = (checked: boolean) => {
    dispatch({
      type: ActionCreatorTypes.SetDifferentTeethParametersChecked,
      payload: {
        checked,
        activeJobID,
        activeProductID: product.jobProductUUID,
      },
    });
  };

  const handleToothSelect = (tooth: string) => {
    dispatch({
      type: ActionCreatorTypes.SetSelectedTooth,
      payload: {
        activeJobID,
        activeProductID: product.jobProductUUID,
        tooth,
      },
    });
  };

  return (
    <div className={styles.container}>
      {product && (
        <div className={styles.jobSettings}>
          <div className={styles.checkboxGroup}>
            <div>
              <Checkbox disabled checked={product.combinedProduct}>
                {t("Комбінований виріб")}
              </Checkbox>
            </div>
            <div>
              <Checkbox
                disabled={state.onlyDetailsEdit}
                checked={product.differentTeethParameters}
                onChange={(e) =>
                  handleDifferentTeethParametersCheck(e.target.checked)
                }
              >
                {t("Різні параметри зубів")}
              </Checkbox>
            </div>
          </div>
          {product.teeth && (
            <Space wrap>
              <span>{t("Зуби")}:</span>
              {product.teeth.map((tooth) => (
                <Tooth
                  key={tooth}
                  tooth={tooth}
                  selected={
                    product.differentTeethParameters &&
                    product.selectedTooth === tooth
                  }
                  onClick={() =>
                    product.differentTeethParameters && handleToothSelect(tooth)
                  }
                />
              ))}
            </Space>
          )}
        </div>
      )}
      {isProduct ? (
        isParameters ? (
          shouldChooseTooth ? (
            <span>{t("Оберіть зуб")}</span>
          ) : (
            <Collapse
              activeKey={activeKeys}
              onChange={(keys) =>
                typeof keys === "object" && setActiveKeys(keys as string[])
              }
            >
              {product.product?.productsParametersGroups.map((group) => (
                <Panel
                  header={group.name}
                  key={group.productsParametersGroupUUID}
                >
                  <ParametersGroupItem
                    activeTooth={activeTooth}
                    activeJobID={activeJobID}
                    activeProductID={product.jobProductUUID}
                    allFilters={filters}
                    group={group}
                  />
                </Panel>
              ))}
            </Collapse>
          )
        ) : (
          <span>{t("Виріб не містить параметрів")}</span>
        )
      ) : (
        <span>{t("Оберіть виріб")}</span>
      )}
    </div>
  );
};

export default Parameters;
