import { useEffect, useState } from "react";

import { WidgetCaseStatus } from "@/root/models/widgetCaseStatus";
import { WidgetCaseStatusApi } from "@/api/widgetCaseStatus";

const useCaseStatus = () => {
  const [statuses, setStatuses] = useState<WidgetCaseStatus[]>([]);
  const [loading, steLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        steLoading(true);
        const statuses = await WidgetCaseStatusApi.getStatuses();
        setStatuses(statuses);
      } catch (e) {
      } finally {
        steLoading(false);
      }
    })();
  }, []);

  return {
    statuses,
    loading,
  };
};

export default useCaseStatus;
