import { CaseFormState } from "@/components/CaseForm/CaseFormContext";
import { Job } from "@/root/models/case";
import { Product } from "@/root/models/product";

export const jobsToFormJobs = (
  jobs: Job[],
  findProductByID: (id: string) => Product | undefined,
  getParameterGroup: (product: Product) => Product
): CaseFormState.Job[] => {
  return (
    jobs.map((job) => {
      return {
        jobID: job.jobUUID,
        activeProductID: job.products[0].jobProductUUID,
        products: job.products.map((product) => {
          const fullProduct = findProductByID(product.product.productUUID);
          if (!fullProduct) {
            throw new Error(`Product ${product.product.name} does not exist`);
          }
          return {
            product: fullProduct,
            quantity: product.quantity,
            jobProductUUID: product.jobProductUUID,
            price: product.price,
            teethColor: fullProduct
              ? getParameterGroup(fullProduct).groupsColor
              : "",
            teeth: product.teeth,
            differentTeethParameters: product.differentTeethParameters,
            selectedTooth: product.teeth[0],
            combinedProduct: product.combinedProduct,
            productsParametersGroups: fullProduct
              ? fullProduct.productsParametersGroups.map((fullGroup) => {
                  const group = product.productsParametersGroups.find(
                    (g) =>
                      g.productsParametersGroup.productsParametersGroupUUID ===
                      fullGroup.productsParametersGroupUUID
                  );

                  return {
                    productsParametersGroupUUID:
                    fullGroup.productsParametersGroupUUID,
                    productsParameters:
                      fullGroup.productsParameters.flatMap<CaseFormState.ProductsParameter>(
                        (fullParameter) => {
                          if (product.differentTeethParameters) {
                            return product.teeth.map((tooth) => {
                              const parameter = group?.productsParameters.find(
                                (p) =>
                                  p.productsParameter.productsParameterUUID ===
                                    fullParameter.productsParameter
                                      .productsParameterUUID &&
                                  p.tooth === tooth
                              );
                              return {
                                productsParameterUUID:
                                  parameter?.productsParameter
                                    .productsParameterUUID ||
                                  fullParameter.productsParameter
                                    .productsParameterUUID,
                                value:
                                  parameter?.value ||
                                  fullParameter.productsParameter.defaultValue,
                                tooth,
                                required: fullParameter?.required ?? true,
                              };
                            });
                          }

                          const parameter = group?.productsParameters.find(
                            (p) =>
                              p.productsParameter.productsParameterUUID ===
                              fullParameter.productsParameter
                                .productsParameterUUID
                          );

                          return {
                            productsParameterUUID:
                              parameter?.productsParameter
                                .productsParameterUUID ||
                              fullParameter.productsParameter
                                .productsParameterUUID,
                            value:
                              parameter?.value ||
                              fullParameter.productsParameter.defaultValue,
                            tooth: null,
                            required: fullParameter?.required ?? true,
                          };
                        }
                      ),
                  };
                })
              : [],
          };
        }),
        jobName: job.jobName,
        spareParts: job.spareParts?.map((sparePart) => ({
          ...sparePart,
          id: window.crypto.randomUUID(),
          price: Number(sparePart.price?.replace(",", ".")),
        })),
        extras: job.extras?.map((extra) => ({
          ...extra,
          id: window.crypto.randomUUID(),
          price: Number(extra.price?.replace(",", ".")),
        })),
      };
    }) || []
  );
};
