import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { QrReader } from "react-qr-reader";

import { Alert, Button, Card, Modal, Select, Space, Typography } from "antd";
import { useForm } from "antd/es/form/Form";

import styles from "./TaskActions.module.scss";

import { Task } from "@/root/models/task";
import { TaskStatusEnum, TrackingStatusEnum } from "@/root/types";
import useTasks from "@/hooks/useTasks";
import { EditTaskPayload } from "@/api/tasks";
import EditTaskForm from "@/modules/CourierTasks/components/EditStatusForm";
import TrackingStatus = Task.TrackingStatus;
import useTrackingStatuses from "@/hooks/useTrackingStatuses";

interface TaskActionsProps {
  task: Task;
  page: number;
}

const { Text } = Typography;
const { Option } = Select;

const TaskActions: FC<TaskActionsProps> = ({ task, page }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { trackingStatuses } = useTrackingStatuses();
  const [editModal, setEditModal] = useState(false);
  const [qrModal, setQrModal] = useState(false);
  const [scanError, setScanError] = useState(false);
  const [newStatus, setNewStatus] = useState<TrackingStatus>();

  const onEditSuccess = () => {
    setEditModal(false);
  };

  const { editTask, editLoading, editError } = useTasks({
    onEditSuccess,
    page,
    pageLimit: 25,
  });

  const handleEdit = async (values: EditTaskPayload) => {
    editTask(task.taskUUID, values);
  };

  const submit = () => {
    form.submit();
  };

  const openModal = () => {
    form.setFieldsValue({
      taskStatusUUID: task.taskStatus.taskStatusUUID,
      trackingStatusUUID: task.trackingStatus.trackingStatusUUID,
    });
    setEditModal(true);
  };

  const handleQrRead = (value: string) => {
    if (value !== task.case.caseNumber) {
      setScanError(true);
      return;
    }

    let status = task.trackingStatus.trackingStatusUUID;

    if (status === TrackingStatusEnum["Waiting for pickup"]) {
      status = TrackingStatusEnum["Heading to the lab"];
    } else if (status === TrackingStatusEnum["Heading to the lab"]) {
      status = TrackingStatusEnum["At lab"];
    } else if (status === TrackingStatusEnum["At lab"]) {
      status = TrackingStatusEnum["Heading to the clinic"];
    } else if (status === TrackingStatusEnum["Heading to the clinic"]) {
      status = TrackingStatusEnum["Delivered"];
    }

    if (trackingStatuses) {
      const trackingStatus = trackingStatuses.find(
        (s) => s.trackingStatusUUID === status
      );
      trackingStatus && setNewStatus(trackingStatus);
    }
  };

  const handleScanModalOk = () => {
    if (scanError) {
      setScanError(false);
      return;
    }

    if (newStatus) {
      setNewStatus(undefined);
      setQrModal(false);
      handleEdit({ trackingStatusUUID: newStatus.trackingStatusUUID });
    }
  };

  const handleAcceptClick = () => {
    editTask(task.taskUUID, { taskStatusUUID: TaskStatusEnum["In progress"] });
  };

  const handleCancel = () => {
    setQrModal(false);
    setScanError(false);
    setNewStatus(undefined);
  };

  const handleNewStatusSelect = (id: string) => {
    if (trackingStatuses) {
      const trackingStatus = trackingStatuses.find(
        (s) => s.trackingStatusUUID === id
      );
      trackingStatus && setNewStatus(trackingStatus);
    }
  };

  return (
    <>
      <div className={styles.container}>
        {task.taskStatus.taskStatusUUID === TaskStatusEnum.New && (
          <Button loading={editLoading} onClick={handleAcceptClick}>
            {t("Прийняти")}
          </Button>
        )}
        <Button onClick={() => setQrModal(true)}>{t("Відсканувати")}</Button>
        <Button loading={editLoading} onClick={openModal}>
          {t("Редагувати завдання")}
        </Button>
      </div>
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={
          <div style={{ marginBottom: 20 }}>{t("Редагування завдання")}</div>
        }
        open={editModal}
        onCancel={() => setEditModal(false)}
        onOk={submit}
        okButtonProps={{ loading: editLoading }}
      >
        <EditTaskForm form={form} handleSubmit={handleEdit} error={editError} />
      </Modal>
      <Modal
        title={
          scanError ? (
            <Alert
              style={{ marginTop: 20 }}
              type="error"
              description={t(
                "Будь-ласка відскануйте код відповідного замовлення"
              )}
            />
          ) : undefined
        }
        destroyOnClose
        open={qrModal}
        onCancel={handleCancel}
        onOk={handleScanModalOk}
        okButtonProps={{
          hidden: !scanError && !newStatus,
        }}
        okText={
          scanError
            ? t("Повторити сканування")
            : newStatus
            ? t("Застосувати")
            : undefined
        }
      >
        {scanError ? null : newStatus ? (
          <Space
            size="large"
            direction="vertical"
            align="center"
            style={{ width: "100%" }}
          >
            <Space direction="vertical" size="small">
              <Text strong style={{ fontSize: 16 }}>
                {t("Поточний статус відстеження")}:
              </Text>
              <Card size="small">{task.trackingStatus.name}</Card>
            </Space>
            <Space direction="vertical" size="small">
              <Text strong style={{ fontSize: 16 }}>
                {t("Новий статус відстеження")}:
              </Text>
              <Select
                value={newStatus.trackingStatusUUID}
                onSelect={handleNewStatusSelect}
              >
                {trackingStatuses.map((status) => (
                  <Option
                    key={status.trackingStatusUUID}
                    value={status.trackingStatusUUID}
                  >
                    {status.name}
                  </Option>
                ))}
              </Select>
            </Space>
          </Space>
        ) : (
          <QrReader
            constraints={{ facingMode: "environment" }}
            onResult={(result) =>
              result?.getText() && handleQrRead(result.getText())
            }
          />
        )}
      </Modal>
    </>
  );
};

export default TaskActions;
