import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { MenuProps } from "antd";
import {
  BlockOutlined,
  DollarOutlined,
  FileSearchOutlined, OrderedListOutlined,
  PieChartOutlined,
  SettingOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import {
  AdminClients,
  AdminSettings,
  ProductCatalog,
  Users,
  Parts,
  MainPanel,
  CasesPanel, Tasks,
} from "@/modules";
import DashboardPage from "../DashboardPage";
import { pages } from "@/root/consts";
import PricelistPanel from "@/modules/PricelistPanel";

const AdminDashboardPage: FC = () => {
  const { t } = useTranslation();

  const navigation: MenuProps["items"] = [
    {
      key: pages.admin.dashboard.path,
      label: t("Головна панель"),
      icon: <PieChartOutlined />,
    },
    {
      key: pages.admin.clients.path,
      label: t("Контрагенти"),
      icon: <TeamOutlined />,
    },
    {
      key: pages.admin.employees.path,
      label: t("Співробітники"),
      icon: <TeamOutlined />,
    },
    {
      key: pages.admin.productCatalog.path,
      label: t("Каталог виробів"),
      icon: <UnorderedListOutlined />,
    },
    {
      key: pages.admin.parts.path,
      label: t("Матеріали"),
      icon: <BlockOutlined />,
    },
    {
      key: pages.admin.pricelist.path,
      label: t("Прайс лист"),
      icon: <DollarOutlined />,
    },
    {
      key: pages.admin.cases.path,
      label: t("Замовлення"),
      icon: <FileSearchOutlined />,
    },
    {
      key: pages.admin.tasks.path,
      label: t("Завдання"),
      icon: <OrderedListOutlined />,
    },
    {
      key: pages.admin.settings.path,
      label: t("Налаштування"),
      icon: <SettingOutlined />,
    },
  ];

  return (
    <DashboardPage navigation={navigation}>
      <Routes>
        <Route path="dashboard/*" element={<MainPanel />} />
      </Routes>
      <Routes>
        <Route path="clients/*" element={<AdminClients />} />
      </Routes>
      <Routes>
        <Route path="employees/*" element={<Users />} />
      </Routes>
      <Routes>
        <Route path="product-catalog/*" element={<ProductCatalog />} />
      </Routes>
      <Routes>
        <Route path="parts/*" element={<Parts />} />
      </Routes>
      <Routes>
        <Route path="cases/*" element={<CasesPanel />} />
      </Routes>
      <Routes>
        <Route path="tasks/*" element={<Tasks />} />
      </Routes>
      <Routes>
        <Route path="settings/*" element={<AdminSettings />} />
      </Routes>
      <Routes>
        <Route path="pricelist/*" element={<PricelistPanel />} />
      </Routes>
    </DashboardPage>
  );
};

export default AdminDashboardPage;
