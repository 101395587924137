import React, {
  FC,
  Fragment,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import { Space } from "antd";

import styles from "./TeethWidget.module.scss";

import Tooth, { ITooth } from "./components/Tooth";
import adultTeethSwg from "@/components/TeethWidget/adultTeeth";
import childTeethSwg from "@/components/TeethWidget/childTeeth";
import { groupTeethByJob } from "@/utils/groupToothByJob";
import { getJawTeeth } from "@/utils/getJawTeeth";
import { jaws } from "@/root/consts";
import { JawType } from "@/root/types";

export interface ChosenTooth {
  toothID: string;
  isPoint: boolean;
  color: string | null;
  crown: boolean;
  selected: boolean;
  group?: string;
}

interface TeethWidgetProps {
  chosenTeeth: ChosenTooth[];
  toothRender?: (tooth: ITooth) => ReactNode;
  viewBox?: { adult?: string; child?: string };
  onClick?: (id: ITooth) => void;
  formulaType?: "child" | "adult";
  legends?: { name: string; color: string }[];
}

const swgTeethToWidgetTeeth = (formulaType?: "child" | "adult"): ITooth[] => {
  return (formulaType === "adult" ? adultTeethSwg : childTeethSwg).map(
    ({
      position,
      id,
      tooth,
      connector,
      extraProductCircle,
      implantCircle,
      crown,
      number,
      jaw,
    }) => ({
      position,
      id,
      jaw,
      connectorNode: connector,
      toothNode: tooth,
      extraProductCircleNode: extraProductCircle,
      implantCircleNode: implantCircle,
      crownNode: crown,
      numberNode: number,
      isPoint: false,
      tapped: false,
      arc: false,
      isCrown: false,
      selected: false,
      isConnected: false,
      containAnotherProduct: false,
      color: null,
    })
  );
};

const TeethWidget: FC<TeethWidgetProps> = ({
  toothRender,
  chosenTeeth,
  viewBox,
  formulaType,
  onClick,
  legends,
}) => {
  const [teeth, setTeeth] = useState<ITooth[]>(
    swgTeethToWidgetTeeth(formulaType)
  );

  useEffect(() => {
    setTeeth(swgTeethToWidgetTeeth(formulaType));
  }, [formulaType]);

  useEffect(() => {
    // if jaw is chosen than extract all the teeth from this jaw
    const allTeeth: ChosenTooth[] = chosenTeeth
      .slice()
      .reverse()
      .flatMap((tooth) => {
        if (jaws.includes(tooth.toothID as JawType)) {
          return getJawTeeth(tooth.toothID as JawType).map((toothID) => ({
            toothID,
            isPoint: tooth.isPoint,
            selected: tooth.selected,
            crown: tooth.crown,
            color: tooth.color,
            group: tooth.group,
          }));
        }
        return tooth;
      });

    const groupedTeeth = groupTeethByJob(allTeeth);
    const groupedTeethPositions = groupedTeeth
      .filter((teethGroup) => teethGroup.every((t) => t.crown))
      .map((teethGroup) => {
        const chosenToothPositions = teethGroup.map((tooth) => {
          const foundTooth = teeth.find((t) => t.id === tooth.toothID);
          return foundTooth?.position;
        });

        if (!chosenToothPositions) {
          throw new Error("Unable to find tooth positions.");
        }
        const validPositions = chosenToothPositions.filter(Boolean) as number[];
        const jobToothMax = Math.max(...validPositions);
        const jobToothMin = Math.min(...validPositions);

        return [jobToothMin, jobToothMax];
      });

    const getToothIndexInGroup = (tooth: ITooth) => {
      return groupedTeethPositions.findIndex((p) => {
        const jobToothMin = p[0];
        const jobToothMax = p[1];
        return Boolean(
          jobToothMax &&
            jobToothMin &&
            tooth &&
            tooth.position <= jobToothMax &&
            tooth.position >= jobToothMin
        );
      });
    };

    setTeeth((teeth) => {
      const newTeeth = [];
      for (let i = 0; i < teeth.length; i++) {
        const tooth = teeth[i];
        const chosenTeethById = allTeeth.filter((t) => t.toothID === tooth.id);
        const chosenToothIndex = allTeeth.findIndex(
          (t) => t.toothID === tooth.id
        );
        tooth.arc =
          chosenTeeth.some((t) => t.crown) &&
          groupedTeethPositions.some((p) => {
            const jobToothMin = p[0];
            const jobToothMax = p[1];
            return Boolean(
              jobToothMax &&
                jobToothMin &&
                tooth.position < jobToothMax &&
                tooth.position > jobToothMin
            );
          });
        tooth.tapped = !!chosenTeethById.length;
        tooth.isCrown = chosenTeethById.some((t) => t.crown);
        const anotherProduct =
          chosenTeethById[0] &&
          allTeeth
            .slice(chosenToothIndex + 1)
            .find((t) => t.toothID === chosenTeethById[0].toothID);
        tooth.containAnotherProduct = !!(
          chosenTeethById.length && anotherProduct
        );
        tooth.anotherProductColor = anotherProduct?.color || undefined;
        tooth.color = chosenTeethById.length ? chosenTeethById[0].color : null;
        tooth.isPoint = chosenTeethById.length
          ? chosenTeethById[0].isPoint
          : false;
        tooth.selected = chosenTeethById[0]?.selected || false;

        tooth.isConnected =
          (tooth.arc || tooth.isCrown) &&
          (teeth[i - 1]?.arc || teeth[i - 1]?.isCrown) &&
          groupedTeeth[getToothIndexInGroup(tooth)] ===
            groupedTeeth[getToothIndexInGroup(teeth[i - 1])];
        tooth.productUUID = chosenTeethById[0]?.group;

        newTeeth.push(tooth);
      }
      return newTeeth;
    });
  }, [chosenTeeth.length, chosenTeeth]);

  const legendsRef = useRef<HTMLDivElement>(null);

  const height = legendsRef.current?.offsetHeight;

  const adultViewBox = viewBox?.adult ? viewBox.adult : "40 35 440 605";
  const childViewBox = viewBox?.child ? viewBox.child : "5 30 350 315";

  return (
    <>
      <svg
        style={{
          display: "block",
          height: height ? `calc(100% - ${height}px` : "100%",
        }}
        width={"100%"}
        viewBox={formulaType === "adult" ? adultViewBox : childViewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {teeth.map((tooth) => (
          <Fragment key={tooth.id}>
            {toothRender ? (
              toothRender(tooth)
            ) : (
              <Tooth tooth={tooth} onClick={() => onClick?.(tooth)} />
            )}
          </Fragment>
        ))}
      </svg>
      {!!legends?.length && (
        <div ref={legendsRef} className={styles.legends}>
          {legends.map(
            (legend) =>
              legend.name && (
                <Space key={legend.name}>
                  <div
                    style={{
                      background: legend.color,
                      width: 20,
                      height: 20,
                      borderRadius: 20,
                    }}
                  />
                  -<span>{legend.name}</span>
                </Space>
              )
          )}
        </div>
      )}
    </>
  );
};

export default TeethWidget;
