import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "antd";

import { CreateTaskModal } from "@/components";

interface CreateTaskProps {
  caseUUID?: string;
  isCaseAccepted?: boolean;
  page?: number;
}

const CreateTask: FC<CreateTaskProps> = ({
  caseUUID,
  isCaseAccepted,
  page,
}) => {
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <Button onClick={() => setModal(true)}>{t("Створити завдання")}</Button>
      <CreateTaskModal
        open={modal}
        onCancel={() => setModal(false)}
        caseUUID={caseUUID}
        isCaseAccepted={isCaseAccepted}
        page={page}
      />
    </div>
  );
};

export default CreateTask;
