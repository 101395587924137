import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { LabDashboardPage } from "@/pages";

const LabPage: FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<LabDashboardPage />} />
    </Routes>
  );
};

export default LabPage;
