import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Alert, Button, Form, Input } from "antd";
import Title from "antd/lib/typography/Title";

import styles from "./LoginPage.module.scss";

import { LoadingStatus, UserTypeEnum } from "@/root/types";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { login } from "@/store/reducers/user/asyncActionCreators";
import { pages } from "@/root/consts";
import { DCInfo } from "@/components";
import IntroCarousel from "@/pages/LoginPage/Carousel";

const LoginPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loading = useAppSelector((state) => state.userReducer.loginLoading);
  const error = useAppSelector((state) => state.userReducer.loginError);
  const isAuth = useAppSelector((state) => state.userReducer.user?.name);
  const userType = useAppSelector((state) => state.userReducer.user?.userType);

  const onFinish = async (values: { login: string; password: string }) => {
    dispatch(login(values.login, values.password));
  };
  useEffect(() => {
    if (isAuth && userType) {
      switch (userType.userTypeUUID) {
        case UserTypeEnum["Client's employee"]:
        case UserTypeEnum["Client's admin"]:
          navigate(pages.client.dashboard.path);
          break;
        case UserTypeEnum["Employee"]:
          navigate(pages.lab.dashboard.path);
          break;
        case UserTypeEnum["Lab's admin"]:
          navigate(pages.admin.dashboard.path);
          break;
        case UserTypeEnum['Courier']:
          navigate(pages.courier.dashboard.path);
          break;
      }
    }
  }, [isAuth, userType]);

  return (
    <div className={styles.container}>
      <IntroCarousel />
      <div className={styles.formContainer}>
        <Form name="login" className={styles.form} onFinish={onFinish}>
          <DCInfo centered />
          <Title level={4} className={styles.title}>
            {t("Авторизація")}
          </Title>
          <Form.Item
            label="Login"
            name="login"
            rules={[{ required: true, message: t("Заповніть поле") as string }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: t("Заповніть поле") as string }]}
          >
            <Input.Password />
          </Form.Item>

          {!!error && (
            <Alert
              className={styles.errorMessage}
              message={error}
              type="error"
            />
          )}

          <Form.Item>
            <Button
              disabled={loading === LoadingStatus.LOADING}
              loading={loading === LoadingStatus.LOADING}
              className={styles.submit}
              type="primary"
              htmlType="submit"
            >
              {t("Ввійти")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
