import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { DatePicker, Form, FormInstance, Input, Select } from "antd";

import styles from "./CaseDetails.module.scss";

import { EmployeeCase } from "@/root/models/case";
import { DATE_FORMAT } from "@/root/consts";
import usePans from "@/hooks/usePans";
import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import useEmployees from "@/hooks/useEmployees";
import useAdminClients from "@/hooks/useAdminClients";
import { ClientEmployeesApi } from "@/api/client/employees";
import { PatientsApi } from "@/api/patients";
import { Patient } from "@/root/models/patient";
import { ClientEmployee } from "@/root/models/clientEmployee";
import { AdminClient } from "@/root/models/adminClient";
import SelectTeethFormulaType from "@/components/CaseForm/components/SelectTeethFormulaType";
import useCaseStatuses from "@/hooks/useCaseStatuses";
import { useAppSelector } from "@/hooks/redux";
import { CaseStatusEnum, UserTypeEnum } from "@/root/types";

const { Option } = Select;

interface CaseDetails {
  medCase: EmployeeCase | null;
  form: FormInstance;
  grandTotal: number | null;
}

const CaseDetails: FC<CaseDetails> = ({ medCase, form, grandTotal }) => {
  const { t } = useTranslation();
  const { pans, getPansLoading, refetch: panRefetch } = usePans();
  const { state, dispatch } = useCaseFormContext();
  const { employees, getEmployeesLoading } = useEmployees();
  const { adminClients, getAdminClientsLoading } = useAdminClients({});
  const [getUsersLoading, setGetUsersLoading] = useState(false);
  const [patients, setPatients] = useState<Patient[]>();
  const [clientEmployees, setClientEmployees] = useState<ClientEmployee[]>();
  const { statuses, loading: getStatusesLoading } = useCaseStatuses();
  const userType = useAppSelector((state) => state.userReducer.user?.userType);

  useEffect(() => {
    if (!state.status && statuses.length) {
      const status = statuses.find((s) => s.statusUUID === CaseStatusEnum.New);
      if (!status) {
        return;
      }
      dispatch({
        type: ActionCreatorTypes.SetStatus,
        payload: status,
      });
    }
  }, [statuses]);

  const handleDueDateSelect = (value: string) => {
    dispatch({
      type: ActionCreatorTypes.SetDueDate,
      payload: value,
    });
  };

  const handlePanSelect = (value: string) => {
    dispatch({
      type: ActionCreatorTypes.SetPan,
      payload: value,
    });
  };

  const handlePanClear = () => {
    dispatch({
      type: ActionCreatorTypes.SetPan,
      payload: null,
    });
  };

  const handlePatientSelect = (value: string) => {
    const patient = patients?.find((e) => e.patientUUID === value);
    if (!patient) {
      return;
    }
    dispatch({
      type: ActionCreatorTypes.SetPatient,
      payload: patient,
    });
  };

  const handleEmployeeSelect = (value: string) => {
    const employee = employees.find((e) => e.employeeUUID === value);
    if (!employee) {
      return;
    }
    dispatch({
      type: ActionCreatorTypes.SetEmployee,
      payload: employee,
    });
  };

  const handleEmployeeClear = () => {
    dispatch({
      type: ActionCreatorTypes.SetEmployee,
      payload: null,
    });
  };

  const setClientsAndPatients = useCallback(async (client: AdminClient) => {
    try {
      setGetUsersLoading(true);
      const clientEmployees = await ClientEmployeesApi.getEmployees(
        client.clientUUID
      );
      const patients = await PatientsApi.getPatients(client.clientUUID);

      setClientEmployees(clientEmployees);
      setPatients(patients);
    } finally {
      setGetUsersLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!adminClients.length) {
      return;
    }
    const client = adminClients.find(
      (e) => e.clientUUID === medCase?.client.clientUUID
    );
    if (!client) {
      return;
    }

    setClientsAndPatients(client);
  }, [adminClients]);

  const handleClientSelect = (value: string) => {
    const client = adminClients.find((e) => e.clientUUID === value);
    if (!client) {
      return;
    }
    dispatch({
      type: ActionCreatorTypes.SetClient,
      payload: client,
    });

    dispatch({
      type: ActionCreatorTypes.SetPatient,
      payload: null,
    });
    dispatch({
      type: ActionCreatorTypes.SetClientsEmployee,
      payload: null,
    });
    form.setFieldValue("clientsEmployeeUUID", undefined);
    form.setFieldValue("patientUUID", undefined);

    setClientsAndPatients(client);
  };

  const handleClientEmployeeSelect = (value: string) => {
    const employee = clientEmployees?.find(
      (e) => e.clientsEmployeeUUID === value
    );
    if (!employee) {
      return;
    }
    dispatch({
      type: ActionCreatorTypes.SetClientsEmployee,
      payload: employee,
    });
  };

  const handleClientEmployeeClear = () => {
    dispatch({
      type: ActionCreatorTypes.SetClientsEmployee,
      payload: null,
    });
  };

  const handleStatusSelect = (statusUUID: string) => {
    const status = statuses.find((s) => s.statusUUID === statusUUID);
    if (!status) {
      return;
    }
    dispatch({
      type: ActionCreatorTypes.SetStatus,
      payload: status,
    });
  };

  const availablePans = pans.filter((pan) => !pan.case.caseUUID);

  return (
    <div className={styles.details}>
      {grandTotal !== null && (
        <strong className={styles.total}>
          {t("Загальна сума")}: {grandTotal}
        </strong>
      )}
      <Form.Item labelCol={{ span: 10 }} label={t("Номер замовлення")}>
        <Input disabled value={state.caseNumber || undefined} />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 10 }}
        label={t("Дата створення")}
        name="arrival"
        initialValue={dayjs(new Date(state.arrival))}
      >
        <DatePicker
          format={DATE_FORMAT}
          onSelect={(v) => handleDueDateSelect(dayjs(v).format(DATE_FORMAT))}
        />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 10 }}
        requiredMark="optional"
        name="dueDate"
        initialValue={dayjs(new Date(state.dueDate))}
        label={t("Дата завершення")}
        rules={[{ required: true, message: t("Заповніть поле") as string }]}
      >
        <DatePicker
          format={DATE_FORMAT}
          onSelect={(v) => handleDueDateSelect(dayjs(v).format(DATE_FORMAT))}
        />
      </Form.Item>
      <Form.Item
        name="panUUID"
        labelCol={{ span: 10 }}
        label={t("Лоток")}
        initialValue={state.panUUID || undefined}
      >
        <Select
          allowClear
          loading={getPansLoading}
          listHeight={1000}
          onSelect={handlePanSelect}
          onFocus={() => panRefetch()}
          onClear={handlePanClear}
        >
          {availablePans.map((pan) => (
            <Option key={pan.panUUID} value={pan.panUUID}>
              {pan.name}
            </Option>
          ))}
          {!availablePans.some((pan) => pan.panUUID === state.panUUID) &&
            medCase &&
            "panUUID" in medCase.pan && (
              <Option key={medCase.pan.panUUID} value={medCase.pan.panUUID}>
                {medCase.pan.name}
              </Option>
            )}
        </Select>
      </Form.Item>
      <Form.Item
        name="clientUUID"
        labelCol={{ span: 10 }}
        initialValue={state.client?.clientUUID}
        label={t("Контрагент")}
        rules={[{ required: true, message: t("Заповніть поле") as string }]}
      >
        <Select
          listHeight={1000}
          showSearch
          loading={getAdminClientsLoading}
          optionFilterProp="children"
          onSelect={handleClientSelect}
        >
          {adminClients.map((client) => (
            <Option key={client.clientUUID} value={client.clientUUID}>
              {client.name}
            </Option>
          ))}
          {!adminClients.find(
            (e) => e.clientUUID === state.client?.clientUUID
          ) &&
            state.client && (
              <Option
                key={state.client.clientUUID}
                value={state.client.clientUUID}
              >
                {state.client.name}
              </Option>
            )}
        </Select>
      </Form.Item>
      <Form.Item
        name="clientsEmployeeUUID"
        labelCol={{ span: 10 }}
        initialValue={state.clientsEmployee?.clientsEmployeeUUID}
        label={t("Лікар")}
      >
        <Select
          allowClear
          listHeight={1000}
          showSearch
          disabled={!clientEmployees}
          loading={getUsersLoading}
          optionFilterProp="children"
          onSelect={handleClientEmployeeSelect}
          onClear={handleClientEmployeeClear}
        >
          {clientEmployees?.map((clientEmployee) => (
            <Option
              key={clientEmployee.clientsEmployeeUUID}
              value={clientEmployee.clientsEmployeeUUID}
            >
              {clientEmployee.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="patientUUID"
        labelCol={{ span: 10 }}
        initialValue={state.patient?.patientUUID}
        label={t("Пацієнт")}
        rules={[{ required: true, message: t("Заповніть поле") as string }]}
      >
        <Select
          listHeight={1000}
          showSearch
          disabled={!patients}
          loading={getUsersLoading}
          optionFilterProp="children"
          onSelect={handlePatientSelect}
        >
          {patients?.map((patient) => (
            <Option key={patient.patientUUID} value={patient.patientUUID}>
              {patient.name}
            </Option>
          ))}
          {!patients?.find(
            (e) => e.patientUUID === state.patient?.patientUUID
          ) &&
            state.patient && (
              <Option
                key={state.patient.patientUUID}
                value={state.patient.patientUUID}
              >
                {state.patient.name}
              </Option>
            )}
        </Select>
      </Form.Item>
      <Form.Item
        name="employeeUUID"
        labelCol={{ span: 10 }}
        initialValue={state.activeHandler?.activeHandlerUUID}
        label={t("Відповідальний")}
      >
        <Select
          allowClear
          listHeight={1000}
          showSearch
          loading={getEmployeesLoading}
          optionFilterProp="children"
          onSelect={handleEmployeeSelect}
          onClear={handleEmployeeClear}
        >
          {employees.map((employee) => (
            <Option key={employee.employeeUUID} value={employee.employeeUUID}>
              {employee.name}
            </Option>
          ))}
          {!employees.find(
            (e) => e.employeeUUID === state.activeHandler?.activeHandlerUUID
          ) &&
            state.activeHandler && (
              <Option
                key={state.activeHandler.activeHandlerUUID}
                value={state.activeHandler.activeHandlerUUID}
              >
                {state.activeHandler.activeHandlerName}
              </Option>
            )}
        </Select>
      </Form.Item>
      <div className={styles.teethFormula}>
        <SelectTeethFormulaType />
      </div>
      {userType?.userTypeUUID === UserTypeEnum["Employee"] && (
        <Form.Item labelCol={{ span: 10 }} label={t("Статус")}>
          <Select
            listHeight={1000}
            showSearch
            loading={getStatusesLoading}
            optionFilterProp="children"
            value={state.status?.statusUUID}
            onSelect={handleStatusSelect}
          >
            {statuses.map((status) => (
              <Option key={status.statusUUID} value={status.statusUUID}>
                {status.name || " "}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </div>
  );
};

export default CaseDetails;
