export const pages = {
  login: {
    path: "/login",
  },
  client: {
    path: "/client",
    dashboard: {
      path: "/client/dashboard",
    },
    cases: {
      path: "/client/cases",
      create: {
        path: "/client/cases/create",
      },
      edit: {
        path: "/client/cases/edit",
      },
    },
    myCompany: {
      path: "/client/my-company",
    },
    reports: {
      path: "/client/reports",
    },
  },
  admin: {
    path: "/admin",
    dashboard: {
      path: "/admin/dashboard",
    },
    clients: {
      path: "/admin/clients",
    },
    employees: {
      path: "/admin/employees",
    },
    productCatalog: {
      path: "/admin/product-catalog",
    },
    parts: {
      path: "/admin/parts",
    },
    cases: {
      path: "/admin/cases",
    },
    tasks: {
      path: "/admin/tasks",
    },
    settings: {
      path: "/admin/settings",
    },
    pricelist: {
      path: "/admin/pricelist",
    },
  },
  lab: {
    path: "/lab",
    dashboard: {
      path: "/lab/dashboard",
    },
    clients: {
      path: "/lab/clients",
    },
    cases: {
      path: "/lab/cases",
      create: {
        path: "/lab/cases/create",
      },
      edit: {
        path: "/lab/cases/edit",
      },
    },
    tasks: {
      path: "/lab/tasks",
    },
    pricelist: {
      path: "/lab/pricelist",
    },
  },
  courier: {
    path: "/courier",
    dashboard: {
      path: "/courier/dashboard",
    },
  },
};

export const DATE_FORMAT = "YYYY-MM-DD";
export const TOOTH_COLOR = "transparent";

export const adultFormulaTeethIDs = [
  "FDI_11",
  "FDI_12",
  "FDI_13",
  "FDI_14",
  "FDI_15",
  "FDI_16",
  "FDI_17",
  "FDI_18",
  "FDI_21",
  "FDI_22",
  "FDI_23",
  "FDI_24",
  "FDI_25",
  "FDI_26",
  "FDI_27",
  "FDI_28",
  "FDI_31",
  "FDI_32",
  "FDI_33",
  "FDI_34",
  "FDI_35",
  "FDI_36",
  "FDI_37",
  "FDI_38",
  "FDI_41",
  "FDI_42",
  "FDI_43",
  "FDI_44",
  "FDI_45",
  "FDI_46",
  "FDI_47",
  "FDI_48",
  "FDI_AUJ",
  "FDI_ALJ",
];

export const childFormulaTeethIDs = [
  "FDI_51",
  "FDI_52",
  "FDI_53",
  "FDI_54",
  "FDI_55",
  "FDI_61",
  "FDI_62",
  "FDI_63",
  "FDI_64",
  "FDI_65",
  "FDI_71",
  "FDI_72",
  "FDI_73",
  "FDI_74",
  "FDI_75",
  "FDI_81",
  "FDI_82",
  "FDI_83",
  "FDI_84",
  "FDI_85",
  "FDI_CUJ",
  "FDU_CLJ",
];

export const jaws = ["FDI_AUJ", "FDI_ALJ", "FDI_CUJ", "FDI_CLJ"] as const;
