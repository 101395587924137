import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CreateProductPayload, ProductsApi } from "@/api/admin/products";
import { Product } from "@/root/models/product";

const useEditProduct = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const editProductMutation = useMutation({
    mutationFn: ProductsApi.edit,
    onSuccess: (product) => {
      queryClient.setQueryData(["products"], (prev) => {
        const prevProducts = (prev as Product[]) || [];
        const recursiveEdit = (products: Product[]): Product[] => {
          return products.map((prevProduct) => {
            const productCopy = { ...prevProduct };
            if (productCopy.productUUID === product.productUUID) {
              return product;
            } else {
              if (productCopy.children) {
                productCopy.children = recursiveEdit(productCopy.children);
              }
              return productCopy;
            }
          });
        };

        return recursiveEdit(prevProducts);
      });
      onSuccess();
    },
  });

  const editProduct = (id: string, payload: CreateProductPayload) => {
    editProductMutation.mutate({ id, payload });
  };

  const error = (editProductMutation.error as Error)?.message as string;

  return { editProduct, loading: editProductMutation.isLoading, error };
};

export default useEditProduct;
