import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CreateUserPayload, UsersApi } from "@/api/admin/users";
import { User } from "@/root/models/user";

const useAddUser = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const createUserMutation = useMutation({
    mutationFn: UsersApi.create,
    onSuccess: (user) => {
      queryClient.setQueryData(["users"], (prev: User[] | undefined) => {
        if (!prev) {
          return [user];
        }
        return [...prev, user];
      });
      onSuccess();
    },
  });

  const addUser = (payload: CreateUserPayload) => {
    createUserMutation.mutate(payload);
  };

  const error = (createUserMutation.error as Error)?.message as string;

  return { addUser, loading: createUserMutation.isLoading, error };
};

export default useAddUser;
