import { FC, memo } from "react";

import { Pie } from "@ant-design/plots";

import useCaseStatus from "@/components/WidgetCaseStatus/useCaseStatus";

const WidgetCaseStatus: FC = memo(() => {
  const { statuses } = useCaseStatus();

  const data = statuses.map((status) => ({
    type: status.name,
    value: status.quantity,
  }));

  const config = {
    style: {
      width: 240,
    },
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    legend: false,
    label: {
      type: "inner",
      offset: "-50%",
      content: ({ type, value }: { type: string; value: string }) =>
        `${type}; ${value}`,
      style: {
        textAlign: "center",
        fontSize: 14,
        fill: "black",
        lineWidth: 300,
      },
      layout: [{ type: "interval-hide-overlap" }],
    },
    interactions: [
      {
        type: "element-single-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: "Cases progress",
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "none",
        },
      },
    },
    // @ts-ignore
    onReady: (plot) => {
      // @ts-ignore
      plot.on("element:click", ({ data }) => {});
    },
  };

  return (
    // @ts-ignore
    <Pie {...config} />
  );
});

export default WidgetCaseStatus;
