import { createContext, Dispatch, useContext } from "react";
import { t } from "i18next";
import dayjs from "dayjs";

import { Product, Product as FullProduct } from "@/root/models/product";
import { ProductParameter } from "@/root/models/productParameter";
import { DATE_FORMAT, jaws } from "@/root/consts";
import { Case } from "@/root/models/case";
import { Job as CaseJob } from "@/root/models/case";
import { JawType } from "@/root/types";
import { getJawTeeth } from "@/utils/getJawTeeth";
import { AdminClient } from "@/root/models/adminClient";
import { ClientEmployee } from "@/root/models/clientEmployee";
import { Employee } from "@/root/models/employee";
import { Patient } from "@/root/models/patient";
import { Extra } from "@/root/models/extra";
import { Part } from "@/root/models/part";
import { CaseStatus } from "@/root/models/caseStatus";

export namespace CaseFormState {
  export interface ProductsParameter {
    productsParameterUUID: string;
    value: ProductParameter["defaultValue"];
    tooth: string | null;
    required: boolean;
  }

  export namespace Job {
    export interface Extra extends Omit<CaseJob.Extra, "price"> {
      id: string;
      price: number | null;
      error?: string;
    }

    export interface Part extends Omit<CaseJob.Part, "price"> {
      id: string;
      price: number | null;
      error?: string;
    }

    export interface Product {
      jobProductUUID: string;
      product?: FullProduct;
      differentTeethParameters: boolean;
      combinedProduct: boolean;
      teethColor?: string;
      teeth?: string[];
      teethStatus?: "deleted" | "added";
      selectedTooth?: string;
      productsParametersGroups?: {
        productsParametersGroupUUID: string;
        productsParameters: ProductsParameter[];
      }[];
      price?: number | null;
      error?: string;
      quantity: number;
    }
  }

  export interface Job {
    jobID: string;
    jobName: string;
    products: Job.Product[];
    error?: string;
    extras?: Job.Extra[];
    spareParts?: Job.Part[];
    activeProductID?: string | null;
    selectedTeeth?: string[];
  }

  export interface Attachment {
    fileUUID: string;
    filename: string;
  }
}

export interface CaseFormState {
  onlyDetailsEdit: boolean;
  saveButtonActive: boolean;
  caseNumber: string | null;
  status: CaseStatus | null;
  panUUID: string | null;
  caseUUID: string | null;
  client: Case["client"] | null;
  clientsEmployee: Case["clientsEmployee"] | null;
  activeHandler: Case["activeHandler"] | null;
  patient: Case["patient"] | null;
  description: string;
  teethFormulaUUID: string | null;
  teethFormulaInputDisabled: boolean;
  arrival: string;
  dueDate: string;
  attachments: CaseFormState.Attachment[];
  jobs: CaseFormState.Job[];
  activeJobID: string | null;
}

export const caseFormInitialState: CaseFormState = {
  onlyDetailsEdit: true,
  saveButtonActive: true,
  caseNumber: null,
  status: null,
  caseUUID: null,
  client: null,
  clientsEmployee: null,
  activeHandler: null,
  patient: null,
  teethFormulaUUID: null,
  teethFormulaInputDisabled: false,
  description: "",
  arrival: dayjs(new Date()).format(DATE_FORMAT),
  dueDate: dayjs(new Date()).format(DATE_FORMAT),
  attachments: [],
  jobs: [],
  activeJobID: null,
  panUUID: null,
};

export enum ActionCreatorTypes {
  SetOnlyDetailsEdit = "SetOnlyDetailsEdit",
  SetState = "SetState",
  ClearErrors = "ClearErrors",
  SetSaveButtonActive = "SetSaveButtonActive",
  SetCaseUUID = "SetCaseUUID",
  SetClient = "SetClient",
  SetClientsEmployee = "SetClientsEmployee",
  SetEmployee = "SetEmployee",
  SetPatient = "SetPatient",
  SetDescription = "SetDescription",
  SetDate = "SetDate",
  SetDueDate = "SetDueDate",
  SetStatus = "SetStatus",
  AddAttachment = "AddAttachment",
  DeleteAttachment = "DeleteAttachment",
  SetTeethFormulaUUID = "SetTeethFormulaUUID",
  SetTooth = "SetTooth",
  SetJobSelectedTooth = "SetJobSelectedTooth",
  AddJobExtra = "AddJobExtra",
  DeleteJobExtra = "DeleteJobExtra",
  AddJobSparePart = "AddJobSparePart",
  DeleteJobSparePart = "DeleteJobSparePart",
  EditExtraPrice = "EditExtraPrice",
  EditSparePartPrice = "EditSparePartPrice",
  AddJob = "AddJob",
  EditJob = "EditJob",
  DeleteJob = "DeleteJob",
  SetActiveJob = "SetActiveJob",
  SetActiveProduct = "SetActiveProduct",
  SetProductPrice = "SetProductPrice",
  SetProduct = "SetProduct",
  AddProduct = "AddProduct",
  AddProductWithJobSelectedTeeth = "AddProductWithJobSelectedTeeth",
  DeleteProduct = "DeleteProduct",
  ReplaceProducts = "ReplaceProducts",
  SetProductQuantity = "SetProductQuantity",
  SetParameter = "SetParameter",
  SetError = "SetError",
  SetDifferentTeethParametersChecked = "SetDifferentTeethParametersChecked",
  SetCombinedProductChecked = "SetCombinedProductChecked",
  SetSelectedTooth = "SetSelectedTooth",
  SetPan = "SetPan",
}

interface SetOnlyDetailsEditAction {
  type: ActionCreatorTypes.SetOnlyDetailsEdit;
  payload: boolean;
}

interface SetStateAction {
  type: ActionCreatorTypes.SetState;
  payload: CaseFormState;
}

interface ClearErrorsAction {
  type: ActionCreatorTypes.ClearErrors;
}

interface SetSaveButtonActiveAction {
  type: ActionCreatorTypes.SetSaveButtonActive;
  payload: boolean;
}

interface SetCaseUUIDAction {
  type: ActionCreatorTypes.SetCaseUUID;
  payload: string;
}

interface SetClientAction {
  type: ActionCreatorTypes.SetClient;
  payload: AdminClient;
}

interface SetClientsEmployeeAction {
  type: ActionCreatorTypes.SetClientsEmployee;
  payload: ClientEmployee | null;
}

interface SetEmployeeAction {
  type: ActionCreatorTypes.SetEmployee;
  payload: Employee | null;
}

interface SetPatientAction {
  type: ActionCreatorTypes.SetPatient;
  payload: Patient | null;
}

interface SetDescriptionAction {
  type: ActionCreatorTypes.SetDescription;
  payload: string;
}

interface SetDateAction {
  type: ActionCreatorTypes.SetDate;
  payload: string;
}

interface SetDueDateAction {
  type: ActionCreatorTypes.SetDueDate;
  payload: string;
}

interface SetStatusAction {
  type: ActionCreatorTypes.SetStatus;
  payload: CaseStatus | null;
}

interface SetPanAction {
  type: ActionCreatorTypes.SetPan;
  payload: string | null;
}

interface AddAttachmentAction {
  type: ActionCreatorTypes.AddAttachment;
  payload: CaseFormState.Attachment;
}

interface DeleteAttachmentAction {
  type: ActionCreatorTypes.DeleteAttachment;
  payload: string;
}

interface SetTeethFormulaUUIDAction {
  type: ActionCreatorTypes.SetTeethFormulaUUID;
  payload: string;
}

interface SetToothAction {
  type: ActionCreatorTypes.SetTooth;
  payload: {
    activeJobID?: string;
    activeProductID?: string;
    tooth: string;
  };
}

interface SetJobSelectedToothAction {
  type: ActionCreatorTypes.SetJobSelectedTooth;
  payload: {
    activeJobID?: string;
    tooth: string;
  };
}

interface AddJobExtraAction {
  type: ActionCreatorTypes.AddJobExtra;
  payload: {
    jobID: string;
    price: number | undefined;
    extra: Extra;
  };
}

interface EditExtraPriceAction {
  type: ActionCreatorTypes.EditExtraPrice;
  payload: {
    jobID: string;
    extraID: string;
    price: number | null;
  };
}

interface AddJobSparePartAction {
  type: ActionCreatorTypes.AddJobSparePart;
  payload: {
    jobID: string;
    part: Part;
  };
}

interface EditSparePartPriceAction {
  type: ActionCreatorTypes.EditSparePartPrice;
  payload: {
    jobID: string;
    partID: string;
    price: number | null;
  };
}

interface DeleteJobExtraAction {
  type: ActionCreatorTypes.DeleteJobExtra;
  payload: {
    jobID: string;
    extraID: string;
  };
}

interface DeleteJobSparePartAction {
  type: ActionCreatorTypes.DeleteJobSparePart;
  payload: {
    jobID: string;
    partID: string;
  };
}

interface AddJobAction {
  type: ActionCreatorTypes.AddJob;
  payload: string;
}

interface EditJobAction {
  type: ActionCreatorTypes.EditJob;
  payload: {
    jobID: string;
    jobName: string;
  };
}

interface DeleteJobAction {
  type: ActionCreatorTypes.DeleteJob;
  payload: string;
}

interface SetActiveJobAction {
  type: ActionCreatorTypes.SetActiveJob;
  payload: string;
}

interface SetActiveProductAction {
  type: ActionCreatorTypes.SetActiveProduct;
  payload: {
    productUUID: string;
    jobUUID: string;
  };
}

interface SetActiveJobAction {
  type: ActionCreatorTypes.SetActiveJob;
  payload: string;
}

interface SetProductPriceAction {
  type: ActionCreatorTypes.SetProductPrice;
  payload: {
    jobID: string;
    productUUID: string;
    price: number | null;
  };
}

interface SetProductAction {
  type: ActionCreatorTypes.SetProduct;
  payload: {
    activeJobID?: string;
    productUUID?: string;
    product: Product;
    teethColor: string | undefined;
  };
}

interface AddProductAction {
  type: ActionCreatorTypes.AddProduct;
  payload: {
    activeJobID?: string;
    product?: Product;
    teethColor?: string;
  };
}

interface AddProductWithJobSelectedTeethAction {
  type: ActionCreatorTypes.AddProductWithJobSelectedTeeth;
  payload: {
    activeJobID?: string;
    product?: Product;
    teethColor?: string;
    move: boolean;
  };
}

interface DeleteProductAction {
  type: ActionCreatorTypes.DeleteProduct;
  payload: {
    activeJobID?: string;
    productUUID?: string;
  };
}

interface ReplaceProductAction {
  type: ActionCreatorTypes.ReplaceProducts;
  payload: {
    jobID: string;
    sourceIndex: number;
    destinationIndex: number;
  };
}

interface SetProductQuantityAction {
  type: ActionCreatorTypes.SetProductQuantity;
  payload: {
    activeJobID?: string;
    activeProductUUID?: string;
    quantity: number;
  };
}

interface SetErrorAction {
  type: ActionCreatorTypes.SetError;
  payload: {
    jobID: string;
    product?: string;
    sparePartUUID?: string;
    extraUUID?: string;
    message: string;
  };
}

interface SetParameterAction {
  type: ActionCreatorTypes.SetParameter;
  payload: {
    activeJobID?: string;
    activeProductID?: string;
    groupUUID: string;
    parameterUUID: string;
    tooth: string | null;
    value: string | number | boolean | string[] | null;
  };
}

interface SetDifferentTeethParametersCheckedAction {
  type: ActionCreatorTypes.SetDifferentTeethParametersChecked;
  payload: {
    activeJobID?: string;
    activeProductID?: string;
    checked: boolean;
  };
}

interface SetCombinedProductCheckedAction {
  type: ActionCreatorTypes.SetCombinedProductChecked;
  payload: {
    activeJobID?: string;
    activeProductID?: string;
    checked: boolean;
  };
}

interface SetSelectedToothAction {
  type: ActionCreatorTypes.SetSelectedTooth;
  payload: {
    tooth: string;
    activeJobID: string;
    activeProductID: string;
  };
}

type Actions =
  | SetOnlyDetailsEditAction
  | SetStateAction
  | ClearErrorsAction
  | SetSaveButtonActiveAction
  | SetCaseUUIDAction
  | SetClientAction
  | SetClientsEmployeeAction
  | SetEmployeeAction
  | SetDescriptionAction
  | SetPatientAction
  | SetDateAction
  | SetDueDateAction
  | SetStatusAction
  | SetPanAction
  | AddAttachmentAction
  | DeleteAttachmentAction
  | SetTeethFormulaUUIDAction
  | SetToothAction
  | SetJobSelectedToothAction
  | AddJobExtraAction
  | EditExtraPriceAction
  | AddJobSparePartAction
  | EditSparePartPriceAction
  | DeleteJobExtraAction
  | DeleteJobSparePartAction
  | AddJobAction
  | EditJobAction
  | DeleteJobAction
  | SetProductQuantityAction
  | SetActiveJobAction
  | SetActiveProductAction
  | SetProductPriceAction
  | SetProductAction
  | AddProductAction
  | AddProductWithJobSelectedTeethAction
  | DeleteProductAction
  | ReplaceProductAction
  | SetParameterAction
  | SetErrorAction
  | SetDifferentTeethParametersCheckedAction
  | SetCombinedProductCheckedAction
  | SetSelectedToothAction;

const checkIfTeethFormulaInputDisabled = (jobs: CaseFormState.Job[]) => {
  return jobs.some((job) =>
    job.products.some((product) => product.teeth?.length)
  );
};

const getJobName = (job: CaseFormState.Job) => {
  return job.products
    .map((product) => `${product.quantity} x ${product.product?.name}`)
    .join("; ");
};

export const CaseFormContext = createContext<{
  state: CaseFormState;
  dispatch: Dispatch<Actions>;
}>({
  state: caseFormInitialState,
  dispatch: () => null,
});

export const caseFormReducer = (state: CaseFormState, action: Actions) => {
  state.saveButtonActive = true;
  switch (action.type) {
    case ActionCreatorTypes.SetOnlyDetailsEdit:
      return { ...state, onlyDetailsEdit: action.payload };
    case ActionCreatorTypes.SetState:
      return action.payload;
    case ActionCreatorTypes.ClearErrors: {
      return {
        ...state,
        jobs: state.jobs.map((job) => ({
          ...job,
          products: job.products.map((product) => ({ ...product, error: "" })),
          error: "",
        })),
      };
    }
    case ActionCreatorTypes.SetSaveButtonActive:
      return { ...state, saveButtonActive: action.payload };
    case ActionCreatorTypes.SetCaseUUID:
      return { ...state, caseUUID: action.payload };
    case ActionCreatorTypes.SetClient:
      return {
        ...state,
        client: {
          clientUUID: action.payload.clientUUID,
          name: action.payload.name,
        },
      };
    case ActionCreatorTypes.SetClientsEmployee:
      return { ...state, clientsEmployee: action.payload };
    case ActionCreatorTypes.SetEmployee:
      return { ...state, employee: action.payload };
    case ActionCreatorTypes.SetPatient:
      return { ...state, patient: action.payload };
    case ActionCreatorTypes.SetDescription:
      return { ...state, description: action.payload };
    case ActionCreatorTypes.SetDate:
      return { ...state, arrival: action.payload };
    case ActionCreatorTypes.SetDueDate:
      return { ...state, dueDate: action.payload };
    case ActionCreatorTypes.SetPan:
      return { ...state, panUUID: action.payload };
    case ActionCreatorTypes.SetStatus:
      return { ...state, status: action.payload };
    case ActionCreatorTypes.AddAttachment:
      return { ...state, attachments: [...state.attachments, action.payload] };
    case ActionCreatorTypes.DeleteAttachment:
      return {
        ...state,
        attachments: state.attachments.filter(
          (att) => att.filename !== action.payload
        ),
      };
    case ActionCreatorTypes.SetTeethFormulaUUID:
      return { ...state, teethFormulaUUID: action.payload };
    case ActionCreatorTypes.SetTooth: {
      const activeJobID = action.payload.activeJobID || state.activeJobID;
      const tooth = action.payload.tooth;
      let jobs = state.jobs.map((job) => {
        if (job.jobID !== activeJobID) {
          return job;
        }
        const activeProductID =
          action.payload.activeProductID || job.activeProductID;

        if (!job.products.length) {
          const productID = window.self.crypto.randomUUID();
          job.activeProductID = productID;
          job.products = [
            {
              productsParametersGroups: [],
              teeth: [action.payload.tooth],
              differentTeethParameters: false,
              combinedProduct: false,
              quantity: 1,
              jobProductUUID: productID,
            },
          ];
        } else {
          job.products = job.products.map((product) => {
            if (product.jobProductUUID !== activeProductID) {
              return product;
            }

            let teeth: string[];

            if (product.teeth) {
              if (product.teeth.includes(tooth)) {
                teeth = product.teeth.filter((t) => t !== tooth);
                job.selectedTeeth = job.selectedTeeth?.filter(
                  (t) => t !== tooth
                );
              } else {
                teeth = [...product.teeth, tooth];
              }
            } else {
              teeth = [tooth];
            }

            if (jaws.includes(action.payload.tooth as JawType)) {
              teeth = teeth.filter(
                (tooth) =>
                  !getJawTeeth(action.payload.tooth as JawType).includes(tooth)
              );
            }

            const updatedProduct = {
              ...product,
              selectedTooth:
                product.differentTeethParameters && tooth
                  ? tooth
                  : product.selectedTooth,
              teeth,
              teethStatus: (teeth.includes(action.payload.tooth)
                ? "added"
                : "deleted") as "added" | "deleted",
              productsParametersGroups:
                product.differentTeethParameters && product.product
                  ? product.productsParametersGroups?.map((group) => {
                      const toothParameters = product
                        .product!.productsParametersGroups.find(
                          (g) =>
                            g.productsParametersGroupUUID ===
                            group.productsParametersGroupUUID
                        )
                        ?.productsParameters.map((parameter) => ({
                          productsParameterUUID:
                            parameter.productsParameter.productsParameterUUID,
                          tooth,
                          value: parameter.productsParameter.defaultValue,
                          required: parameter.required,
                        }));

                      const productsParameters = [...group.productsParameters];
                      if (toothParameters) {
                        productsParameters.push(...toothParameters);
                      }
                      return {
                        ...group,
                        productsParameters: productsParameters.filter(
                          (p) => p.tooth
                        ),
                      };
                    })
                  : product.productsParametersGroups,
            };

            if (
              updatedProduct.teeth?.length &&
              updatedProduct.error ===
                t("Оберіть зуби виробу для") + product.product?.name
            ) {
              updatedProduct.error = "";
            }

            return updatedProduct;
          });
        }
        return job;
      });

      if (!jobs.length) {
        const jobID = window.self.crypto.randomUUID();
        const productID = window.self.crypto.randomUUID();

        const newJob = {
          jobID,
          jobName: "",
          activeProductID: productID,
          products: [
            {
              productsParametersGroups: [],
              teeth: [action.payload.tooth],
              differentTeethParameters: false,
              combinedProduct: false,
              quantity: 1,
              jobProductUUID: productID,
            },
          ],
        };

        state.activeJobID = jobID;

        jobs = [newJob];
      }

      return {
        ...state,
        jobs,
        teethFormulaInputDisabled: checkIfTeethFormulaInputDisabled(state.jobs),
      };
    }
    case ActionCreatorTypes.SetJobSelectedTooth: {
      const activeJobID = action.payload.activeJobID || state.activeJobID;
      const tooth = action.payload.tooth;
      let jobs = state.jobs.map((job) => {
        if (job.jobID !== activeJobID) {
          return job;
        }

        if (job.selectedTeeth) {
          if (job.selectedTeeth.includes(tooth)) {
            job.selectedTeeth = job.selectedTeeth.filter((t) => t !== tooth);
          } else {
            job.selectedTeeth.push(tooth);
          }
        } else {
          job.selectedTeeth = [tooth];
        }

        return job;
      });

      return {
        ...state,
        jobs,
      };
    }
    case ActionCreatorTypes.AddJobExtra: {
      return {
        ...state,
        jobs: state.jobs.map((job) => {
          if (job.jobID === action.payload.jobID) {
            const extra = {
              extra: action.payload.extra,
              price: action.payload.price ?? 0,
              id: window.crypto.randomUUID(),
            };
            job.extras = job.extras ? [...job.extras, extra] : [extra];
          }
          return job;
        }),
      };
    }
    case ActionCreatorTypes.EditExtraPrice: {
      return {
        ...state,
        jobs: state.jobs.map((job) => {
          if (job.jobID === action.payload.jobID) {
            const extra = job.extras?.find(
              (e) => e.id === action.payload.extraID
            );
            if (extra) {
              extra.price = action.payload.price;
              if (action.payload.price) {
                extra.error = "";
              }
            }
          }
          return job;
        }),
      };
    }
    case ActionCreatorTypes.AddJobSparePart: {
      return {
        ...state,
        jobs: state.jobs.map((job) => {
          if (job.jobID === action.payload.jobID) {
            const part = {
              sparePart: action.payload.part,
              price: 0,
              id: window.crypto.randomUUID(),
            };
            job.spareParts = job.spareParts
              ? [...job.spareParts, part]
              : [part];
          }
          return job;
        }),
      };
    }
    case ActionCreatorTypes.DeleteJobExtra: {
      return {
        ...state,
        jobs: state.jobs.map((job) => {
          if (job.jobID === action.payload.jobID) {
            job.extras = job.extras?.filter(
              (extra) => extra.id !== action.payload.extraID
            );
          }
          return job;
        }),
      };
    }
    case ActionCreatorTypes.EditSparePartPrice: {
      return {
        ...state,
        jobs: state.jobs.map((job) => {
          if (job.jobID === action.payload.jobID) {
            const sparePart = job.spareParts?.find(
              (e) => e.id === action.payload.partID
            );
            if (sparePart) {
              sparePart.price = action.payload.price;
              if (action.payload.price) {
                sparePart.error = "";
              }
            }
          }
          return job;
        }),
      };
    }
    case ActionCreatorTypes.DeleteJobSparePart: {
      return {
        ...state,
        jobs: state.jobs.map((job) => {
          if (job.jobID === action.payload.jobID) {
            job.spareParts = job.spareParts?.filter(
              (sparePart) => sparePart.id !== action.payload.partID
            );
          }
          return job;
        }),
      };
    }
    case ActionCreatorTypes.AddJob: {
      const jobID = window.self.crypto.randomUUID();
      const productID = window.self.crypto.randomUUID();

      const newJob = {
        jobID,
        jobName: action.payload,
        activeProductID: productID,
        products: [
          {
            productsParametersGroups: [],
            differentTeethParameters: false,
            combinedProduct: false,
            quantity: 1,
            jobProductUUID: productID,
          },
        ],
      };

      return {
        ...state,
        jobs: [...state.jobs, newJob],
        activeJobID: jobID,
      };
    }
    case ActionCreatorTypes.EditJob: {
      const updatedJobs = state.jobs.map((job) => {
        if (job.jobID === action.payload.jobID) {
          return {
            ...job,
            jobName: action.payload.jobName,
          };
        }
        return job;
      });

      return {
        ...state,
        jobs: updatedJobs,
      };
    }
    case ActionCreatorTypes.DeleteJob: {
      const jobs = state.jobs.filter((job) => job.jobID !== action.payload);

      return {
        ...state,
        jobs,
        teethFormulaInputDisabled: checkIfTeethFormulaInputDisabled(jobs),
      };
    }
    case ActionCreatorTypes.SetActiveJob: {
      return {
        ...state,
        activeJobID: action.payload,
        jobs: state.jobs.map((job) => {
          if (job.jobID === action.payload) {
            if (job.products.length) {
              job.activeProductID = job.products[0].jobProductUUID;
            }
          }
          return job;
        }),
      };
    }
    case ActionCreatorTypes.SetActiveProduct: {
      return {
        ...state,
        activeJobID: action.payload.jobUUID,
        jobs: state.jobs.map((job) => {
          if (job.jobID === action.payload.jobUUID) {
            job.activeProductID = action.payload.productUUID;
          }
          return job;
        }),
      };
    }
    case ActionCreatorTypes.SetProductPrice: {
      return {
        ...state,
        jobs: state.jobs.map((job) => {
          if (job.jobID === action.payload.jobID) {
            job.products = job.products.map((product) => {
              if (product.jobProductUUID === action.payload.productUUID) {
                product.price = action.payload.price;
              }
              return product;
            });

            if (action.payload.price) {
              job.error = "";
            }
          }

          return job;
        }),
      };
    }
    case ActionCreatorTypes.SetProduct: {
      const activeJobID = action.payload.activeJobID || state.activeJobID;
      const updatedJobs: CaseFormState.Job[] = state.jobs.map((job) => {
        if (job.jobID === activeJobID) {
          job.products = job.products.map((product) => {
            const activeProductUUID =
              action.payload.productUUID || job.activeProductID;
            if (product.jobProductUUID === activeProductUUID) {
              const productsParametersGroups =
                action.payload.product.productsParametersGroups.map((group) => {
                  const productsParameters = group.productsParameters.map(
                    (parameter) => ({
                      productsParameterUUID:
                        parameter.productsParameter.productsParameterUUID,
                      tooth: null,
                      value: parameter.productsParameter.defaultValue,
                      required: parameter.required,
                    })
                  );
                  return {
                    productsParametersGroupUUID:
                      group.productsParametersGroupUUID,
                    productsParameters,
                  };
                });

              product.product = action.payload.product;
              product.teethColor = action.payload.teethColor;
              product.productsParametersGroups = productsParametersGroups;
            }

            return product;
          });
        }

        return { ...job, jobName: getJobName(job) };
      });

      return {
        ...state,
        jobs: updatedJobs,
      };
    }
    case ActionCreatorTypes.AddProduct: {
      const activeJobID = action.payload.activeJobID || state.activeJobID;

      const updatedJobs: CaseFormState.Job[] = state.jobs.map((job) => {
        const newProductID = window.crypto.randomUUID();
        if (job.jobID === activeJobID) {
          const product = action.payload.product;
          const teethColor = action.payload.teethColor;
          const productsParametersGroups =
            product?.productsParametersGroups.map((group) => {
              const productsParameters = group.productsParameters.map(
                (parameter) => ({
                  productsParameterUUID:
                    parameter.productsParameter.productsParameterUUID,
                  tooth: null,
                  value: parameter.productsParameter.defaultValue,
                  required: parameter.required,
                })
              );
              return {
                productsParametersGroupUUID: group.productsParametersGroupUUID,
                productsParameters,
              };
            }) || [];

          const newProduct = {
            product,
            teethColor,
            jobProductUUID: newProductID,
            productsParametersGroups,
            price: 0,
            combinedProduct: false,
            differentTeethParameters: false,
            quantity: 1,
          };

          job.activeProductID = newProduct.jobProductUUID;

          job.products.push(newProduct);
        }

        return { ...job, jobName: getJobName(job) };
      });

      return {
        ...state,
        activeJobID,
        jobs: updatedJobs,
      };
    }
    case ActionCreatorTypes.DeleteProduct: {
      const activeJobID = action.payload.activeJobID || state.activeJobID;
      const jobs = state.jobs.map((job) => {
        const activeProductId =
          action.payload.productUUID || job.activeProductID;
        if (job.jobID === activeJobID) {
          const deletedProduct = job.products.find(p => p.jobProductUUID === activeProductId)
          job.selectedTeeth = job.selectedTeeth?.filter(t => !deletedProduct?.teeth?.includes(t))
          job.products = job.products.filter(
            (product) => product.jobProductUUID !== activeProductId
          );
        }

        return {
          ...job,
          jobName: getJobName(job),
          activeProductID: job.products.at(-1)?.jobProductUUID,
        };
      });

      return {
        ...state,
        jobs,
        teethFormulaInputDisabled: checkIfTeethFormulaInputDisabled(jobs),
      };
    }
    case ActionCreatorTypes.AddProductWithJobSelectedTeeth: {
      const activeJobID = action.payload.activeJobID || state.activeJobID;
      const jobs = state.jobs.map((job) => {
        if (job.jobID === activeJobID) {
          const newProduct = {
            jobProductUUID: window.crypto.randomUUID(),
            price: 0,
            combinedProduct: false,
            differentTeethParameters: false,
            quantity: 1,
            teethColor: action.payload.teethColor,
            product: action.payload.product,
            teeth: job.selectedTeeth,
          };

          if (action.payload.move) {
            job.products = job.products.map((product) => {
              product.teeth = product.teeth?.filter(
                (tooth) => !job.selectedTeeth?.includes(tooth)
              );
              return product;
            });
          }

          job.selectedTeeth = [];
          job.products.push(newProduct);
        }

        return job;
      });

      return {
        ...state,
        jobs,
      };
    }
    case ActionCreatorTypes.ReplaceProducts: {
      const jobs = state.jobs.map((job) => {
        if (job.jobID === action.payload.jobID) {
          const [removed] = job.products.splice(action.payload.sourceIndex, 1);
          job.products.splice(action.payload.destinationIndex, 0, removed);
        }

        return job;
      });

      return {
        ...state,
        jobs,
      };
    }
    case ActionCreatorTypes.SetProductQuantity: {
      const jobs = state.jobs.map((job) => {
        const activeJobId = action.payload.activeJobID || state.activeJobID;
        if (job.jobID !== activeJobId) {
          return job;
        }

        const activeProductId =
          action.payload.activeProductUUID || job.activeProductID;

        job.products = job.products.map((product) => {
          if (product.jobProductUUID === activeProductId) {
            product.quantity = action.payload.quantity;
          }

          return product;
        });

        return { ...job, jobName: getJobName(job) };
      });

      return { ...state, jobs };
    }
    case ActionCreatorTypes.SetParameter: {
      const jobs = state.jobs.map((job) => {
        const activeJobId = action.payload.activeJobID || state.activeJobID;
        if (job.jobID !== activeJobId) {
          return job;
        }

        const activeProductId =
          action.payload.activeProductID || job.activeProductID;

        job.products = job.products.map((product) => {
          if (product.jobProductUUID === activeProductId) {
            let isError = false;
            const productsParametersGroups =
              product.productsParametersGroups?.map((group) => {
                if (
                  group.productsParametersGroupUUID !== action.payload.groupUUID
                ) {
                  return group;
                }

                const productsParameters = group.productsParameters.map(
                  (parameter) => {
                    if (
                      parameter.productsParameterUUID ===
                        action.payload.parameterUUID &&
                      parameter.tooth === action.payload.tooth
                    ) {
                      parameter.value = action.payload.value;
                    }

                    if (parameter.required && !parameter.value) {
                      isError = true;
                    }

                    return parameter;
                  }
                );

                return { ...group, productsParameters };
              });

            product.error = isError ? job.error : "";
            product.productsParametersGroups = productsParametersGroups;
          }
          return product;
        });

        return job;
      });

      return { ...state, jobs };
    }
    case ActionCreatorTypes.SetError: {
      return {
        ...state,
        jobs: state.jobs.map((job) => {
          if (job.jobID === action.payload.jobID) {
            if ("sparePartUUID" in action.payload) {
              return {
                ...job,
                spareParts: job.spareParts?.map((part) => {
                  if (
                    part.sparePart.sparePartUUID ===
                    action.payload.sparePartUUID
                  ) {
                    part.error = action.payload.message;
                  }
                  return part;
                }),
              };
            }

            if ("extraUUID" in action.payload) {
              return {
                ...job,
                extras: job.extras?.map((extra) => {
                  if (extra.extra.extraUUID === action.payload.extraUUID) {
                    extra.error = action.payload.message;
                  }
                  return extra;
                }),
              };
            }
            return { ...job, error: action.payload.message };
          }
          return job;
        }),
      };
    }
    case ActionCreatorTypes.SetDifferentTeethParametersChecked: {
      const activeJobID = action.payload.activeJobID || state.activeJobID;
      return {
        ...state,
        jobs: state.jobs.map((job) => {
          if (job.jobID === activeJobID) {
            job.products = job.products.map((product) => {
              const activeProductID =
                action.payload.activeProductID || job.activeProductID;
              if (product.jobProductUUID === activeProductID) {
                product.differentTeethParameters = action.payload.checked;
                if (!product.product) {
                  return product;
                }

                if (product.teeth?.length && product.differentTeethParameters) {
                  if (!product.selectedTooth) {
                    product.selectedTooth = product.teeth[0];
                  }
                } else {
                  product.selectedTooth = undefined;
                }

                product.productsParametersGroups =
                  product.product.productsParametersGroups.map((group) => {
                    const currentFormGroup =
                      product.productsParametersGroups?.find(
                        (g) =>
                          g.productsParametersGroupUUID ===
                          group.productsParametersGroupUUID
                      );
                    return {
                      productsParametersGroupUUID:
                        group.productsParametersGroupUUID,
                      productsParameters:
                        group.productsParameters.flatMap<CaseFormState.ProductsParameter>(
                          (parameter) => {
                            const currentParam =
                              currentFormGroup?.productsParameters.find(
                                (p) =>
                                  p.productsParameterUUID ===
                                  parameter.productsParameter
                                    .productsParameterUUID
                              );
                            const value =
                              currentParam?.value ||
                              parameter.productsParameter.defaultValue;
                            if (
                              product.teeth?.length &&
                              product.differentTeethParameters
                            ) {
                              return product.teeth!.map((tooth) => ({
                                productsParameterUUID:
                                  parameter.productsParameter
                                    .productsParameterUUID,
                                tooth,
                                value,
                                required: parameter.required,
                              }));
                            } else {
                              return {
                                productsParameterUUID:
                                  parameter.productsParameter
                                    .productsParameterUUID,
                                tooth: null,
                                value: value,
                                required: parameter.required,
                              };
                            }
                          }
                        ),
                    };
                  });
              }

              return product;
            });
          }
          return job;
        }),
      };
    }
    case ActionCreatorTypes.SetCombinedProductChecked: {
      const activeJobID = action.payload.activeJobID || state.activeJobID;
      const updatedJobs = state.jobs.map((job) => {
        if (job.jobID === activeJobID) {
          job.products = job.products.map((product) => {
            const activeProductID =
              action.payload.activeProductID || job.activeProductID;
            if (product.jobProductUUID === activeProductID) {
              product.combinedProduct = action.payload.checked;
              state.activeJobID = activeJobID;
              job.activeProductID = activeProductID;
            }
            return product;
          });
        }
        return job;
      });

      return {
        ...state,
        jobs: updatedJobs,
      };
    }
    case ActionCreatorTypes.SetSelectedTooth: {
      const updatedJobs = state.jobs.map((job) => {
        if (job.jobID === action.payload.activeJobID) {
          job.products = job.products.map((product) => {
            if (product.jobProductUUID === action.payload.activeProductID) {
              product.selectedTooth = action.payload.tooth;
            }
            return product;
          });
        }
        return job;
      });

      return {
        ...state,
        jobs: updatedJobs,
      };
    }
    default:
      return state;
  }
};

export const useCaseFormContext = () => useContext(CaseFormContext);
