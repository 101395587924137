import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, notification, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

import { AdminClient } from "@/root/models/adminClient";
import { CreateAdminClientPayload } from "@/api/admin/clients";
import { AdminClientForm } from "@/components";
import useAdminClients from "@/hooks/useAdminClients";

const { confirm } = Modal;

interface TableActionsProps {
  client: AdminClient;
}

const TableActions: FC<TableActionsProps> = ({ client }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [modal, setModal] = useState(false);

  const onDeleteError = (message: string) => {
    notification.error({
      message,
    });
  };

  const onEditSuccess = () => {
    setModal(false);
  };

  const {
    deleteAdminClient,
    deleteLoading,
    editAdminClient,
    editLoading,
    editError,
  } = useAdminClients({ onDeleteError, onEditSuccess });

  const handleDelete = async () => {
    await deleteAdminClient(client.clientUUID);
  };

  const handleEdit = (values: CreateAdminClientPayload) => {
    editAdminClient(client.clientUUID, values);
  };

  const submit = () => {
    form.submit();
  };

  const openModal = () => {
    form.setFieldsValue(client);
    setModal(true);
  };

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  return (
    <Space className="table-actions" size="middle">
      <Button size="small" icon={<EditOutlined />} onClick={openModal} />
      <Button
        size="small"
        icon={<DeleteOutlined />}
        loading={deleteLoading}
        danger
        onClick={handleDeleteButtonClick}
      />
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={
          <div style={{ marginBottom: 20 }}>
            {t("Редагування співробітника")}
          </div>
        }
        open={modal}
        onCancel={() => setModal(false)}
        onOk={submit}
        okButtonProps={{ loading: editLoading }}
      >
        <AdminClientForm
          form={form}
          handleSubmit={handleEdit}
          error={editError}
        />
      </Modal>
    </Space>
  );
};

export default TableActions;
