import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { CourierTasks } from "@/modules";

const CourierPage: FC = () => {
  return (
    <Routes>
      <Route path="dashboard" element={<CourierTasks />} />
    </Routes>
  );
};

export default CourierPage;
