import { FC } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { DatePicker, Form, Input, Select } from "antd";

import { Pricelist } from "@/root/models/pricelist";
import {
  ExtraPricelist,
  ProductPricelist,
} from "@/components/PricelistForm/componenets";
import { PricelistTypeEnum } from "@/components/PricelistForm";
import { Block } from "@/components/common";

const { Option } = Select;

interface ActivePricelistProps {
  pricelist: Pricelist;
  presentation?: boolean;
}

const ActivePricelist: FC<ActivePricelistProps> = ({
  pricelist,
  presentation,
}) => {
  const { t } = useTranslation();
  const type = pricelist.pricelistType;
  const listItems = pricelist.list.map((item) => ({
    ...item,
    itemUUID:
      "productUUID" in item.item ? item.item.productUUID : item.item.extraUUID,
  }));

  return (
    <Block style={{ boxShadow: "none", borderRadius: 6 }}>
      <Form disabled={true} requiredMark={false}>
        <Form.Item
          rules={[
            {
              required: true,
              message: t("Заповніть поле") as string,
            },
          ]}
          label={t("Опис")}
        >
          <Input
            value={pricelist.description}
            placeholder={t("Введіть значення") as string}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: t("Заповніть поле") as string,
            },
          ]}
          label={t("Дата активації")}
        >
          <DatePicker
            value={dayjs(new Date(pricelist.date))}
            format="YYYY.MM.DD"
            placeholder={t("Виберіть дату") as string}
          />
        </Form.Item>
        <Form.Item label={t("Тип")}>
          <Select value={type.pricelistTypeUUID}>
            <Option value={type.pricelistTypeUUID}>{type.name || " "}</Option>
          </Select>
        </Form.Item>

        {type.pricelistTypeUUID === PricelistTypeEnum.ProductsCatalog && (
          <ProductPricelist
            productPrices={listItems}
            setProductPrices={() => {}}
            presentation={presentation}
          />
        )}

        {type.pricelistTypeUUID === PricelistTypeEnum.Extras && (
          <ExtraPricelist
            extraPrices={listItems}
            setExtraPrices={() => {}}
            presentation={presentation}
          />
        )}
      </Form>
    </Block>
  );
};

export default ActivePricelist;
