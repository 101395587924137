import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Checkbox, Input, InputNumber, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import styles from "./Product.module.scss";

import { Block } from "@/components/common";

const SkeletonProduct: FC = () => {
  const { t } = useTranslation();

  return (
    <Block
      style={{
        background: "rgb(251, 251, 251)",
        cursor: "pointer",
      }}
    >
      <div className={styles.container}>
        <Space className={styles.jobInputContainer}>
          <Input
            style={{
              cursor: "pointer",
            }}
            disabled
          />
        </Space>

        <Space>
          <span style={{ color: "rgba(0, 0, 0, 0.25)", whiteSpace: "nowrap" }}>
            {t("к-сть")}:
          </span>
          <InputNumber
            disabled
            style={{
              width: 50,
              cursor: "pointer",
            }}
            type="number"
            size="small"
            value={undefined}
          />
          <Button
            style={{
              cursor: "pointer",
            }}
            disabled
            size="small"
            icon={<DeleteOutlined />}
          />
        </Space>
      </div>

      <div>
        <Checkbox
          style={{
            cursor: "pointer",
          }}
          disabled
        >
          {t("Комбінований виріб")}
        </Checkbox>
      </div>
    </Block>
  );
};

export default SkeletonProduct;
