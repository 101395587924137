import i18next from "i18next";
import { AuthApi } from "@/api/auth";
import { AppDispatch } from "@/store";
import {
  resetUserState,
  setGetMeError,
  setGetMeLoading,
  setLoginError,
  setLoginLoading,
  setUserData,
} from "@/store/reducers/user/userSlice";
import { clearAuthToken, saveAuthToken } from "@/utils/tokenHelpers";
import { LoadingStatus } from "@/root/types";

export const login =
  (login: string, password: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoginError(""));
      dispatch(setLoginLoading(LoadingStatus.LOADING));
      const data = await AuthApi.login(login, password);
      dispatch(
        setUserData({
          name: data.name,
          language: data.language,
          userType: data.userType,
          userUUID: data.userUUID,
          client: data.client,
        })
      );
      saveAuthToken(data.token, data.expires);
      await i18next.changeLanguage(data.language);
      dispatch(setLoginLoading(LoadingStatus.SUCCESS));
    } catch (e: any) {
      dispatch(setLoginError(e.message));
      dispatch(setLoginLoading(LoadingStatus.ERROR));
    }
  };

export const me = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setGetMeError(""));
    dispatch(setGetMeLoading(LoadingStatus.LOADING));
    const data = await AuthApi.me();
    dispatch(
      setUserData({
        name: data.name,
        language: data.language,
        userType: data.userType,
        userUUID: data.userUUID,
        client: data.client,
      })
    );
    await i18next.changeLanguage(data.language);
    dispatch(setGetMeLoading(LoadingStatus.SUCCESS));
  } catch (e: any) {
    dispatch(setGetMeError(e.message));
    dispatch(setGetMeLoading(LoadingStatus.ERROR));
  }
};

export const logout = () => (dispatch: AppDispatch) => {
  dispatch(resetUserState());
  clearAuthToken();
};
