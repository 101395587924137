import { FC, ReactNode } from "react";

import { TOOTH_COLOR } from "@/root/consts";

export interface ITooth {
  position: number;
  id: string;
  productUUID?: string;
  jaw: "upper" | "lower";
  connectorNode?: ReactNode;
  numberNode: ReactNode;
  toothNode: ReactNode;
  crownNode: ReactNode;
  extraProductCircleNode: ReactNode;
  implantCircleNode: ReactNode;
  tapped: boolean;
  arc: boolean;
  isPoint: boolean;
  isCrown: boolean;
  isConnected: boolean;
  containAnotherProduct: boolean;
  anotherProductColor?: string;
  selected: boolean;
  color: string | null;
}

interface ToothProps {
  tooth: ITooth;
  onClick: (id: ITooth["id"]) => void;
}

const Tooth: FC<ToothProps> = ({ tooth, onClick }) => {
  return (
    <g onClick={() => onClick(tooth.id)}>
      {tooth.numberNode}
      {tooth.selected && <g fill={TOOTH_COLOR}>{tooth.toothNode}</g>}
      <g
        fill={
          tooth.tapped
            ? tooth.color?.trim()
              ? tooth.color
              : "#D8D8D8"
            : TOOTH_COLOR
        }
        style={{
          opacity: tooth.selected ? 0.35 : undefined,
        }}
      >
        {tooth.toothNode}
      </g>
      {tooth.containAnotherProduct && (
        <g fill={tooth.anotherProductColor || undefined}>
          {tooth.extraProductCircleNode}
        </g>
      )}
      {tooth.tapped && tooth.isPoint && tooth.implantCircleNode}
      {tooth.isCrown && <>{tooth.tapped && tooth.crownNode}</>}
      {tooth.arc && tooth.crownNode}
      {tooth.isConnected && (
        <>
          {tooth.selected && <g fill={"white"}>{tooth.connectorNode}</g>}
          <g
            fill={
              tooth.tapped
                ? tooth.color?.trim()
                  ? tooth.color
                  : "#D8D8D8"
                : "white"
            }
            style={{
              opacity: tooth.selected ? 0.35 : undefined,
            }}
          >
            {tooth.connectorNode}
          </g>
        </>
      )}
    </g>
  );
};

export default Tooth;
