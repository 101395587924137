import React, { FC, useState } from "react";
import dayjs from "dayjs";

import { Button, Calendar, DatePicker } from "antd";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";

import styles from "./MainPanel.module.scss";

import { WidgetCaseStatus } from "@/components";
import { Block } from "@/components/common";
import { DashboardTables } from "@/modules/MainPanel/components";
import { DATE_FORMAT } from "@/root/consts";

const { RangePicker } = DatePicker;

const MainPanel: FC = () => {
  const [widgetCollapsed, setWidgetCollapsed] = useState(false);
  const [date, setDate] = useState<string>("");

  const handleCalendarChange = (dayjs: dayjs.Dayjs) => {
    dayjs.format(DATE_FORMAT) === date
      ? setDate("")
      : setDate(dayjs.format(DATE_FORMAT));
  };

  return (
    <div className={styles.container}>
      {widgetCollapsed ? (
        <Block
          onClick={() => setWidgetCollapsed(false)}
          className={styles.expandWidgets}
        >
          <DoubleRightOutlined />
        </Block>
      ) : (
        <div className={styles.widgets}>
          <Block className={styles.chartContainer}>
            <WidgetCaseStatus />
            <Button
              className={styles.collapseButton}
              icon={<DoubleLeftOutlined />}
              onClick={() => setWidgetCollapsed(true)}
            />
          </Block>
          <Block className={styles.calendarContainer}>
            <div className={styles.rangePicker}>
              <RangePicker
                onChange={(_, range) =>
                  setDate(range[0] ? range.join(",") : "")
                }
                allowClear
              />
            </div>
            <Calendar
              className={styles.calendar}
              fullscreen={false}
              value={
                date && !date?.includes(",") ? dayjs(new Date(date)) : dayjs()
              }
              onSelect={handleCalendarChange}
            />
            <Button
              className={styles.collapseButton}
              icon={<DoubleLeftOutlined />}
              onClick={() => setWidgetCollapsed(true)}
            />
          </Block>
        </div>
      )}
      <div style={{width: widgetCollapsed ? 'calc(100% - 30px)' : 'calc(100% - 408px)'}} className={styles.contentContainer}>
        <DashboardTables date={date} />
      </div>
    </div>
  );
};

export default MainPanel;
