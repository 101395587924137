import React, { FC, FocusEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { InputNumber } from "antd";

import { CaseFormState } from "@/components/CaseForm/CaseFormContext";

interface PriceProps {
  job: CaseFormState.Job;
  product: CaseFormState.Job.Product;
  onPriceChange: (productUUID: string, price: number | null) => void;
}

const Price: FC<PriceProps> = ({ job, product, onPriceChange }) => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState(!product.price);

  useEffect(() => {
    if (job.error === t("Заповніть ціну виробу")) {
      setIsError(true);
    }
  }, [job.error]);

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value || value === "0") {
      onPriceChange(product.jobProductUUID, null);
      setIsError(true);
    } else {
      setIsError(false);
      onPriceChange(product.jobProductUUID, Number(e.target.value));
    }
  };

  return (
    <InputNumber
      status={isError ? "error" : undefined}
      style={isError ? { background: "rgba(255,0,0,.85)" } : undefined}
      min={0}
      type="number"
      placeholder={t("Ціна") as string}
      defaultValue={product.price ?? undefined}
      onBlur={handleBlur}
    />
  );
};

export default Price;
