import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Form, Select } from "antd";

import styles from "./SelectTeethFormulaType.module.scss";

import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import useTeethFormulaTypes from "@/hooks/useTeethFormulaTypes";

const { Option } = Select;

interface SelectTeethFormulaTypeProps {}

const SelectTeethFormulaType: FC<SelectTeethFormulaTypeProps> = ({}) => {
  const { t } = useTranslation();
  const { teethFormulaTypes, getTypesLoading } = useTeethFormulaTypes();
  const { state, dispatch } = useCaseFormContext();

  useEffect(() => {
    if (!state.teethFormulaUUID && teethFormulaTypes.length) {
      const adult = teethFormulaTypes.find((type) => type.name === "Adult");
      if (!adult) {
        return;
      }
      dispatch({
        type: ActionCreatorTypes.SetTeethFormulaUUID,
        payload: adult.teethFormulaUUID,
      });


    }
  }, [teethFormulaTypes]);

  const handleSelect = (value: string) => {
    dispatch({
      type: ActionCreatorTypes.SetTeethFormulaUUID,
      payload: value,
    });
  };

  return (
    <Form.Item
      className={styles.formItem}
      labelCol={{ span: 10 }}
      label={t("Тип формули")}
      rules={[{ required: true, message: t("Заповніть поле") as string }]}
    >
      <Select
        disabled={state.teethFormulaInputDisabled}
        listHeight={1000}
        showSearch
        loading={getTypesLoading}
        value={state.teethFormulaUUID}
        optionFilterProp="children"
        onSelect={handleSelect}
      >
        {teethFormulaTypes.map((type) => (
          <Option key={type.teethFormulaUUID} value={type.teethFormulaUUID}>
            {type.name || " "}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SelectTeethFormulaType;
