import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Alert, Form, FormInstance, Input } from "antd";

import useFormAutoFocusInput from "@/hooks/useFormAutoFocusInput";
import { CreatePriceFormulaPayload } from "@/api/admin/priceFormulas";

interface PriceFormulaFormProps {
  form: FormInstance<CreatePriceFormulaPayload>;
  handleSubmit: (values: CreatePriceFormulaPayload) => void;
  error: string;
}

const PriceFormulaForm: FC<PriceFormulaFormProps> = ({
  form,
  handleSubmit,
  error,
}) => {
  const { t } = useTranslation();
  const focusInputRef = useFormAutoFocusInput();

  return (
    <Form requiredMark={false} form={form} onFinish={handleSubmit}>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
        name="name"
        label={t("Ім'я")}
      >
        <Input
          ref={focusInputRef}
          placeholder={t("Введіть значення") as string}
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
        name="formula"
        label={t("Формула")}
      >
        <Input
          placeholder={t("Введіть значення") as string}
        />
      </Form.Item>

      {!!error && <Alert message={error} type="error" />}
    </Form>
  );
};

export default PriceFormulaForm;
