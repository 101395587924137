import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, Table, Typography } from "antd";

import styles from "@/modules/MainPanel/components/DashboardTable.module.scss";

import { Block, Pagination } from "@/components/common";
import useCases from "@/modules/Cases/useCases";
import { ColumnsType } from "antd/es/table";
import { Case } from "@/root/models/case";
import { isLaptop } from "@/utils/isLaptop";
import { pages as navigationPages } from "@/root/consts";
import { ChosenCase, TableActions } from "@/modules/Cases/components";
import { CaseStatusEnum, UserTypeEnum } from "@/root/types";
import { useAppSelector } from "@/hooks/redux";
import useClickOutside from "@/hooks/useClickOutside";
import { EyeOutlined } from "@ant-design/icons";

const { Title } = Typography;

interface CasesTableProps {
  date: string;
  chosenCaseUUID?: string;
  onChoose: (id: string) => void;
}

const CasesTable: FC<CasesTableProps> = ({
  date,
  chosenCaseUUID,
  onChoose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userType = useAppSelector((state) => state.userReducer.user?.userType);
  const {
    cases,
    currentPage,
    pages,
    getCasesLoading,
    setCurrentPage,
    setDueDate,
    deleteCase,
  } = useCases();

  const tableRef = useRef<HTMLDivElement>(null);
  const [paginationRange, setPaginationRange] = useState(1);
  const [fullCaseOpen, setFullCaseOpen] = useState<Case>();

  useEffect(() => {
    setDueDate(date);
  }, [date]);

  useEffect(() => {
    if (cases) {
      cases[0] && onChoose(cases[0].caseUUID);
    }
  }, [cases]);

  useEffect(() => {
    setTimeout(() => {
      setPaginationRange(
        tableRef.current
          ? Math.floor(
              (tableRef.current.getBoundingClientRect().width - 8 - 39 * 4) / 39
            )
          : 1
      );
    });
  }, [tableRef.current, getCasesLoading]);

  const handleEdit = async (medCase: Case) => {
    navigate(navigationPages.lab.cases.edit.path, { state: medCase.caseUUID });
  };
  const handleCreate = () => {
    navigate(navigationPages.lab.cases.create.path);
  };

  const casesColumns: ColumnsType<Case> = [
    {
      width: 88,
      title: t("Дата завершення"),
      dataIndex: "dueDate",
      key: "dueDate",
      render: (date: Case["dueDate"]) => <span>{date.slice(0, 10)}</span>,
    },

    {
      title: t("Лікар"),
      dataIndex: "clientsEmployee",
      key: "clientsEmployee",
      render: (clientsEmployee: Case["clientsEmployee"]) => (
        <span>{clientsEmployee?.name}</span>
      ),
    },
    {
      title: t("Пацієнт"),
      dataIndex: "patient",
      key: "patient",
      render: (patient: Case["patient"]) => <span>{patient?.name}</span>,
    },
    {
      title: t("Статус"),
      dataIndex: "status",
      key: "status",
      render: (status: Case["status"]) => <span>{status.name}</span>,
    },
    {
      width: 35,
      title: "",
      key: "action",
      render: (_: any, medCase: Case) =>
        (userType?.userTypeUUID === UserTypeEnum["Client's admin"] ||
          userType?.userTypeUUID === UserTypeEnum["Client's employee"]) &&
        medCase.status.statusUUID !== CaseStatusEnum.New ? (
          <div className="table-actions">
            <Button
              size="small"
              icon={<EyeOutlined />}
              onClick={() => setFullCaseOpen(medCase)}
            />
          </div>
        ) : (
          <TableActions
            direction="vertical"
            medCase={medCase}
            onDelete={deleteCase}
            onEditButtonClick={handleEdit}
          />
        ),
    },
  ];

  if (
    userType?.userTypeUUID === UserTypeEnum["Employee"] ||
    userType?.userTypeUUID === UserTypeEnum["Lab's admin"]
  ) {
    casesColumns.splice(1, 0, {
      title: t("Контрагент"),
      dataIndex: "client",
      key: "client",
      render: (client: Case["client"]) => <span>{client?.name}</span>,
    });
    casesColumns.splice(4, 0, {
      title: t("Відповідальний"),
      dataIndex: "activeHandler",
      key: "activeHandler",
      render: (activeHandler: Case["activeHandler"]) => (
        <span>{activeHandler.activeHandlerName}</span>
      ),
    });
  }

  const fullCaseRef = useRef<HTMLDivElement>(null);
  useClickOutside(fullCaseRef, () => setFullCaseOpen(undefined));

  return (
    <>
      <Block style={{ paddingLeft: 5, paddingRight: 5, width: "60%" }}>
        <div className={styles.container}>
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "33%" }}>
                <Button onClick={handleCreate}>
                  {t("Створити замовлення")}
                </Button>
              </div>
              <Title level={3} style={{ width: "33%", textAlign: "center" }}>
                {t("Замовлення")}
              </Title>
            </div>
            <Table
              rowClassName={(medCase) =>
                medCase.caseUUID === chosenCaseUUID
                  ? styles.activeRow
                  : undefined
              }
              size={"small"}
              scroll={{
                y: isLaptop() ? "73vh" : "79vh",
              }}
              ref={tableRef}
              columns={casesColumns}
              dataSource={cases.map((c) => ({ ...c, key: c.caseUUID }))}
              pagination={false}
              loading={getCasesLoading}
              onRow={(medCase) => {
                return {
                  onClick: () => onChoose(medCase.caseUUID),
                };
              }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              currentPage={currentPage}
              pages={pages}
              paginationRange={paginationRange}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </Block>
      {fullCaseOpen && (
        <div ref={fullCaseRef} className={styles.fullCase}>
          <ChosenCase medCase={fullCaseOpen} />
        </div>
      )}
    </>
  );
};

export default CasesTable;
