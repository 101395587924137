import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Space, Table } from "antd";

import usePricelists from "@/hooks/usePricelists";
import {
  ActivePricelist,
  CreatePricelist,
  TableActions,
} from "@/modules/Pricelists/components";
import { Pricelist } from "@/root/models/pricelist";
import PrintPricelist from "../ClientPricelists/components/PrintPricelist";
import { useAppSelector } from "@/hooks/redux";
import { UserTypeEnum } from "@/root/types";

const Pricelists: FC = () => {
  const { t } = useTranslation();
  const {
    pricelists,
    getPricelistsLoading,
    createPricelist,
    deletePricelist,
    editPricelist,
  } = usePricelists();
  const [activePricelist, setActivePricelist] = useState<Pricelist>();
  const userType = useAppSelector((state) => state.userReducer.user?.userType);

  const pricelistsColumn = [
    {
      title: t("Номер"),
      dataIndex: "number",
      key: "number",
    },
    {
      title: t("Дата"),
      dataIndex: "date",
      key: "date",
    },
    {
      title: t("Дата активації"),
      dataIndex: "activationDate",
      key: "activationDate",
    },
    {
      title: t("Тип"),
      dataIndex: "pricelistType",
      key: "pricelistType",
      render: (type: Pricelist.Type) => <span>{type.name}</span>,
    },
    {
      title: t("Опис"),
      dataIndex: "description",
      key: "description",
    },
  ];

  userType?.userTypeUUID === UserTypeEnum["Lab's admin"] &&
    pricelistsColumn.push({
      title: "",
      key: "action", // @ts-ignore
      render: (_: any, pricelist: Pricelist) => (
        <TableActions
          pricelist={pricelist}
          onDelete={deletePricelist}
          onEdit={editPricelist}
        />
      ),
    });

  return (
    <Space direction="vertical">
      <Space>
        {userType?.userTypeUUID === UserTypeEnum["Lab's admin"] && (
          <CreatePricelist onCreate={createPricelist} />
        )}
        <Button disabled={!activePricelist} onClick={() => window.print()}>
          {t("Друк")}
        </Button>
      </Space>
      {activePricelist && <PrintPricelist pricelist={activePricelist} />}
      <div style={{ display: "flex", gap: 10 }}>
        <Table
          columns={pricelistsColumn}
          dataSource={pricelists.map((p) => ({ ...p, key: p.pricelistUUID }))}
          pagination={false}
          expandedRowKeys={pricelists.map((p) => p.pricelistUUID)}
          expandRowByClick
          loading={getPricelistsLoading}
          onRow={(pricelist) => ({
            onClick: () => setActivePricelist(pricelist),
          })}
        />
        {activePricelist && (
          <ActivePricelist pricelist={activePricelist} presentation />
        )}
      </div>
    </Space>
  );
};

export default Pricelists;
