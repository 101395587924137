import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { DatePicker, Form, FormInstance, Select } from "antd";

import useCaseStatuses from "@/hooks/useCaseStatuses";
import { useAppSelector } from "@/hooks/redux";
import { UserTypeEnum } from "@/root/types";
import { DATE_FORMAT } from "@/root/consts";
import AdminClients from "./AdminClients";
import ClientEmployeesSelect from "./ClientEmployeesSelect";
import PatientsSelect from "./PatientsSelect";
import EmployeesSelect from "./EmployeesSelect";

export interface AdvancedSearchParameters {
  statuses: string[];
  adminClients: string[];
  clientEmployees?: string[];
  patients?: string[];
  employees?: string[];
  date: [string, string];
  dueDate: [string, string];
}

interface AdvancedCasesSearchFormProps {
  form: FormInstance<AdvancedSearchParameters>;
  handleSubmit: (values: AdvancedSearchParameters) => void;
}

const { RangePicker } = DatePicker;
const { Option } = Select;

const AdvancedCasesSearchForm: FC<AdvancedCasesSearchFormProps> = ({
  form,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.userReducer.user);
  const { statuses, loading: getStatusesLoading } = useCaseStatuses();
  const [chosenAdminClients, setChosenAdminClients] = useState<string[]>([]);

  return (
    <Form requiredMark={false} form={form} onFinish={handleSubmit}>
      <Form.Item name="statuses" label={t("Статус")}>
        <Select
          mode="multiple"
          listHeight={1000}
          loading={getStatusesLoading}
          allowClear
        >
          {statuses.map((status) => (
            <Option key={status.statusUUID} value={status.statusUUID}>
              {status.name || " "}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="date" label={t("Дата замовлення")}>
        <RangePicker format={DATE_FORMAT} />
      </Form.Item>
      <Form.Item name="dueDate" label={t("Дата завершення")}>
        <RangePicker format={DATE_FORMAT} />
      </Form.Item>
      {user?.userType.userTypeUUID !== UserTypeEnum["Client's admin"] &&
        user?.userType.userTypeUUID !== UserTypeEnum["Client's employee"] && (
          <AdminClients
            chosenAdminClients={chosenAdminClients}
            setChosenAdminClients={setChosenAdminClients}
          />
        )}
      {(user?.userType.userTypeUUID === UserTypeEnum["Client's admin"] ||
        user?.userType.userTypeUUID === UserTypeEnum["Client's employee"]) &&
      user?.client ? (
        <ClientEmployeesSelect clientUUIDs={chosenAdminClients} />
      ) : chosenAdminClients.length > 0 ? (
        <ClientEmployeesSelect clientUUIDs={chosenAdminClients} />
      ) : (
        <Form.Item name="clientEmployees" label={t("Лікар")}>
          <Select disabled mode="multiple" placeholder={t("Оберіть клієнта")} />
        </Form.Item>
      )}
      {(user?.userType.userTypeUUID === UserTypeEnum["Client's admin"] ||
        user?.userType.userTypeUUID === UserTypeEnum["Client's employee"]) &&
      user?.client ? (
        <PatientsSelect clientUUIDs={[user.client.clientUUID]} />
      ) : chosenAdminClients.length > 0 ? (
        <PatientsSelect clientUUIDs={chosenAdminClients} />
      ) : (
        <Form.Item name="patients" label={t("Пацієнт")}>
          <Select disabled mode="multiple" placeholder={t("Оберіть клієнта")} />
        </Form.Item>
      )}

      {(user?.userType.userTypeUUID === UserTypeEnum["Employee"] ||
        user?.userType.userTypeUUID === UserTypeEnum["Lab's admin"]) && (
        <Form.Item name="employees" label={t("Співробітник")} shouldUpdate>
          {EmployeesSelect({ ...form })}
        </Form.Item>
      )}
    </Form>
  );
};

export default AdvancedCasesSearchForm;
