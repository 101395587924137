import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { notification } from "antd";

import { LaboratoryCaseForm, CaseForm } from "@/components";
import {
  caseFormInitialState,
  CaseFormState,
} from "@/components/CaseForm/CaseFormContext";
import { useAppSelector } from "@/hooks/redux";
import { CreateCasePayload, CasesApi } from "@/api/client/cases";
import { pages } from "@/root/consts";
import { UserTypeEnum } from "@/root/types";

const MakeCase: FC = () => {
  const client = useAppSelector((state) => state.userReducer.user?.client);
  const userUUID = useAppSelector((state) => state.userReducer.user?.userUUID);
  const userType = useAppSelector((state) => state.userReducer.user?.userType);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const navigateToCase = () => {
    if (!userType) {
      return;
    }

    switch (userType.userTypeUUID) {
      case UserTypeEnum["Client's employee"]:
      case UserTypeEnum["Client's admin"]:
        navigate(pages.client.cases.path);
        break;
      case UserTypeEnum["Employee"]:
        navigate(pages.lab.cases.path);
        break;
      case UserTypeEnum["Lab's admin"]:
        navigate(pages.admin.cases.path);
        break;
    }
  };

  const handleCreate = async (values: CreateCasePayload, close: boolean) => {
    try {
      setLoading(true);
      const medCase = await CasesApi.create(values);
      if (close) {
        navigateToCase();
      }
      return medCase;
    } catch (e: any) {
      notification.error({
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const jobID = window.self.crypto.randomUUID();
  const productID = window.crypto.randomUUID()

  const initialState: CaseFormState = {
    ...caseFormInitialState,
    client: client!,
    onlyDetailsEdit: true,
    clientsEmployee:
      userUUID && userType?.userTypeUUID === UserTypeEnum["Client's employee"]
        ? {
            clientsEmployeeUUID: userUUID,
            name: "",
          }
        : null,
    activeJobID: jobID,
    jobs: [
      {
        jobName: "",
        jobID: jobID,
        activeProductID: productID,
        products: [
          {
            productsParametersGroups: [],
            differentTeethParameters: false,
            combinedProduct: false,
            quantity: 1,
            jobProductUUID: productID,
          },
        ],
      },
    ],
  };

  const Form = () => {
    if (!userType) {
      return null;
    }

    switch (userType.userTypeUUID) {
      case UserTypeEnum["Client's employee"]:
      case UserTypeEnum["Client's admin"]:
        return (
          <CaseForm
            initialState={initialState}
            loading={loading}
            onFinish={handleCreate}
            onCancel={navigateToCase}
          />
        );
      case UserTypeEnum["Employee"]:
      case UserTypeEnum["Lab's admin"]:
        return (
          <LaboratoryCaseForm
            medCase={null}
            initialState={initialState}
            loading={loading}
            onFinish={handleCreate}
            onCancel={navigateToCase}
          />
        );
      default:
        return null;
    }
  };

  return Form();
};

export default MakeCase;
