import { useQuery } from "@tanstack/react-query";

import { Product } from "@/root/models/product";
import { ProductsApi } from "@/api/admin/products";

const useProducts = () => {
  const {
    data: products,
    isFetching: getProductsLoading,
    error: getProductsError,
  } = useQuery({
    queryKey: ["products"],
    queryFn: ProductsApi.getProducts,
  });

  const findProductByID = (id: string) => {
    const recursiveSearch = (products: Product[]) => {
      let product: Product | undefined;
      for (const candidate of products) {
        if (candidate.productUUID !== id) {
          if (candidate.children) {
            const foundProduct = recursiveSearch(candidate.children);
            if (foundProduct) {
              product = foundProduct;
              break;
            }
          }
        } else {
          product = candidate;
          break;
        }
      }
      return product;
    };

    return recursiveSearch(products || []);
  };

  const getParameterGroup = (product: Product) => {
    let firstLevelProduct: Product = product;

    do {
      const candidate = findProductByID(firstLevelProduct.parentProductUUID);
      if (candidate) {
        firstLevelProduct = candidate;
      } else {
        break;
      }
    } while (firstLevelProduct?.level !== 1);

    return firstLevelProduct;
  };

  return {
    products: products || [],
    findProductByID,
    getProductsLoading,
    getProductsError,
    getParameterGroup,
  };
};

export default useProducts;
