import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Space, Table } from "antd";

import usePans from "@/hooks/usePans";
import { CreatePan, TableActions } from "@/modules/Pans/components";
import { Pan } from "@/root/models/pan";

const Pans: FC = () => {
  const { t } = useTranslation();
  const { pans, getPansLoading } = usePans();

  const pansColumn = [
    {
      title: t("Назва"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Номер замовлення"),
      dataIndex: "case",
      key: "caseNumber",
      render: (panCase: Pan.Case) => <span>{panCase.caseNumber}</span>,
    },
    {
      title: "",
      key: "action",
      render: (_: any, pan: Pan) => <TableActions pan={pan} />,
    },
  ];

  return (
    <Space direction="vertical">
      <CreatePan />
      <Table
        columns={pansColumn}
        dataSource={pans.map((p) => ({ ...p, key: p.panUUID }))}
        pagination={false}
        loading={getPansLoading}
      />
    </Space>
  );
};

export default Pans;
