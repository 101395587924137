import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { Button, Modal, notification, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { Pricelist } from "@/root/models/pricelist";
import { CreatePricelistPayload, PricelistsApi } from "@/api/admin/pricelists";
import { PricelistForm } from "@/components";

const { confirm } = Modal;

interface TableActionsProps {
  pricelist: Pricelist;
  onDelete: (id: string) => void;
  onEdit: (id: string, pricelist: Pricelist) => void;
}

const TableActions: FC<TableActionsProps> = ({
  pricelist,
  onDelete,
  onEdit,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [modal, setModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editError, setEditError] = useState("");

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      await PricelistsApi.delete(pricelist.pricelistUUID);
      onDelete(pricelist.pricelistUUID);
    } catch (e: any) {
      notification.error({
        message: e.message,
      });
    } finally {
      setDeleteLoading(true);
    }
  };

  const handleEdit = async (values: CreatePricelistPayload) => {
    try {
      setEditLoading(true);
      setEditError("");
      const newPricelist = await PricelistsApi.edit(
        pricelist.pricelistUUID,
        {
          ...values,
          activationDate: dayjs(values.activationDate).format("YYYY.MM.DD"),
        }
      );
      onEdit(pricelist.pricelistUUID, newPricelist);
      setModal(false);
    } catch (e: any) {
      setEditError(e.message);
    } finally {
      setEditLoading(false);
    }
  };

  const submit = () => {
    form.submit();
  };

  const openModal = () => {
    form.setFieldsValue({
      ...pricelist,
      priceListUUID: pricelist.pricelistUUID,
      pricelistTypeUUID: pricelist.pricelistType.pricelistTypeUUID,
      description: pricelist.description,
      activationDate: dayjs(pricelist.activationDate),
      list: pricelist.list.map(({ price, item }) => ({
        itemUUID: Pricelist.List.GetItemUUID(item),
        price,
      })),
    });
    setModal(true);
  };

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  return (
    <Space className="table-actions" size="middle">
      <Button size="small" icon={<EditOutlined />} onClick={openModal} />
      <Button
        size="small"
        icon={<DeleteOutlined />}
        loading={deleteLoading}
        danger
        onClick={handleDeleteButtonClick}
      />
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={
          <div style={{ marginBottom: 20 }}>{t("Редагування прайс листа")}</div>
        }
        open={modal}
        onCancel={() => setModal(false)}
        onOk={submit}
        okButtonProps={{ loading: editLoading }}
      >
        <PricelistForm
          form={form}
          handleSubmit={handleEdit}
          error={editError}
        />
      </Modal>
    </Space>
  );
};

export default TableActions;
