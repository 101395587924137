import { useQuery } from "@tanstack/react-query";

import { CasesApi } from "@/api/client/cases";

const useCaseNumbers = () => {
  const {
    data: caseNumbers,
    isLoading: getCaseNumbersLoading,
    error: getCaseNumbersError,
  } = useQuery({
    queryKey: ["cases", "caseNumbers", "caseUUIDs"],
    queryFn: CasesApi.getCaseNumbers,
  });

  return {
    caseNumbers: caseNumbers || [],
    getCaseNumbersLoading,
    getCaseNumbersError,
  };
};

export default useCaseNumbers;
