import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, Select, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import styles from "../../LaboratoryCaseForm.module.scss";

import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import { adultFormulaTeethIDs, childFormulaTeethIDs } from "@/root/consts";
import { TeethFormulaTypeEnum } from "@/components/CaseForm/components/Teeth";
import { getLowerJawTeeth, getUpperJawTeeth } from "@/utils/getJawTeeth";

const { Option } = Select;
const { confirm } = Modal;

interface TeethProps {
  activeJobID: string;
  activeProductID: string;
  teeth: string[];
  productTeeth: string[] | undefined;
  isLastTeeth: boolean;
}

const Teeth: FC<TeethProps> = ({
  activeJobID,
  activeProductID,
  teeth,
  productTeeth,
  isLastTeeth,
}) => {
  const { t } = useTranslation();
  const { state, dispatch } = useCaseFormContext();

  const handleAddTooth = (tooth: string) => {
    dispatch({
      type: ActionCreatorTypes.SetTooth,
      payload: { activeJobID, activeProductID, tooth },
    });
  };

  const handleDeleteTooth = (tooth: string) => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => {
        dispatch({
          type: ActionCreatorTypes.SetTooth,
          payload: { activeJobID, activeProductID, tooth },
        });
      },
    });
  };

  const allTeeth =
    state.teethFormulaUUID === TeethFormulaTypeEnum.adult
      ? adultFormulaTeethIDs
      : childFormulaTeethIDs;

  const isUpperJawChosen =
    productTeeth?.includes("FDI_AUJ") || productTeeth?.includes("FDI_CUJ");
  const isLowerJawChosen =
    productTeeth?.includes("FDI_ALJ") || productTeeth?.includes("FDI_CLJ");

  const filteredByUpperJaw = isUpperJawChosen
    ? allTeeth.filter((tooth) => !getUpperJawTeeth().includes(tooth))
    : allTeeth;
  const filteredByLowerJaw = isLowerJawChosen
    ? filteredByUpperJaw.filter((tooth) => !getLowerJawTeeth().includes(tooth))
    : filteredByUpperJaw;

  const isTeethFormula = state.teethFormulaUUID;

  return (
    <>
      <Space
        direction="vertical"
        style={{
          textAlign: "center",
          paddingBottom: isLastTeeth ? 48 : undefined,
        }}
      >
        {teeth?.map((tooth) => (
          <div key={tooth}>
            <span>{tooth.slice(4)}</span>
            <Button
              style={{ marginLeft: 10 }}
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteTooth(tooth)}
            />
          </div>
        ))}
      </Space>

      {isLastTeeth && (
        <div className={styles.addCellItem}>
          {isTeethFormula ? (
            <Space direction="vertical">
              <Select
                style={{ width: 110 }}
                mode="multiple"
                tagRender={() => <span />}
                value={productTeeth}
                onSelect={handleAddTooth}
                onDeselect={handleDeleteTooth}
              >
                {filteredByLowerJaw.map((id) => (
                  <Option key={id} value={id}>
                    {id}
                  </Option>
                ))}
              </Select>
              <span
                style={{ top: "auto", bottom: 0 }}
                className={styles.placeholder}
              >
                {t("Зуби")}
              </span>
            </Space>
          ) : (
            <span>{t("Оберіть тип формули")}</span>
          )}
        </div>
      )}
    </>
  );
};

export default Teeth;
