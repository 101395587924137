import { UsersApi } from "@/api/admin/users";
import { useQuery } from "@tanstack/react-query";

const useUsers = () => {
  const {
    data: users,
    isFetching: getUsersLoading,
    error: getUsersError,
  } = useQuery({
    queryKey: ["users"],
    queryFn: UsersApi.getUsers,
  });

  return {
    users: users || [],
    getUsersLoading,
    getUsersError,
  };
};

export default useUsers;
