import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Dropdown, MenuProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { Case } from "@/root/models/case";
import { Print } from "@/components/common";
import { LaboratoryCase } from "@/components";

interface PrintMenuProps {
  medCase: Case;
}

enum PrintMenuButtons {
  Invoice = "Invoice",
  Case = "Case",
  DevelopmentForm = "DevelopmentForm",
}

const PrintMenu: FC<PrintMenuProps> = ({ medCase }) => {
  const { t } = useTranslation();
  const [printKey, setPrintKey] = useState<PrintMenuButtons>();

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const key = e.key;
    if (key === PrintMenuButtons.Case) {
      setPrintKey(PrintMenuButtons.Case);

      // print after setting print key
      setTimeout(() => window.print());
    }
  };

  const items: MenuProps["items"] = [
    {
      label: t("Фінансовий інвойс"),
      key: PrintMenuButtons.Invoice,
    },
    {
      label: t("Замовлення", { count: 1 }),
      key: PrintMenuButtons.Case,
    },
    {
      label: t("Внутрішня форма для виробництва"),
      key: PrintMenuButtons.DevelopmentForm,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div>
      <Dropdown menu={menuProps}>
        <Button>
          <Space>
            {t("Друк")}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      {printKey === PrintMenuButtons.Case && (
        <Print>
          <LaboratoryCase medCase={medCase} />
        </Print>
      )}
    </div>
  );
};

export default PrintMenu;
