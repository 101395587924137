import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { AdminDashboardPage } from "@/pages";

const AdminPage: FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<AdminDashboardPage />} />
    </Routes>
  );
};

export default AdminPage;
