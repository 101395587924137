import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Space, Table } from "antd";

import {
  ActivePricelist,
  CreatePricelist,
  TableActions,
} from "@/modules/ClientPricelists/components";
import { ClientPricelist, Pricelist } from "@/root/models/pricelist";
import useClientPricelists from "@/hooks/useClientPricelists";
import PrintPricelist from "./components/PrintPricelist";

const Pricelists: FC = () => {
  const { t } = useTranslation();
  const {
    pricelists,
    getPricelistsLoading,
    createPricelist,
    deletePricelist,
    editPricelist,
  } = useClientPricelists();
  const [activePricelist, setActivePricelist] = useState<ClientPricelist>();

  const pricelistsColumn = [
    {
      title: t("Номер"),
      dataIndex: "number",
      key: "number",
    },
    {
      title: t("Дата"),
      dataIndex: "date",
      key: "date",
    },
    {
      title: t("Дата активації"),
      dataIndex: "activationDate",
      key: "activationDate",
    },
    {
      title: t("Контрагент"),
      dataIndex: "client",
      key: "client",
      render: (client: ClientPricelist["client"]) => (
        <span>{client.name}</span>
      ),
    },
    {
      title: t("Тип"),
      dataIndex: "pricelistType",
      key: "pricelistType",
      render: (type: Pricelist.Type) => <span>{type.name}</span>,
    },
    {
      title: t("Опис"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: "",
      key: "action",
      render: (_: any, pricelist: ClientPricelist) => (
        <TableActions
          pricelist={pricelist}
          onDelete={deletePricelist}
          onEdit={editPricelist}
        />
      ),
    },
  ];

  return (
    <Space direction="vertical">
      <Space>
        <CreatePricelist onCreate={createPricelist} />
        <Button disabled={!activePricelist} onClick={() => window.print()}>
          {t("Друк")}
        </Button>
      </Space>
      {activePricelist && <PrintPricelist pricelist={activePricelist} />}
      <div style={{ display: "flex", gap: 10 }}>
        <Table
          columns={pricelistsColumn}
          dataSource={pricelists.map((p) => ({
            ...p,
            key: p.clientsPricelistUUID,
          }))}
          pagination={false}
          expandedRowKeys={pricelists.map((p) => p.clientsPricelistUUID)}
          expandRowByClick
          loading={getPricelistsLoading}
          onRow={(pricelist) => ({
            onClick: () => setActivePricelist(pricelist),
          })}
        />
        {activePricelist && (
          <ActivePricelist pricelist={activePricelist} presentation />
        )}
      </div>
    </Space>
  );
};

export default Pricelists;
