import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Radio } from "antd";

import styles from "./MiddlePanel.module.scss";

import { Jobs } from "@/components/CaseForm/components";

const MiddlePanel: FC = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState("jobs");

  return (
    <div style={{ width: "35%" }}>
      <Radio.Group
        value={tab}
        onChange={(e) => setTab(e.target.value)}
        style={{ marginBottom: 8 }}
      >
        <Radio.Button value="jobs">{t("Вироби")}</Radio.Button>
        <Radio.Button value="parameters">{t("Параметри")}</Radio.Button>
      </Radio.Group>
      <div className={styles.container}>
          {tab === "jobs" && <Jobs withParameters={false} />}
          {tab === "parameters" && <Jobs withParameters={true} />}
      </div>
    </div>
  );
};

export default MiddlePanel;
