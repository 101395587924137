import { apiBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";
import { Task } from "@/root/models/task";
import { ResponseDataWithPagination } from "@/api/types";

export interface CreateTaskPayload {
  dueDate: string;
  completionDate: string | null;
  responsibleUUID: string;
  caseUUID: string;
  trackingStatusUUID: string;
  taskStatusUUID: string;
  trackingNumber: string;
}

export type EditTaskPayload =
  | {
      taskStatusUUID: string;
    }
  | {
      trackingStatusUUID: string;
    };

export const TasksApi = {
  getTasks: async (
    caseUUID?: string,
    page?: number,
    pageLimit?: number
  ): Promise<ResponseDataWithPagination<Task[]>> => {
    const data = await makeRequest<Task[], true>(
      {
        url: apiBaseurl + "/tasks",
        params: pageLimit
          ? {
              caseUUID,
              page,
              "page-limit": pageLimit,
            }
          : {
              caseUUID,
            },
      },
      { pagination: true }
    );
    return data;
  },

  create: async (payload: CreateTaskPayload): Promise<Task> => {
    const data = await makeRequest<Task>({
      url: apiBaseurl + "/tasks",
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async ({
    id,
    payload,
  }: {
    id: string;
    payload: CreateTaskPayload | EditTaskPayload;
  }): Promise<Task> => {
    const data = await makeRequest<Task>({
      url: apiBaseurl + `/tasks/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/tasks/${id}`,
      method: "delete",
    });
  },
};
