import { useMutation, useQueryClient } from "@tanstack/react-query";

import { UsersApi } from "@/api/admin/users";
import { User } from "@/root/models/user";

const useDeleteUser = (onError?: (message: string) => void) => {
  const queryClient = useQueryClient();
  const deleteUserMutation = useMutation({
    mutationFn: UsersApi.delete,
    onError: (error) => onError?.((error as Error).message),
    onSuccess: (_, userUUID) => {
      queryClient.setQueryData(["users"], (prev: User[] | undefined) =>
        (prev || []).filter((p) => p.userUUID !== userUUID)
      );
    },
  });

  const deleteUser = async (id: string) => {
    await deleteUserMutation.mutateAsync(id);
  };

  const error = (deleteUserMutation.error as Error)?.message as string;

  return { deleteUser, loading: deleteUserMutation.isLoading, error };
};

export default useDeleteUser;
