import { FC, useEffect } from "react";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Alert, DatePicker, Form, FormInstance, Input, Select } from "antd";

import { CreateTaskPayload } from "@/api/tasks";
import useTaskStatuses from "@/hooks/useTaskStatuses";
import useTrackingStatuses from "@/hooks/useTrackingStatuses";
import useCouriers from "@/hooks/useCourier";
import { TaskStatusEnum } from "@/root/types";
import useCaseNumbers from "@/hooks/useCaseNumbers";

dayjs.extend(weekday);
dayjs.extend(localeData);

interface TaskFormProps {
  form: FormInstance<CreateTaskPayload>;
  handleSubmit: (values: CreateTaskPayload) => void;
  error: string;
  isCaseUUIDDisabled?: boolean;
  type?: "edit" | "create";
}

const { Option } = Select;

const TaskForm: FC<TaskFormProps> = ({
  form,
  handleSubmit,
  error,
  isCaseUUIDDisabled,
  type = "create",
}) => {
  const { t } = useTranslation();
  const { caseNumbers, getCaseNumbersLoading } = useCaseNumbers();
  const { taskStatuses, getStatusesLoading: getTaskStatusesLoading } =
    useTaskStatuses();
  const { trackingStatuses, getStatusesLoading: getTrackingStatusesLoading } =
    useTrackingStatuses();
  const { couriers, getCouriersLoading } = useCouriers();

  useEffect(() => {
    if (type !== "create") {
      return;
    }

    const status = taskStatuses.find(
      (status) => status.taskStatusUUID === TaskStatusEnum["New"]
    );
    if (status) {
      form.setFieldValue("taskStatusUUID", status.taskStatusUUID);
    }
  }, [taskStatuses]);

  const submit = (values: CreateTaskPayload) => {
    handleSubmit({
      ...values,
      completionDate: values.completionDate
        ? dayjs(values.completionDate).format("YYYY.MM.DD HH:mm") + ":00"
        : null,
      dueDate: dayjs(values.dueDate).format("YYYY.MM.DD HH:mm") + ":00",
    });
  };

  return (
    <Form
      labelCol={{ span: 6 }}
      requiredMark={false}
      form={form}
      onFinish={submit}
    >
      <Form.Item
        name="caseUUID"
        label={t("Замовлення_one")}
        rules={[{ required: true, message: "Missing last name" }]}
      >
        <Select
          showSearch
          filterOption={(inputValue, option) =>
            // @ts-ignore
            option?.children?.toLowerCase().includes(inputValue.toLowerCase())
          }
          disabled={isCaseUUIDDisabled}
          loading={getCaseNumbersLoading}
        >
          {caseNumbers.map((medCase) => (
            <Option key={medCase.caseUUID} value={medCase.caseUUID}>
              {medCase.caseNumber}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="courierUUID"
        label={t("Кур'єр")}
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
      >
        <Select loading={getCouriersLoading}>
          {couriers.map((courier) => (
            <Option key={courier.courierUUID} value={courier.courierUUID}>
              {courier.name || " "}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="taskStatusUUID"
        label={t("Статус завдання")}
        rules={[{ required: true, message: "Missing last name" }]}
      >
        <Select loading={getTaskStatusesLoading}>
          {taskStatuses.map((status) => (
            <Option key={status.taskStatusUUID} value={status.taskStatusUUID}>
              {status.name || " "}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="trackingStatusUUID"
        label={t("Статус відстеження")}
        rules={[{ required: true, message: "Missing last name" }]}
      >
        <Select loading={getTrackingStatusesLoading}>
          {trackingStatuses.map((status) => (
            <Option
              key={status.trackingStatusUUID}
              value={status.trackingStatusUUID}
            >
              {status.name || " "}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="trackingNumber"
        label={t("Номер відстеження")}
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
      >
        <Input placeholder={t("Введіть значення") as string} />
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: t("Введіть дату") as string,
          },
        ]}
        name="dueDate"
        label={t("Дата завершення")}
      >
        <DatePicker
          showTime
          format="YYYY.MM.DD HH:mm"
          showSecond={false}
          placeholder={t("Виберіть дату та час") as string}
        />
      </Form.Item>
      {type === "edit" && (
        <Form.Item name="completionDate" label={t("Дата виконання")}>
          <DatePicker
            showTime
            format="YYYY.MM.DD HH:mm"
            showSecond={false}
            placeholder={t("Виберіть дату та час") as string}
          />
        </Form.Item>
      )}

      {!!error && <Alert message={error} type="error" />}
    </Form>
  );
};

export default TaskForm;
