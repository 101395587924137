import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "antd";
import { useForm } from "antd/es/form/Form";

import AdvancedCasesSearchForm, {
  AdvancedSearchParameters,
} from "@/components/AdvancedCasesSearchForm";
import { SearchCasesParameters } from "@/modules/Cases/useCases";
import dayjs from "dayjs";
import { DATE_FORMAT } from "@/root/consts";

interface AdvancedSearchProps {
  open: boolean;
  onClose: () => void;
  onSearch: (values: SearchCasesParameters) => void;
}

const AdvancedSearch: FC<AdvancedSearchProps> = ({
  open,
  onSearch,
  onClose,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const handleSubmit = (values: AdvancedSearchParameters) => {
    onSearch({
      status: values.statuses?.join(",") || undefined,
      patientUUID: values.patients?.join(",") || undefined,
      employeeUUID: values.employees?.join(",") || undefined,
      clientUUID: values.adminClients?.join(",") || undefined,
      clientsEmployeeUUID: values.clientEmployees?.join(",") || undefined,
      date:
        values.date
          ?.map((d) => dayjs(new Date(d)).format(DATE_FORMAT))
          .join(",") || undefined,
      dueDate: values.dueDate
        ?.map((d) => dayjs(new Date(d)).format(DATE_FORMAT))
        .join(","),
    });
    onClose();
  };

  return (
    <Modal
      maskClosable={false}
      okText={t("Шукати")}
      title={t("Розширений пошук")}
      open={open}
      onCancel={onClose}
      onOk={form.submit}
    >
      <AdvancedCasesSearchForm form={form} handleSubmit={handleSubmit} />
    </Modal>
  );
};

export default AdvancedSearch;
