import React, { FC } from "react";

import { Select } from "antd";

import useEmployees from "@/hooks/useEmployees";

const { Option } = Select;

const EmployeesSelect: FC = () => {
  const { employees, getEmployeesLoading } = useEmployees();

  return (
    <Select
      mode="multiple"
      loading={getEmployeesLoading}
      listHeight={1000}
      allowClear
    >
      {employees?.map((employee) => (
        <Option key={employee.employeeUUID} value={employee.employeeUUID}>
          {employee.name || " "}
        </Option>
      ))}
    </Select>
  );
};

export default EmployeesSelect;
