import { apiBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";
import { Task } from "@/root/models/task";

export const TrackingStatusesApi = {
  getStatuses: async (): Promise<Task.TrackingStatus[]> => {
    const data = await makeRequest<Task.TrackingStatus[]>({
      url: apiBaseurl + "/tracking-statuses",
    });
    return data;
  },
};
