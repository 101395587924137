import React, { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, DatePicker, Select, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";

import styles from "./Cases.module.scss";

import useCases from "@/modules/Cases/useCases";
import { EmployeeCase, Case } from "@/root/models/case";
import {
  AdvancedSearch,
  ChosenCase,
  TableActions,
} from "@/modules/Cases/components";
import useCaseStatuses from "@/hooks/useCaseStatuses";
import { DATE_FORMAT } from "@/root/consts";
import { useAppSelector } from "@/hooks/redux";
import { LaboratoryCase, SearchInput } from "@/components";
import { CasesApi } from "@/api/client/cases";
import { Block, Pagination, Print } from "@/components/common";
import { CaseStatusEnum, UserTypeEnum } from "@/root/types";
import { isLaptop } from "@/utils/isLaptop";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Cases: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    cases,
    currentPage,
    pages,
    getCasesLoading,
    searchCases,
    deleteCase,
    setCaseDate,
    setDueDate,
    setCaseStatuses,
    setCurrentPage,
  } = useCases();
  const { statuses, loading } = useCaseStatuses();
  const [chosenCase, setChosenCase] = useState<Case>();
  const [advancedSearchModal, setAdvancedSearchModal] = useState(false);
  const userType = useAppSelector((state) => state.userReducer.user?.userType);

  const getColumns = (): ColumnsType<Case> => {
    if (!userType) {
      return [];
    }

    switch (userType.userTypeUUID) {
      case UserTypeEnum["Client's employee"]:
      case UserTypeEnum["Client's admin"]:
        return [
          {
            title: t("Дата"),
            dataIndex: "arrival",
            key: "arrival",
            render: (arrival: Case["arrival"]) => (
              <span style={{ whiteSpace: "nowrap" }}>
                {arrival.slice(0, 10)}
              </span>
            ),
            sorter: (a, b) =>
              new Date(a.arrival).getTime() - new Date(b.arrival).getTime(),
            defaultSortOrder: "ascend",
          },
          {
            title: t("Дата завершення"),
            dataIndex: "dueDate",
            key: "dueDate",
            render: (date: Case["dueDate"]) => (
              <span style={{ whiteSpace: "nowrap" }}>{date.slice(0, 10)}</span>
            ),
            sorter: (a, b) =>
              new Date(a.dueDate || new Date()).getTime() -
              new Date(b.dueDate || new Date()).getTime(),
          },
          {
            title: t("Співробітник контрагента"),
            dataIndex: "clientsEmployee",
            key: "clientsEmployee",
            render: (clientsEmployee: Case["clientsEmployee"]) => (
              <span>{clientsEmployee?.name}</span>
            ),
          },
          {
            title: t("Пацієнт"),
            dataIndex: "patient",
            key: "patient",
            render: (patient: Case["patient"]) => <span>{patient.name}</span>,
          },
          {
            title: t("Статус"),
            dataIndex: "status",
            key: "status",
            render: (status: Case["status"]) => <span>{status?.name}</span>,
          },
        ];
      case UserTypeEnum["Employee"]:
      case UserTypeEnum["Lab's admin"]:
        return [
          {
            title: t("Лоток") + " #",
            dataIndex: "pan",
            key: "pan",
            render: (pan: EmployeeCase["pan"]) => (
              <span>{"panUUID" in pan && pan.name}</span>
            ),
          },
          {
            title: t("Дата"),
            dataIndex: "arrival",
            key: "arrival",
            render: (arrival: Case["arrival"]) => (
              <span style={{ whiteSpace: "nowrap" }}>
                {arrival.slice(0, 10)}
              </span>
            ),
            sorter: (a, b) =>
              new Date(a.arrival).getTime() - new Date(b.arrival).getTime(),
            defaultSortOrder: "ascend",
          },
          {
            title: t("Дата завершення"),
            dataIndex: "dueDate",
            key: "dueDate",
            render: (date: Case["dueDate"]) => (
              <span style={{ whiteSpace: "nowrap" }}>{date.slice(0, 10)}</span>
            ),
            sorter: (a, b) =>
              new Date(a.dueDate || new Date()).getTime() -
              new Date(b.dueDate || new Date()).getTime(),
          },
          {
            title: t("КЛІЄНТ (клієнт лабораторії - клініки)"),
            dataIndex: "client",
            key: "client",
            render: (client: Case["client"]) => <span>{client.name}</span>,
          },
          {
            title: t("Співробітник контрагента"),
            dataIndex: "clientsEmployee",
            key: "clientsEmployee",
            render: (clientsEmployee: Case["clientsEmployee"]) => (
              <span>{clientsEmployee?.name}</span>
            ),
          },
          {
            title: t("Пацієнт"),
            dataIndex: "patient",
            key: "patient",
            render: (patient: Case["patient"]) => <span>{patient?.name}</span>,
          },
          {
            title: t("Відповідальна ос. (працівник Лаби)"),
            dataIndex: "employee",
            key: "employee",
            render: (activeHandler: Case["activeHandler"]) => (
              <span>{activeHandler?.activeHandlerName}</span>
            ),
          },
          {
            title: t("Статус"),
            dataIndex: "status",
            key: "status",
            render: (status: Case["status"]) => <span>{status?.name}</span>,
          },
        ];
      default:
        return [];
    }
  };

  const casesColumns: ColumnsType<Case> = [
    {
      title: t("Номер замовлення"),
      dataIndex: "caseNumber",
      key: "caseNumber",
    },
    ...getColumns(),
    {
      title: "",
      key: "action",
      render: (_: any, medCase: Case) => {
        if (
          (userType?.userTypeUUID === UserTypeEnum["Client's employee"] ||
            userType?.userTypeUUID === UserTypeEnum["Client's admin"]) &&
          medCase.status.statusUUID !== CaseStatusEnum.New
        ) {
          return null;
        }
        return (
          <TableActions
            medCase={medCase}
            onDelete={deleteCase}
            onEditButtonClick={handleEdit}
          />
        );
      },
    },
  ];

  const handleEdit = async (medCase: Case) => {
    navigate("edit", { state: medCase.caseUUID });
  };

  const handleCreate = () => {
    navigate("create");
  };

  const handleStatusSelect = (statuses: string[]) => {
    if (statuses.length > 0) {
      setCaseStatuses(statuses);
    } else {
      setCaseStatuses(undefined);
    }
  };

  const handleCaseDateSelect = (values: [string, string]) => {
    if (values[0] && values[1]) {
      setCaseDate(values);
    } else {
      setCaseDate(undefined);
    }
  };

  const handleDueDateSelect = (values: [string, string]) => {
    if (values[0] && values[1]) {
      setDueDate(values.join(","));
    } else {
      setDueDate(undefined);
    }
  };

  const handleChooseCase = async (caseUUID: string) => {
    const fullCase = await CasesApi.getFullCase(caseUUID);
    setChosenCase(fullCase);
  };

  const printCase = () => {
    window.print();
  };

  const isSmallTable =
    isLaptop() &&
    userType &&
    userType.userTypeUUID !== UserTypeEnum["Client's admin"] &&
    userType.userTypeUUID !== UserTypeEnum["Client's employee"];

  const tableRef = useRef<HTMLDivElement>(null);

  const paginationRange = tableRef.current
    ? Math.floor(
        (tableRef.current.getBoundingClientRect().width - 8 - 30 - 39 * 4) / 39
      )
    : 1;

  return (
    <>
      <Space style={{ marginBottom: 20 }}>
        <SearchInput />
        <Button onClick={() => setAdvancedSearchModal(true)}>
          {t("Розширений пошук")}
        </Button>
      </Space>
      <Space
        className={styles.container}
        direction="vertical"
        style={{ width: "100%" }}
      >
        <Space>
          <Button style={{ marginBottom: 10 }} onClick={handleCreate}>
            {t("Створити замовлення")}
          </Button>
          {chosenCase && (
            <Button style={{ marginBottom: 10 }} onClick={printCase}>
              {t("Надрукувати замовлення")}
            </Button>
          )}
        </Space>
        <div style={{ display: "flex", gap: 8, width: "100%" }}>
          <Space direction="vertical" style={{ width: "calc(70% - 8px)" }}>
            <Space size="large">
              <div>
                <span>{t("Статус")}: </span>
                <Select
                  listHeight={1000}
                  style={{ minWidth: 150 }}
                  mode="multiple"
                  allowClear
                  showSearch
                  loading={loading}
                  onChange={handleStatusSelect}
                >
                  {statuses.map((status) => (
                    <Option key={status.statusUUID} value={status.statusUUID}>
                      {status.name || " "}
                    </Option>
                  ))}
                </Select>
              </div>
              <div>
                <span>{t("Дата замовлення")}: </span>
                <RangePicker
                  format={DATE_FORMAT}
                  onChange={(_, v) => handleCaseDateSelect(v)}
                />
              </div>
              <div>
                <span>{t("Дата завершення")}: </span>
                <RangePicker
                  format={DATE_FORMAT}
                  onChange={(_, v) => handleDueDateSelect(v)}
                />
              </div>
            </Space>
            <Table
              ref={tableRef}
              size={isSmallTable ? "small" : undefined}
              rowClassName={(medCase) =>
                medCase.caseUUID === chosenCase?.caseUUID
                  ? styles.activeRow
                  : undefined
              }
              columns={casesColumns}
              dataSource={cases.map((c) => ({ ...c, key: c.caseUUID }))}
              pagination={false}
              loading={getCasesLoading}
              onRow={(medCase) => {
                return {
                  onClick: () => handleChooseCase(medCase.caseUUID),
                };
              }}
            />
            <Block
              style={{
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                currentPage={currentPage}
                pages={pages}
                paginationRange={paginationRange}
                setCurrentPage={setCurrentPage}
              />
            </Block>
          </Space>
          <div style={{ width: "30%" }}>
            {chosenCase && <ChosenCase medCase={chosenCase} />}
          </div>
        </div>
      </Space>
      {chosenCase && (
        <Print>
          <LaboratoryCase medCase={chosenCase} />
        </Print>
      )}
      <AdvancedSearch
        open={advancedSearchModal}
        onSearch={searchCases}
        onClose={() => setAdvancedSearchModal(false)}
      />
    </>
  );
};

export default Cases;
