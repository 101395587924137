import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Space, Table } from "antd";

import usePatients from "@/hooks/usePatients";
import { CreatePatient, TableActions } from "@/modules/Patients/components";
import { Patient } from "@/root/models/patient";

const Patients: FC = () => {
  const { t } = useTranslation();
  const {
    patients,
    getPatientsLoading,
  } = usePatients({});

  const patientsColumn = [
    {
      title: t("Ім'я"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Дата народження"),
      dataIndex: "birthDate",
      key: "birthDate",
    },
    {
      title: t("Телефон"),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "E-mail",
      dataIndex: "mail",
      key: "mail",
    },
    {
      title: "",
      key: "action",
      render: (_: any, patient: Patient) => (
        <TableActions
          patient={patient}
        />
      ),
    },
  ];

  return (
    <Space direction="vertical">
      <CreatePatient />
      <Table
        columns={patientsColumn}
        dataSource={patients.map((p) => ({ ...p, key: p.patientUUID }))}
        pagination={false}
        loading={getPatientsLoading}
      />
    </Space>
  );
};

export default Patients;
