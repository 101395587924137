import { FC, useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Button, Modal, notification, Space } from "antd";
import { useForm } from "antd/es/form/Form";

import { Patient } from "@/root/models/patient";
import { CreatePatientPayload } from "@/api/patients";
import PatientForm from "@/components/PatientForm";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import usePatients from "@/hooks/usePatients";

const { confirm } = Modal;

interface TableActionsProps {
  patient: Patient;
}

const TableActions: FC<TableActionsProps> = ({ patient }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [modal, setModal] = useState(false);

  const onDeleteError = (message: string) => {
    notification.error({
      message,
    });
  };

  const onEditSuccess = () => {
    setModal(false);
  };

  const { deletePatient, deleteLoading, editPatient, editLoading, editError } =
    usePatients({ onDeleteError, onEditSuccess });

  const handleDelete = async () => {
    await deletePatient(patient.patientUUID);
  };

  const handleEdit = async (values: CreatePatientPayload) => {
    editPatient(patient.patientUUID, {
      ...values,
      birthDate: dayjs(values.birthDate).format("DD.MM.YYYY"),
    });
  };

  const submit = () => {
    form.submit();
  };

  const openModal = () => {
    let date = new Date(patient.birthDate);
    if (date.toString() === "Invalid Date") {
      const dateParts = patient.birthDate.split("-");
      date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
    }

    form.setFieldsValue({
      ...patient,
      birthDate: dayjs(date),
    });
    setModal(true);
  };

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  return (
    <Space className="table-actions" size="middle">
      <Button size="small" icon={<EditOutlined />} onClick={openModal} />
      <Button
        size="small"
        icon={<DeleteOutlined />}
        loading={deleteLoading}
        danger
        onClick={handleDeleteButtonClick}
      />
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={
          <div style={{ marginBottom: 20 }}>{t("Редагування пацієнта")}</div>
        }
        open={modal}
        onCancel={() => setModal(false)}
        onOk={submit}
        okButtonProps={{ loading: editLoading }}
      >
        <PatientForm form={form} handleSubmit={handleEdit} error={editError} />
      </Modal>
    </Space>
  );
};

export default TableActions;
