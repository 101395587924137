import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import ClientDashboardPage from "@/pages/ClientDashboardPage";

const ClientPage: FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<ClientDashboardPage />} />
    </Routes>
  );
};

export default ClientPage;
