import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ProductsApi } from "@/api/admin/products";
import { Product } from "@/root/models/product";

const useEditProductGroup = (onError?: (message: string) => void) => {
  const queryClient = useQueryClient();
  const editProductGroupMutation = useMutation({
    mutationFn: ProductsApi.edit,
    onError: (error) => onError?.((error as Error).message),
    onSuccess: () => {
      queryClient.invalidateQueries(["products"], { exact: true});
    },
  });

  const editProductGroup = (product: Product, newParentProductUUID: string) => {
    editProductGroupMutation.mutate({
      id: product.productUUID,
      payload: {
        isPoint: product.isPoint,
        name: product.name,
        isGroup: product.isGroup,
        parentProductUUID: newParentProductUUID,
        productsParametersGroups:
          product.productsParametersGroups?.map(
            (p) => p.productsParametersGroupUUID
          ) || [],
      },
    });
  };

  return editProductGroup;
};

export default useEditProductGroup;
