import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { Attachment, Case } from "@/root/models/case";
import { CaseStatusEnum } from "@/root/types";
import { ResponseDataWithPagination } from "@/api/types";

export interface CreateCasePayload {
  clientUUID: string;
  clientsEmployeeUUID: string;
  employeeUUID?: string;
  patientUUID: string;
  description: string;
  attachments: Attachment[];
  teethFormulaUUID: string;
  status?: CaseStatusEnum;
  arrival: string;
  dueDate: string;
  jobs: {
    jobUUID: string;
    jobName: string;
    products: {
      jobProductUUID: string;
      productUUID: string;
      differentTeethParameters: boolean;
      combinedProduct: boolean;
      quantity: number;
      price: number;
      teeth: string[];
      productsParametersGroups: {
        productsParametersGroupUUID: string;
        productsParameters: {
          productsParameterUUID: string;
          value: string | number | boolean | string[] | null;
          tooth: string[];
        }[];
      }[];
    }[];
    extras?: {
      extraUUID: string;
      price: number | null;
    }[];
    spareParts?: {
      sparePartUUID: string;
      price: number | null;
    }[];
  }[];
}

export const CasesApi = {
  getCaseNumbers: async (): Promise<
    { caseNumber: string; caseUUID: string }[]
  > => {
    const data = await makeRequest<{ caseNumber: string; caseUUID: string }[]>({
      url: apiBaseurl + "/cases",
      params: { status: CaseStatusEnum.New, fields: "caseNumber,caseUUID" },
    });
    return data;
  },

  getCases: async (
    params?: Record<string, string | number | undefined>
  ): Promise<ResponseDataWithPagination<Case[]>> => {
    const data = await makeRequest<Case[], true>(
      {
        url: apiBaseurl + "/cases",
        params,
      },
      { pagination: true }
    );
    return data;
  },

  getFullCase: async (id: string): Promise<Case> => {
    const data = await makeRequest<Case>({
      url: apiBaseurl + `/cases/${id}`,
    });

    // @ts-ignore
    if (!("name" in data.clientsEmployee)) {
      return { ...data, clientsEmployee: null };
    }

    return data;
  },

  create: async (payload: CreateCasePayload): Promise<Case> => {
    const data = await makeRequest<Case>({
      url: apiBaseurl + "/cases",
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async (id: string, payload: CreateCasePayload): Promise<Case> => {
    const data = await makeRequest<Case>({
      url: apiBaseurl + `/cases/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/cases/${id}`,
      method: "delete",
    });
  },
};
