import { FC, ReactNode, useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/uk";

import { ConfigProvider } from "antd";
import enUS from "antd/locale/en_US";
import ua from "antd/locale/uk_UA";

import { useAppSelector } from "@/hooks/redux";

interface LanguageProviderProps {
  children?: ReactNode;
}

const LanguageProvider: FC<LanguageProviderProps> = ({ children }) => {
  const language = useAppSelector((state) => state.userReducer.user?.language);
  const [locale, setLocale] = useState(enUS);

  useEffect(() => {
    if (language) {
      const loc = language === "en" ? enUS : ua;
      setLocale(loc);
      dayjs.locale(language);
    }
  }, [language]);

  return <ConfigProvider locale={locale}>{children}</ConfigProvider>;
};

export default LanguageProvider;
