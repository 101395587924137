import React, { FC, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Form, notification, Space, Tabs, TabsProps } from "antd";
import { useForm } from "antd/es/form/Form";

import styles from "./LaboratoryCaseForm.module.scss";

import {
  ActionCreatorTypes,
  CaseFormContext,
  caseFormInitialState,
  caseFormReducer,
  CaseFormState,
} from "@/components/CaseForm/CaseFormContext";
import { CreateCasePayload } from "@/api/client/cases";
import {
  CaseDetails,
  PrintMenu,
  Extras,
  SpareParts,
  Products,
  LabCaseFiles,
  Delivery,
} from "@/components/LaboratoryCaseForm/components";
import {Case, EmployeeCase} from "@/root/models/case";
import { Block } from "@/components/common";
import formJobToJob from "@/components/CaseForm/formJobToJob";
import useFilters from "@/hooks/useFilters";
import { calculateGrandTotal } from "@/utils/calculateCasePrice";
import { CaseStatusEnum } from "@/root/types";

interface CaseFormProps {
  medCase: EmployeeCase | null;
  initialState?: CaseFormState;
  loading: boolean;
  onFinish: (data: CreateCasePayload, close: boolean) => Promise<Case | undefined>;
  onCancel: () => void;
}

const LaboratoryCaseForm: FC<CaseFormProps> = ({
  initialState,
  medCase,
  loading,
  onCancel,
  onFinish,
}) => {
  const { t } = useTranslation();
  const { filters } = useFilters();
  const [tabKey, setTabKey] = useState("products");
  const [state, dispatch] = useReducer(
    caseFormReducer,
    initialState || caseFormInitialState
  );
  const [grandTotal, setGrandTotal] = useState<number | null>(null);
  const [form] = useForm();

  useEffect(() => {
    if (!state.teethFormulaUUID) {
      return;
    }

    setGrandTotal(
      calculateGrandTotal(
        state.jobs as CaseFormState.Job[],
        state.teethFormulaUUID
      )
    );
  }, [state.teethFormulaUUID, state.jobs]);

  const items: TabsProps["items"] = [
    {
      key: "products",
      label: t("Вироби"),
      children: <Products />,
    },
    {
      key: "extras",
      label: t("Додаткові роботи і послуги"),
      children: <Extras />,
    },
    {
      key: "spareParts",
      label: t("Матеріали"),
      children: <SpareParts />,
    },
    {
      key: "files",
      label: t("Файли"),
      children: <LabCaseFiles />,
    },
    {
      key: "delivery",
      label: t("Доставка"),
      children: (
        <Delivery
          save={() => handleFinish(false)}
          caseUUID={state.caseUUID || undefined}
          isCaseAccepted={
            state.status?.statusUUID === CaseStatusEnum.Accepted
          }
        />
      ),
    },
  ];

  const checkError = () => {
    for (let job of state.jobs as CaseFormState.Job[]) {
      for (let product of job.products) {
        if (!product.productsParametersGroups) {
          return { jobID: job.jobID, message: t("Оберіть виріб") };
        } else if (!product.teeth?.length) {
          return { jobID: job.jobID, message: t("Оберіть зуби") };
        } else if (typeof product.price !== "number") {
          // price can be 0
          return {
            jobID: job.jobID,
            message: t("Заповніть ціну виробу"),
          };
        } else {
          for (let group of product.productsParametersGroups) {
            for (let parameter of group.productsParameters) {
              if (parameter.required && !parameter.value) {
                const toothText = parameter.tooth ? ` ${parameter.tooth}` : "";
                return {
                  jobID: job.jobID,
                  message: t("Заповніть обов'язкові параметри") + toothText,
                };
              }
            }
          }
        }
      }
    }
  };

  const handleFinish = async (close: boolean = true) => {
    dispatch({ type: ActionCreatorTypes.ClearErrors });
    await form.validateFields();
    const error = checkError();
    if (error) {
      notification.error({ message: error.message });
      dispatch({ type: ActionCreatorTypes.SetError, payload: error });
      return;
    }
    const data = {
      clientUUID: state.client?.clientUUID,
      clientsEmployeeUUID: state.clientsEmployee?.clientsEmployeeUUID || null,
      activeHandlerUUID: state.activeHandler?.activeHandlerUUID || null,
      patientUUID: state.patient?.patientUUID,
      description: state.description,
      teethFormulaUUID: state.teethFormulaUUID,
      arrival: state.arrival,
      dueDate: state.dueDate,
      panUUID: state.panUUID,
      statusUUID: state.status?.statusUUID,
      attachments: state.attachments.map((att) => ({
        ...att,
        description: state.description,
      })),
      jobs: state.jobs.map((job: CaseFormState.Job) =>
        formJobToJob(job, filters)
      ),
    } as CreateCasePayload;
    dispatch({ type: ActionCreatorTypes.SetSaveButtonActive, payload: false });
    const medCase = await onFinish(data, close);
    if (medCase) {
      dispatch({ type: ActionCreatorTypes.SetCaseUUID, payload: medCase.caseUUID });
    }
    return true
  };

  const handleCalculateTotalClick = () => {
    if (!state.teethFormulaUUID) {
      return;
    }

    setGrandTotal(
      calculateGrandTotal(
        state.jobs as CaseFormState.Job[],
        state.teethFormulaUUID
      )
    );
  };

  return (
    <CaseFormContext.Provider value={{ state, dispatch }}>
      <Space className={styles.actionButtons}>
        <Button htmlType="button" disabled={loading} onClick={onCancel}>
          {t("Скасувати")}
        </Button>
        <Button
          disabled={!state.saveButtonActive}
          onClick={() => handleFinish(false)}
          loading={loading}
        >
          {t("Зберегти")}
        </Button>
        <Button onClick={() => handleFinish(true)} loading={loading}>
          {t("Зберегти і закрити")}
        </Button>
      </Space>
      <Form form={form} requiredMark={false}>
        <Block>
          <CaseDetails form={form} medCase={medCase} grandTotal={grandTotal} />
        </Block>
        <Space
          style={{
            justifyContent: "space-between",
            width: "100%",
            marginTop: 10,
          }}
        >
          <Button onClick={handleCalculateTotalClick}>
            {t("Розрахувати вартість замовлення")}
          </Button>
          {medCase && <PrintMenu medCase={medCase} />}
        </Space>
        <Tabs
          destroyInactiveTabPane
          style={{ marginTop: 10 }}
          type="card"
          items={items}
          activeKey={tabKey}
          onTabClick={setTabKey}
        />
      </Form>
    </CaseFormContext.Provider>
  );
};

export default LaboratoryCaseForm;
