import { apiBaseurl } from "@/api/baseurl";
import { ClientEmployee } from "@/root/models/clientEmployee";
import makeRequest from "@/api/makeRequest";

export interface CreateClientEmployeePayload {
  name: string;
  login: string;
  password: string;
  disableAccess: boolean;
}

export const ClientEmployeesApi = {
  getEmployees: async (clientUUID?: string): Promise<ClientEmployee[]> => {
    const data = await makeRequest<ClientEmployee[]>({
      url: apiBaseurl + "/clients-employees",
      params: clientUUID && {
        clientUUID,
      },
    });
    return data;
  },

  create: async (
    payload: CreateClientEmployeePayload
  ): Promise<ClientEmployee> => {
    const data = await makeRequest<ClientEmployee>({
      url: apiBaseurl + "/clients-employees",
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async ({
    id,
    payload,
  }: {
    id: string;
    payload: CreateClientEmployeePayload;
  }): Promise<ClientEmployee> => {
    const data = await makeRequest<ClientEmployee>({
      url: apiBaseurl + `/clients-employees/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/clients-employees/${id}`,
      method: "delete",
    });
  },
};
