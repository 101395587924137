import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form, Select, Spin } from "antd";

import usePatients from "@/hooks/usePatients";

const { Option } = Select;

interface PatientsSelectProps {
  clientUUIDs: string[];
}

const PatientsSelect: FC<PatientsSelectProps> = ({ clientUUIDs }) => {
  const { t } = useTranslation();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [name, setName] = useState<string>("");
  const { patients, getPatientsLoading } = usePatients({
    clientUUID: clientUUIDs.join(","),
    name,
  });

  useEffect(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setName(searchValue);
    }, 500);
  }, [searchValue]);

  return (
    <Form.Item name="patients" label={t("Пацієнт")}>
      <Select
        labelInValue
        filterOption={false}
        notFoundContent={getPatientsLoading ? <Spin size="small" /> : null}
        showSearch
        mode="multiple"
        loading={getPatientsLoading}
        listHeight={1000}
        allowClear
        searchValue={searchValue}
        defaultActiveFirstOption={false}
        autoClearSearchValue={false}
        onSearch={setSearchValue}
        onBlur={() => setSearchValue('')}
      >
        {name &&
          patients?.map((patient) => (
            <Option key={patient.patientUUID} value={patient.patientUUID}>
              {`${patient.name} ${patient.birthDate.slice(0, 10)}` || " "}
            </Option>
          ))}
      </Select>
    </Form.Item>
  );
};

export default PatientsSelect;
