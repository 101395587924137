import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "antd";
import { useForm } from "antd/es/form/Form";

import { UserForm } from "@/components";
import useAddUser from "@/modules/Users/components/CreateUser/useAddUser";

interface CreateUserModalProps {
  open: boolean;
  onCancel: () => void;
}

const CreateUserModal: FC<CreateUserModalProps> = ({ open, onCancel }) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const onSuccess = () => {
    onCancel();
    form.resetFields();
  };

  const { addUser, loading, error } = useAddUser(onSuccess);

  const submit = () => {
    form.submit();
  };

  return (
    <Modal
      maskClosable={false}
      okText={t("Створити")}
      title={<div style={{ marginBottom: 20 }}>{t("Створення співробітника")}</div>}
      open={open}
      onCancel={onCancel}
      onOk={submit}
      okButtonProps={{ loading }}
    >
      <UserForm form={form} handleSubmit={addUser} error={error} />
    </Modal>
  );
};

export default CreateUserModal;
