import { jaws } from "@/root/consts";

export enum LoadingStatus {
  DEFAULT = "DEFAULT",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export enum UserTypeEnum {
  "Client's employee" = "7fd6c354-ff3b-4b04-9256-8dee24d116bd",
  "Client's admin" = "65e019a5-ff5d-4d3b-83da-720dd6f2d4d5",
  "Empty" = "97110491-3cb7-4b27-afc9-194dabac31c5",
  "Employee" = "f6293af1-f990-4484-b088-1fb9e55d3f29",
  "Lab's admin" = "a003b0f5-8cb4-4acb-82a4-c5ef38d6e9d8",
  "Courier" = "e2c198fb-c8a9-4695-96f4-d90c51b6cea4",
}

export enum CaseStatusEnum {
  "Empty" = "49fa38fc-b4df-417c-beb2-b8aa57e0ef13",
  "New" = "15616f93-5ad0-4b22-a3c1-a716f1b5520a",
  "Accepted" = "c91d69c4-59b7-4f37-bd3c-1eb06b95fa66",
  "Started" = "b2ee7744-bf8a-4958-b99a-6a8e17f3d1ef",
  "Finished" = "1b64a3dc-c669-4935-8b47-f00e14791ebd",
  "Ready" = "4729ef9b-5820-4b3c-a82c-649e9dd49230",
}

export enum TrackingStatusEnum {
  "Empty" = "124fb870-b118-4a5e-8ccf-d77bd8555356",
  "Waiting for pickup" = "13c116be-26b1-4f15-aba1-08f7b3f898a5",
  "Heading to the lab" = "83fbc24d-a7a0-4c05-ae3e-fc0f6aa953d7",
  "At lab" = "73971225-7585-4575-a12f-4f086641db08",
  "Heading to the clinic" = "9a33656d-dff4-4ff5-817f-ab99a15a2d5f",
  "Delivered" = "251fa44d-7561-4d98-b94c-7526857382aa",
}

export enum TaskStatusEnum {
  "New"="947effc1-ef77-4aa4-88e5-1e44011ab83f",
  "In progress"="632ebb25-0246-4795-b8b4-ff58bc91c209",
  "Completed"="762aa2ca-871a-4904-9976-ca434456249c",
}

export interface Metadata {
  version: string;
}

export type JawType = (typeof jaws)[number];
