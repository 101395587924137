export namespace Task {
  export interface Case {
    caseUUID: string;
    name: string;
    caseNumber: string;
    client: {
      clientUUID: string;
      name: string;
      address: string;
    };
    patient: string;
    status?: {
      statusUUID: string;
      name: string;
    };
  }

  export interface TrackingStatus {
    trackingStatusUUID: string;
    name: string;
  }

  export interface TaskStatus {
    taskStatusUUID: string;
    name: string;
  }

  export interface Courier {
    courierUUID: string;
    name: string;
  }
}

export interface Task {
  taskUUID: string;
  taskNumber: string;
  dueDate: string;
  completionDate: string;
  courier: Task.Courier;
  case: Task.Case;
  trackingStatus: Task.TrackingStatus;
  taskStatus: Task.TaskStatus;
}
