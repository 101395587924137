import { useEffect, useState } from "react";

import { ClientPricelist } from "@/root/models/pricelist";
import { ClientPricelistsApi } from "@/api/admin/clientPricelists";

const usePricelists = () => {
  const [pricelists, setPricelists] = useState<ClientPricelist[]>([]);
  const [getPricelistsLoading, setGetPricelistsLoading] = useState(false);
  const [getPricelistsError, setGetPricelistsError] = useState("");

  useEffect(() => {
    (async () => {
      try {
        setGetPricelistsLoading(true);
        const pricelists = await ClientPricelistsApi.getPricelists();
        setPricelists(pricelists);
      } catch (e: any) {
        setGetPricelistsError(e.message);
      } finally {
        setGetPricelistsLoading(false);
      }
    })();
  }, []);

  const createPricelist = (pricelist: ClientPricelist) => {
    setPricelists((prev) => [...prev, pricelist]);
  };

  const deletePricelist = (id: string) => {
    setPricelists((prev) => prev.filter((p) => p.clientsPricelistUUID !== id));
  };

  const editPricelist = (id: string, pricelist: ClientPricelist) => {
    setPricelists((prev) =>
      prev.map((prevPricelist) => {
        if (prevPricelist.clientsPricelistUUID === id) {
          return pricelist;
        }
        return prevPricelist;
      })
    );
  };

  return {
    pricelists,
    getPricelistsLoading,
    getPricelistsError,
    createPricelist,
    deletePricelist,
    editPricelist,
  };
};

export default usePricelists;
