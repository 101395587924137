import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { Product } from "@/root/models/product";

export interface CreateProductPayload {
  parentProductUUID: string;
  productsParametersGroups: string[];
  name: string;
  isPoint: boolean;
  isGroup: boolean;
  priceFormulaUUID?: string;
  groupsColor?: string;
}

export const ProductsApi = {
  getProducts: async (): Promise<Product[]> => {
    const data = await makeRequest<Product[]>({
      url: apiBaseurl + "/products",
    });
    const parseData = (data: Product[], parentProductUUID: string) => {
      return data.map((p) => {
        p.parentProductUUID = parentProductUUID;
        if (p.children) {
          p.children = parseData(p.children, p.productUUID);
        }

        return p;
      });
    };

    return parseData(data, "");
  },

  create: async (payload: CreateProductPayload): Promise<Product> => {
    const data = await makeRequest<Product>({
      url: apiBaseurl + "/products",
      method: "post",
      data: payload,
    });
    return { ...data, parentProductUUID: payload.parentProductUUID };
  },

  edit: async ({
    id,
    payload,
  }: {
    id: string;
    payload: CreateProductPayload;
  }): Promise<Product> => {
    const data = await makeRequest<Product>({
      url: apiBaseurl + `/products/${id}`,
      method: "put",
      data: payload,
    });
    return { ...data, parentProductUUID: payload.parentProductUUID };
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/products/${id}`,
      method: "delete",
    });
  },
};
