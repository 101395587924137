import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { User } from "@/root/models/user";

export interface CreateUserPayload {
  name: string;
  login: string;
  password: string;
  clientAdmin: boolean;
  userTypeUUID: string;
  disableAccess: boolean;
}

export const UsersApi = {
  getUsers: async (): Promise<User[]> => {
    const data = await makeRequest<User[]>({
      url: apiBaseurl + "/users",
    });
    return data;
  },

  create: async (payload: CreateUserPayload): Promise<User> => {
    const data = await makeRequest<User>({
      url: apiBaseurl + "/users",
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async ({id, payload}:{id: string, payload: CreateUserPayload}): Promise<User> => {
    const data = await makeRequest<User>({
      url: apiBaseurl + `/users/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/users/${id}`,
      method: "delete",
    });
  },
};
