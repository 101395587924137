import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "antd";
import { useForm } from "antd/es/form/Form";

import { CreateProductPayload } from "@/api/admin/products";
import { Product } from "@/root/models/product";
import { ProductForm } from "@/components";
import useAddProduct from "@/modules/Products/components/AddProductModal/useAddProduct";

interface AddProductModalProps {
  open: boolean;
  onCancel: () => void;
  onAdd: () => void;
  product?: Product;
}

const AddProductModal: FC<AddProductModalProps> = ({
  open,
  product,
  onCancel,
  onAdd,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const onSuccess = () => {
    form.resetFields();
    onAdd();
  };

  const {addProduct, error, loading} = useAddProduct(onSuccess)

  const handleAdd = async (values: CreateProductPayload) => {
    addProduct({
      ...values,
      parentProductUUID: product ? product.productUUID : "",
      productsParametersGroups: values.productsParametersGroups || []
    })
  };

  const addSubmit = () => {
    form.submit();
  };

  return (
    <Modal
      maskClosable={false}
      okText={t("Створити")}
      title={<div style={{ marginBottom: 20 }}>{t("Створення виробу")}</div>}
      open={open}
      onCancel={onCancel}
      onOk={addSubmit}
      okButtonProps={{ loading }}
    >
      <ProductForm
        form={form}
        handleSubmit={handleAdd}
        error={error}
        groupDisabled={product ? product.level === 4 : false}
      />
    </Modal>
  );
};

export default AddProductModal;
