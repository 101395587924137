import { FC } from "react";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Alert, Form, FormInstance, Select } from "antd";

import { CreateTaskPayload } from "@/api/tasks";
import useTaskStatuses from "@/hooks/useTaskStatuses";
import useTrackingStatuses from "@/hooks/useTrackingStatuses";

dayjs.extend(weekday);
dayjs.extend(localeData);

interface TaskFormProps {
  form: FormInstance<CreateTaskPayload>;
  handleSubmit: (values: CreateTaskPayload) => void;
  error: string;
}

const { Option } = Select;

const EditTaskForm: FC<TaskFormProps> = ({ form, handleSubmit, error }) => {
  const { t } = useTranslation();
  const { taskStatuses, getStatusesLoading: getTaskStatusesLoading } =
    useTaskStatuses();
  const { trackingStatuses, getStatusesLoading: getTrackingStatusesLoading } =
    useTrackingStatuses();

  const submit = (values: CreateTaskPayload) => {
    handleSubmit(values);
  };

  return (
    <Form
      labelCol={{ span: 6 }}
      requiredMark={false}
      form={form}
      onFinish={submit}
    >
      <Form.Item
        name="taskStatusUUID"
        label={t("Статус завдання")}
        rules={[{ required: true, message: "Missing last name" }]}
      >
        <Select loading={getTaskStatusesLoading}>
          {taskStatuses.map((status) => (
            <Option key={status.taskStatusUUID} value={status.taskStatusUUID}>
              {status.name || " "}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="trackingStatusUUID"
        label={t("Статус відстеження")}
        rules={[{ required: true, message: "Missing last name" }]}
      >
        <Select loading={getTrackingStatusesLoading}>
          {trackingStatuses.map((status) => (
            <Option
              key={status.trackingStatusUUID}
              value={status.trackingStatusUUID}
            >
              {status.name || " "}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {!!error && <Alert message={error} type="error" />}
    </Form>
  );
};

export default EditTaskForm;
