import { useQuery } from "@tanstack/react-query";

import { TrackingStatusesApi } from "@/api/trackingStatuses";

const useTrackingStatuses = () => {
  const {
    data: trackingStatuses,
    isLoading: getStatusesLoading,
    error: getStatusesError,
  } = useQuery({
    queryKey: ["trackingStatuses"],
    queryFn: TrackingStatusesApi.getStatuses,
  });

  return {
    trackingStatuses: trackingStatuses || [],
    getStatusesLoading,
    getStatusesError,
  };
};

export default useTrackingStatuses;
