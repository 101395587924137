import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useCaseFormContext } from "@/components/CaseForm/CaseFormContext";
import { TeethWidget } from "@/components";
import useProducts from "@/hooks/useProducts";
import { ChosenTooth } from "@/components/TeethWidget";
import CaseFormTooth from "./CaseFormTooth";
import { jaws } from "@/root/consts";
import { JawType } from "@/root/types";
import { getJawTeeth } from "@/utils/getJawTeeth";

export enum TeethFormulaTypeEnum {
  "child" = "53553231-e2b5-4d53-a0b4-cc9988082bd2",
  "adult" = "4fcd8b2e-435f-4a8d-ac4a-4f8ff2c25caa",
}

const Teeth: FC = () => {
  const { t } = useTranslation();
  const { state } = useCaseFormContext();
  const { getParameterGroup } = useProducts();

  const { job } = useMemo(() => {
    const job = state.jobs.find((job) => job.jobID === state.activeJobID);
    const jobProduct = job?.products.find(
      (product) => product.jobProductUUID === job.activeProductID
    );
    return { job, jobProduct };
  }, [state.jobs, state.activeJobID]);

  const isChildFormula = state.teethFormulaUUID === TeethFormulaTypeEnum.child;
  const isAdultFormula = state.teethFormulaUUID === TeethFormulaTypeEnum.adult;

  const chosenTeeth =
    job?.products.reduce<ChosenTooth[]>((sum, product) => {
      product.teeth &&
        sum.push(
          ...product.teeth.flatMap((tooth) => {
            if (jaws.includes(tooth as JawType)) {
              return getJawTeeth(tooth as JawType).map((toothID) => ({
                toothID: toothID,
                isPoint: product.product?.isPoint || false,
                crown: !!product?.combinedProduct,
                color: product?.teethColor || null,
                group: product.jobProductUUID,
                selected: job.selectedTeeth?.includes(toothID) || false,
              }));
            }
            return {
              toothID: tooth,
              isPoint: product.product?.isPoint || false,
              crown: !!product?.combinedProduct,
              color: product?.teethColor || null,
              group: product.jobProductUUID,
              selected: job.selectedTeeth?.includes(tooth) || false,
            };
          })
        );
      return sum;
    }, []) || [];

  const products = Array.from(
    new Set(
      job?.products.map((product) =>
        product.product ? getParameterGroup(product.product) : null
      )
    )
  );

  const legends = products.map((product) => ({
    name: product?.name || "",
    color: product?.groupsColor || "",
  }));

  return state.teethFormulaUUID && (isChildFormula || isAdultFormula) ? (
    <TeethWidget
      toothRender={(tooth) => <CaseFormTooth tooth={tooth} />}
      formulaType={
        state.teethFormulaUUID === TeethFormulaTypeEnum.child
          ? "child"
          : "adult"
      }
      chosenTeeth={chosenTeeth}
      legends={legends}
    />
  ) : (
    <span>{t("Оберіть тип формули")}</span>
  );
};
export default Teeth;
