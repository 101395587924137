import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Select, Space, Table } from "antd";

import useTasks from "@/hooks/useTasks";
import { Task } from "@/root/models/task";
import { CreateTask, TableActions } from "@/modules/Tasks/components";
import useCaseNumbers from "@/hooks/useCaseNumbers";
import { Block, Pagination } from "@/components/common";

interface TasksProps {
  caseUUID?: string;
  isCaseAccepted?: boolean;
}

const { Option } = Select;

const Tasks: FC<TasksProps> = ({ caseUUID, isCaseAccepted }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const { tasks, pages, getTasksLoading } = useTasks({
    caseUUID,
    page,
    pageLimit: 25,
  });
  const { caseNumbers, getCaseNumbersLoading } = useCaseNumbers();
  const [filterCaseUUID, setFilterCaseUUID] = useState<string>();

  const tasksColumn = [
    {
      title: t("Номер завдання"),
      dataIndex: "taskNumber",
      key: "taskNumber",
    },
    {
      title: t("Дата завершення"),
      dataIndex: "dueDate",
      key: "dueDate",
      render: (date: string) => <span>{date.slice(0, 16)}</span>,
    },
    {
      title: t("Дата виконання"),
      dataIndex: "completionDate",
      key: "completionDate",
      render: (date: string) => <span>{date.slice(0, 16)}</span>,
    },
    {
      title: t("Кур'єр"),
      dataIndex: "courier",
      key: "courier",
      render: (courier: Task.Courier) => <span>{courier.name}</span>,
    },
    {
      title: t("Номер відстеження"),
      dataIndex: "trackingNumber",
      key: "trackingNumber",
    },
    {
      title: t("Статус відстеження"),
      dataIndex: "trackingStatus",
      key: "trackingStatus",
      render: (trackingStatus: Task.TrackingStatus) => (
        <span>{trackingStatus.name}</span>
      ),
    },
    {
      title: t("Статус завдання"),
      dataIndex: "taskStatus",
      key: "taskStatus",
      render: (taskStatus: Task.TaskStatus) => <span>{taskStatus.name}</span>,
    },
    {
      title: t("Номер замовлення"),
      dataIndex: "case",
      key: "case",
      render: (medCase: Task.Case) => <span>{medCase.caseNumber}</span>,
    },
    {
      title: t("Контрагент"),
      dataIndex: "case",
      key: "case",
      render: (medCase: Task.Case) => <span>{medCase.client.name}</span>,
    },
    {
      title: t("Пацієнт"),
      dataIndex: "case",
      key: "case",
      render: (medCase: Task.Case) => <span>{medCase.patient}</span>,
    },
    {
      title: "",
      key: "action",
      render: (_: any, task: Task) => (
        <TableActions task={task} caseUUID={caseUUID} page={page} />
      ),
    },
  ];

  const filteredTasks = filterCaseUUID
    ? tasks.filter((task) => task.case.caseUUID === filterCaseUUID)
    : tasks;

  const tableRef = useRef<HTMLDivElement>(null);

  const paginationRange = tableRef.current
    ? Math.floor(
        (tableRef.current.getBoundingClientRect().width - 8 - 30 - 39 * 4) / 39
      )
    : 1;

  return (
    <Space direction="vertical">
      <Space>
        <CreateTask
          caseUUID={caseUUID}
          isCaseAccepted={isCaseAccepted}
          page={page}
        />
        <div>
          {t("Замовлення")}:{" "}
          <Select
            style={{ minWidth: 140 }}
            showSearch
            optionFilterProp="children"
            allowClear
            value={filterCaseUUID}
            onSelect={setFilterCaseUUID}
            onClear={() => setFilterCaseUUID(undefined)}
            loading={getCaseNumbersLoading}
          >
            {caseNumbers.map((medCase) => (
              <Option key={medCase.caseUUID} value={medCase.caseUUID}>
                {medCase.caseNumber}
              </Option>
            ))}
          </Select>
        </div>
      </Space>
      <Table
        ref={tableRef}
        columns={tasksColumn}
        dataSource={filteredTasks.map((p) => ({ ...p, key: p.taskUUID }))}
        pagination={false}
        loading={getTasksLoading}
      />
      <Block
        style={{
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination
          currentPage={page}
          pages={pages || 1}
          paginationRange={paginationRange}
          setCurrentPage={setPage}
        />
      </Block>
    </Space>
  );
};

export default Tasks;
