import React, { FC } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";

import { Alert } from "antd";

import styles from "./Jobs.module.scss";

import {
  ActionCreatorTypes,
  CaseFormState,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import ProductItem from "./Product";
import SkeletonProduct from "@/components/CaseJobs/Product/SkeletonProduct";

interface JobProps {
  withParameters: boolean;
  job: CaseFormState.Job;
  onDelete: () => void;
  onEdit: (value: string) => void;
}

const Job: FC<JobProps> = ({ withParameters, job }) => {
  const { state, dispatch } = useCaseFormContext();

  const addNewProduct = () => {
    if (job.products.length && !job.products.at(-1)?.product) {
      return;
    }

    dispatch({
      type: ActionCreatorTypes.AddProduct,
      payload: { activeJobID: job.jobID },
    });
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    ...draggableStyle,
  });

  const onDragEnd: OnDragEndResponder = (result) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    dispatch({
      type: ActionCreatorTypes.ReplaceProducts,
      payload: {
        jobID: job.jobID,
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index,
      },
    });
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              className={styles.products}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {job.products?.map((product, index) => (
                <Draggable
                  isDragDisabled={state.onlyDetailsEdit}
                  key={product.jobProductUUID}
                  draggableId={product.jobProductUUID}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <ProductItem
                        withParameters={withParameters}
                        job={job}
                        product={product}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div style={{ marginTop: 8 }} onClick={addNewProduct}>
        <SkeletonProduct />
      </div>
      {!!job.error && (
        <div className={styles.error}>
          <Alert type="error" message={job.error} />
        </div>
      )}
    </>
  );
};

export default Job;
