import { FC, useEffect, useMemo, useState } from "react";

import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import styles from "./ChooseProduct.module.scss";

import useProducts from "@/hooks/useProducts";
import { Product } from "@/root/models/product";
import { useCaseFormContext } from "@/components/CaseForm/CaseFormContext";
import ProductItem from "./Product";
import { filterProductsByName } from "@/components/LaboratoryCaseForm/components/ChooseProduct";
import { CatalogSearch } from "@/components";

interface ChooseProductProps {
  productsSearchValue?: string;
  withLocalSearch?: boolean;
  onSelect: (props: {
    product: Product;
    teethColor: string | undefined;
  }) => void;
}

const ChooseProduct: FC<ChooseProductProps> = ({
  onSelect,
  withLocalSearch = true,
  productsSearchValue,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { products, findProductByID, getParameterGroup } = useProducts();
  const { state } = useCaseFormContext();
  const [current, setCurrent] = useState<Product>();

  const filteredProducts = useMemo(() => {
    let filteredProducts = current ? [current] : products;

    if (withLocalSearch) {
      if (searchValue) {
        filteredProducts = filterProductsByName(
          filteredProducts,
          searchValue
        );
      }
    } else {
      if (productsSearchValue) {
        filteredProducts = filterProductsByName(
          filteredProducts,
          productsSearchValue
        );
      }
    }

    return filteredProducts;
  }, [withLocalSearch, searchValue, productsSearchValue, products, current]);

  const { jobProduct } = useMemo(() => {
    const job = state.jobs.find((job) => job.jobID === state.activeJobID);
    const jobProduct = job?.products.find(
      (product) => product.jobProductUUID === job.activeProductID
    );
    return { job, jobProduct };
  }, [state.jobs, state.activeJobID]);

  useEffect(() => {
    if (
      jobProduct &&
      current?.productUUID !== jobProduct.product?.productUUID
    ) {
      setCurrent(
        jobProduct.product
          ? findProductByID(jobProduct.product.parentProductUUID)
          : undefined
      );
    }
  }, [jobProduct]);

  const handleSelect = (product: Product) => {
    if (product.isGroup) {
      setCurrent(product);
    } else {
      const group = getParameterGroup(product);
      const teethColor = group.groupsColor;
      onSelect({ product, teethColor });
    }
  };

  const back = () => {
    if (current?.parentProductUUID) {
      setCurrent(findProductByID(current.parentProductUUID));
    } else {
      setCurrent(undefined);
    }
  };

  return (
    <div className={styles.container}>
      {withLocalSearch && <CatalogSearch onSearch={setSearchValue} />}
      {current ? (
        <div>
          <div className={styles.buttonContainer}>
            <div className={styles.colorContainer}>
              <div
                style={{
                  background: getParameterGroup(current).groupsColor,
                  width: 20,
                  height: 20,
                  borderRadius: 20,
                }}
              />
            </div>
            <Button type="text" icon={<LeftOutlined />} onClick={back}>
              {current.name}
            </Button>
          </div>
          <div className={styles.products}>
            {filteredProducts[0]?.children?.map((product) => (
              <ProductItem
                key={product.productUUID}
                product={product}
                onSelect={handleSelect}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.products}>
          {filteredProducts.map((product) => (
            <ProductItem
              key={product.productUUID}
              product={product}
              onSelect={handleSelect}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ChooseProduct;
