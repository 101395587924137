import React, {
  FC,
  FocusEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Checkbox,
  Dropdown,
  Input,
  InputNumber,
  Modal,
  Space,
} from "antd";
import { CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons";

import styles from "./Product.module.scss";

import {
  ActionCreatorTypes,
  CaseFormState,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import { Block } from "@/components/common";
import { ChooseProduct, Parameters } from "@/components/CaseForm/components";
import { Product } from "@/root/models/product";
import useClickOutside from "@/hooks/useClickOutside";

const { confirm } = Modal;

interface ProductProps {
  withParameters: boolean;
  job: CaseFormState.Job;
  product?: CaseFormState.Job.Product;
}

const ProductItem: FC<ProductProps> = ({ withParameters, job, product }) => {
  const { state, dispatch } = useCaseFormContext();
  const { t } = useTranslation();
  const activeProduct = job.products.find(
    (product) => product.jobProductUUID === job.activeProductID
  );
  const [productsSearchValue, setProductsSearchValue] = useState("");
  const [chooseProductOpen, setChooseProductOpen] = useState(false);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => setChooseProductOpen(false));

  useEffect(() => {
    if (!product?.product?.name) {
      return;
    }
    setProductsSearchValue(product?.product?.name);
  }, [job]);

  const addProduct = (selected: {
    product: Product;
    teethColor: string | undefined;
  }) => {
    dispatch({
      type: ActionCreatorTypes.AddProduct,
      payload: {
        product: selected.product,
        teethColor: selected.teethColor,
        activeJobID: job.jobID,
      },
    });
    setChooseProductOpen(false);
    setProductsSearchValue(selected.product.name);
  };

  const setProduct = (selected: {
    product: Product;
    teethColor: string | undefined;
  }) => {
    if (product) {
      dispatch({
        type: ActionCreatorTypes.SetProduct,
        payload: {
          productUUID: product.jobProductUUID,
          activeJobID: job.jobID,
          product: selected.product,
          teethColor: selected.teethColor,
        },
      });
    }
    setChooseProductOpen(false);
    setProductsSearchValue(selected.product.name);
  };

  const handleProductSelect = (selected: {
    product: Product;
    teethColor: string | undefined;
  }) => {
    if (product) {
      setProduct(selected);
    } else {
      addProduct(selected);
    }
  };

  const handleProductActive = () => {
    if (!product) {
      return;
    }
    dispatch({
      type: ActionCreatorTypes.SetActiveProduct,
      payload: {
        jobUUID: job.jobID,
        productUUID: product.jobProductUUID,
      },
    });
  };

  const handleDeleteClick = (e: MouseEvent) => {
    if (!product) {
      return;
    }

    e.stopPropagation();
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => {
        dispatch({
          type: ActionCreatorTypes.DeleteProduct,
          payload: {
            activeJobID: job.jobID,
            productUUID: product.jobProductUUID,
          },
        });
      },
    });
  };

  const handleQuantityChange = (quantity: number | null) => {
    if (!product) {
      return;
    }

    if (!quantity || quantity < 0) {
      return;
    }

    dispatch({
      type: ActionCreatorTypes.SetProductQuantity,
      payload: {
        activeProductUUID: product.jobProductUUID,
        quantity,
      },
    });
  };

  const handleCombinedProductCheck = (checked: boolean) => {
    if (!product) {
      return;
    }
    dispatch({
      type: ActionCreatorTypes.SetCombinedProductChecked,
      payload: {
        checked,
        activeJobID: job.jobID,
        activeProductID: product.jobProductUUID,
      },
    });
  };

  const handleInputFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.value === product?.product?.name) {
      setProductsSearchValue("");
    }
    setChooseProductOpen(true);
  };

  const isActive = product
    ? state.activeJobID === job.jobID && activeProduct === product
    : false;

  useEffect(() => {
    if (!chooseProductOpen) {
      product?.product && setProductsSearchValue(product?.product?.name);
    }
  }, [chooseProductOpen]);

  return (
    <Block
      style={{
        background: isActive && !withParameters ? "#e6f4ff" : undefined,
      }}
      onClick={handleProductActive}
    >
      <div className={styles.container}>
        <Space className={styles.jobInputContainer}>
          <Dropdown
            disabled={withParameters || state.onlyDetailsEdit}
            overlayClassName={styles.productDropdown}
            open={chooseProductOpen}
            destroyPopupOnHide
            dropdownRender={() => (
              <div ref={wrapperRef}>
                <Block>
                  <ChooseProduct
                    withLocalSearch={false}
                    productsSearchValue={productsSearchValue}
                    onSelect={handleProductSelect}
                  />
                </Block>
              </div>
            )}
          >
            <Input
              allowClear={{
                clearIcon: (
                  <CloseCircleOutlined
                    onClick={() => setChooseProductOpen(true)}
                  />
                ),
              }}
              placeholder={t("Оберіть виріб, або клікніть на зуб") as string}
              disabled={withParameters || state.onlyDetailsEdit}
              className={styles.productName}
              value={productsSearchValue}
              onChange={(e) => setProductsSearchValue(e.target.value)}
              onFocus={handleInputFocus}
            />
          </Dropdown>
        </Space>
        {product && (
          <Space>
            <span style={{ whiteSpace: "nowrap" }}>{t("к-сть")}:</span>
            <InputNumber
              disabled={withParameters || state.onlyDetailsEdit}
              style={{ width: 50 }}
              type="number"
              size="small"
              value={product.quantity}
              onChange={handleQuantityChange}
              onClick={(e) => e.stopPropagation()}
            />
            <Button
              disabled={withParameters || state.onlyDetailsEdit}
              size="small"
              icon={<DeleteOutlined />}
              onClick={handleDeleteClick}
            />
          </Space>
        )}
      </div>
      {product?.teeth && (
        <div style={{ margin: "4px 0" }}>
          {t("Зуби")}: {product.teeth.map((tooth) => tooth.slice(4)).join(", ")}
        </div>
      )}
      {product && !withParameters && (
        <div>
          <Checkbox
            disabled={state.onlyDetailsEdit}
            checked={product.combinedProduct}
            onChange={(e) => handleCombinedProductCheck(e.target.checked)}
          >
            {t("Комбінований виріб")}
          </Checkbox>
        </div>
      )}
      {withParameters && product && (
        <>
          <Parameters
            activeJobID={job.jobID}
            activeTooth={product.selectedTooth || null}
            product={product}
          />
        </>
      )}
    </Block>
  );
};

export default ProductItem;
