import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CreateUserPayload, UsersApi } from "@/api/admin/users";
import { User } from "@/root/models/user";

const useEditUser = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const editUserMutation = useMutation({
    mutationFn: UsersApi.edit,
    onSuccess: (user: User) => {
      queryClient.setQueryData(["users"], (prev: User[] | undefined) => {
        onSuccess();
        return (prev || []).map((prevUser) => {
          if (prevUser.userUUID === user.userUUID) {
            return user;
          }
          return prevUser;
        });
      });
    },
  });

  const editUser = (id: string, payload: CreateUserPayload) => {
    editUserMutation.mutate({ id, payload });
  };

  const error = (editUserMutation.error as Error)?.message as string;

  return { editUser, loading: editUserMutation.isLoading, error };
};

export default useEditUser;
