import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, notification, Space } from "antd";
import { useForm } from "antd/es/form/Form";

import { ProductParameterForm } from "@/components";
import {
  CreateProductParameterPayload,
  ProductsParametersApi,
} from "@/api/admin/productsParameters";
import { ProductParameter } from "@/root/models/productParameter";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const { confirm } = Modal;

interface TableActionsProps {
  parameter: ProductParameter;
  onDelete: (id: string) => void;
  onEdit: (id: string, parameter: ProductParameter) => void;
}

const TableActions: FC<TableActionsProps> = ({
  parameter,
  onDelete,
  onEdit,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [modal, setModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editError, setEditError] = useState("");

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      await ProductsParametersApi.delete(parameter.productsParameterUUID);
      onDelete(parameter.productsParameterUUID);
    } catch (e: any) {
      notification.error({
        message: e.message,
      });
    } finally {
      setDeleteLoading(true);
    }
  };

  const handleEdit = async (values: CreateProductParameterPayload) => {
    try {
      setEditLoading(true);
      setEditError("");
      const data = {
        ...values,
        defaultValue: values.defaultValue || "",
        list: values.list
          ? values.list.map((i) => ({
              value: i.value,
              filtersValues: i.filtersValues || [],
            }))
          : [],
        filtersValues: values.filtersValues || [],
      };
      const newClient = await ProductsParametersApi.edit(
        parameter.productsParameterUUID,
        data
      );
      onEdit(parameter.productsParameterUUID, newClient);
      setModal(false);
    } catch (e: any) {
      setEditError(e.message);
    } finally {
      setEditLoading(false);
    }
  };

  const submit = () => {
    form.submit();
  };

  const openModal = () => {
    form.setFieldsValue({
      name: parameter.name,
      productsParameterTypeUUID: parameter.type.productsParameterTypeUUID,
      filtersValues: parameter.filtersValues?.map((value) => value.filterUUID),
      defaultValue: parameter.defaultValue,
      list: parameter.list?.map((item) => ({
        value: item.value,
        filtersValues: item.filtersValues.map((value) => value.filterUUID),
      })),
    });
    setModal(true);
  };

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  return (
    <Space className="table-actions" size="middle">
      <Button size="small" icon={<EditOutlined />} onClick={openModal} />
      <Button
        size="small"
        icon={<DeleteOutlined />}
        loading={deleteLoading}
        danger
        onClick={handleDeleteButtonClick}
      />
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={
          <div style={{ marginBottom: 20 }}>{t("Редагування параметра")}</div>
        }
        open={modal}
        onCancel={() => setModal(false)}
        onOk={submit}
        okButtonProps={{ loading: editLoading }}
      >
        <ProductParameterForm
          form={form}
          handleSubmit={handleEdit}
          error={editError}
        />
      </Modal>
    </Space>
  );
};

export default TableActions;
