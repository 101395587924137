import React, { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox, Form, Radio } from "antd";

import styles from "./Results.module.scss";

import { Block } from "@/components/common";
import { useCaseFormContext } from "@/components/CaseForm/CaseFormContext";
import ProductItem from "./ProductItem";
import { Description } from "@/components/CaseForm/components";
import { FileUpload } from "@/components";

const Results: FC = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState("results");
  const { state } = useCaseFormContext();
  const [displayParameters, setShowParameter] = useState(true);

  return (
    <div style={{ flex: 1 }}>
      <Radio.Group
        value={tab}
        onChange={(e) => setTab(e.target.value)}
        style={{ marginBottom: 8 }}
      >
        <Radio.Button value="results">{t("Результати")}</Radio.Button>
        <Radio.Button value="description">{t("Опис")}</Radio.Button>
        <Radio.Button value="files">{t("Файли")}</Radio.Button>
      </Radio.Group>
      {tab === "results" && (
        <Block className={styles.block}>
          <Checkbox
            checked={displayParameters}
            onChange={(e) => setShowParameter(e.target.checked)}
          >
            {t("Включити параметри")}
          </Checkbox>
          <div>
            {t("Опис")}: {state.description}
          </div>
          {state.jobs.map((job) => (
            <Fragment key={job.jobID}>
              {job.products.map((product) => (
                <ProductItem
                  displayParameters={displayParameters}
                  key={product.jobProductUUID}
                  product={product}
                />
              ))}
            </Fragment>
          ))}
        </Block>
      )}
      {tab === "description" && (
        <Block className={styles.block}>
          <Description />
        </Block>
      )}
      {tab === "files" && (
        <Block className={styles.block}>
          <Form.Item name="files">
            <FileUpload />
          </Form.Item>
        </Block>
      )}
    </div>
  );
};

export default Results;
