import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, notification, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { Case } from "@/root/models/case";
import { CasesApi } from "@/api/client/cases";
import { UserTypeEnum } from "@/root/types";
import { useAppSelector } from "@/hooks/redux";

const { confirm } = Modal;

interface TableActionsProps {
  medCase: Case;
  direction?: "horizontal" | "vertical";
  onDelete: (id: string) => void;
  onEditButtonClick: (medCase: Case) => void;
}

const TableActions: FC<TableActionsProps> = ({
  medCase,
  direction,
  onDelete,
  onEditButtonClick,
}) => {
  const { t } = useTranslation();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const userType = useAppSelector((state) => state.userReducer.user?.userType);

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      await CasesApi.delete(medCase.caseUUID);
      onDelete(medCase.caseUUID);
    } catch (e: any) {
      notification.error({
        message: e.message,
      });
    } finally {
      setDeleteLoading(true);
    }
  };

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  return (
    <Space className="table-actions" direction={direction} size="middle">
      <Button
        size="small"
        icon={<EditOutlined />}
        onClick={() => onEditButtonClick(medCase)}
      />
      {userType?.userTypeUUID !== UserTypeEnum["Client's employee"] && (
        <Button
          size="small"
          icon={<DeleteOutlined />}
          loading={deleteLoading}
          danger
          onClick={handleDeleteButtonClick}
        />
      )}
    </Space>
  );
};

export default TableActions;
