import axios from "axios";

import { getAuthToken } from "@/utils/tokenHelpers";
import { MakeRequest, ResponseDataWithPagination } from "./types";

axios.interceptors.request.use((config) => {
  const data = getAuthToken();

  if (data.token && config.headers) {
    // @ts-ignore
    config.headers.token = data.token;
  }

  return config;
});

export default async <R, P extends boolean = false>(
  {
    url = "/",
    method = "get",
    params = {},
    data,
    headers = {},
    responseType = "json",
    onUploadProgress,
  }: MakeRequest,
  config?: { pagination: boolean }
): Promise<P extends true ? ResponseDataWithPagination<R> : R> => {
  try {
    const response = await axios({
      url,
      method,
      params,
      data,
      headers,
      responseType,
      onUploadProgress,
    });

    if (response.data.status === "error") {
      throw new Error(response.data.data);
    }

    return config?.pagination
      ? {
          data: response.data.data,
          page: response.data.page,
          pages: response.data.pages,
        }
      : response.data.data;
  } catch (e: any) {
    if (e?.response?.status === 401) {
      window.location.href = "/login";
    }
    throw new Error(e.message || "Невідома помилка");
  }
};
