import { FC } from "react";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Alert, DatePicker, Form, FormInstance, Input } from "antd";

import { CreatePatientPayload } from "@/api/patients";
import useFormAutoFocusInput from "@/hooks/useFormAutoFocusInput";

dayjs.extend(weekday);
dayjs.extend(localeData);

interface PatientFormProps {
  form: FormInstance<CreatePatientPayload>;
  handleSubmit: (values: CreatePatientPayload) => void;
  error: string;
}

const PatientForm: FC<PatientFormProps> = ({ form, handleSubmit, error }) => {
  const { t } = useTranslation();
  const focusInputRef = useFormAutoFocusInput();

  return (
    <Form requiredMark={false} form={form} onFinish={handleSubmit}>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Введіть ім'я") as string,
          },
        ]}
        name="name"
        label={t("Ім'я")}
      >
        <Input
          ref={focusInputRef}
          placeholder={t("Введіть значення") as string}
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Введіть дату") as string,
          },
        ]}
        name="birthDate"
        label={t("Дата народження")}
      >
        <DatePicker
          format="DD.MM.YYYY"
          placeholder={t("Виберіть дату") as string}
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Введіть e-mail") as string,
          },
        ]}
        required
        name="mail"
        label="E-mail"
      >
        <Input placeholder={t("Введіть значення") as string} />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Введіть номер телефону",
          },
        ]}
        required
        name="phone"
        label={t("Телефон")}
      >
        <Input required placeholder={t("Введіть значення") as string} />
      </Form.Item>

      {!!error && <Alert message={error} type="error" />}
    </Form>
  );
};

export default PatientForm;
