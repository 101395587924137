import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { MenuProps } from "antd";
import {
  FileSearchOutlined,
  FundOutlined,
  PieChartOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import { MainPanel, MyCompany, CasesPanel, ReportsPanel} from "@/modules";
import { DashboardPage } from "@/pages";
import { pages } from "@/root/consts";

const ClientDashboardPage: FC = () => {
  const { t } = useTranslation();

  const navigation: MenuProps["items"] = [
    {
      key: pages.client.dashboard.path,
      label: t("Головна панель"),
      icon: <PieChartOutlined />,
    },
    {
      key: pages.client.cases.path,
      label: t("Замовлення"),
      icon: <FileSearchOutlined />,
    },
    {
      key: pages.client.myCompany.path,
      label: t("Моя компанія"),
      icon: <SettingOutlined />,
    },
    {
      key: pages.client.reports.path,
      label: t("Звіти"),
      icon: <FundOutlined />,
    },
  ];

  return (
    <DashboardPage navigation={navigation}>
      <Routes>
        <Route path="dashboard/*" element={<MainPanel />} />
      </Routes>
      <Routes>
        <Route path="cases/*" element={<CasesPanel />} />
      </Routes>
      <Routes>
        <Route path="my-company/*" element={<MyCompany />} />
      </Routes>
      <Routes>
        <Route path="reports/*" element={<ReportsPanel />} />
      </Routes>
    </DashboardPage>
  );
};

export default ClientDashboardPage;
