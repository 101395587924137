import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "antd";
import { useForm } from "antd/es/form/Form";

import { CreateTaskPayload } from "@/api/tasks";
import { TaskForm } from "@/components";
import useTasks from "@/hooks/useTasks";
import { TrackingStatusEnum } from "@/root/types";

interface CreateTaskModalProps {
  open: boolean;
  onCancel: () => void;
  caseUUID?: string;
  isCaseAccepted?: boolean;
  page?: number
}

const CreateTaskModal: FC<CreateTaskModalProps> = ({
  open,
  onCancel,
  caseUUID,
  isCaseAccepted,
  page
}) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const onCreateSuccess = () => {
    onCancel();
    form.resetFields();
  };

  const {
    createTask,
    createLoading: loading,
    createError: error,
  } = useTasks({ caseUUID, onCreateSuccess, page, pageLimit: 25 });

  const handleCreate = async (values: CreateTaskPayload) => {
    createTask(values);
  };

  const submit = () => {
    form.submit();
  };

  useEffect(() => {
    if (form && open) {
      form.setFieldsValue({
        caseUUID,
        trackingStatusUUID:
          isCaseAccepted && TrackingStatusEnum["Waiting for pickup"],
      });
    }
  }, [form, open]);

  return (
    <Modal
      maskClosable={false}
      okText={t("Створити")}
      title={<div style={{ marginBottom: 20 }}>{t("Створення завдання")}</div>}
      open={open}
      onCancel={onCancel}
      onOk={submit}
      okButtonProps={{ loading }}
    >
      <TaskForm
        form={form}
        handleSubmit={handleCreate}
        error={error}
        isCaseUUIDDisabled={!!caseUUID}
      />
    </Modal>
  );
};

export default CreateTaskModal;
