import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, notification, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { User } from "@/root/models/user";
import { CreateUserPayload } from "@/api/admin/users";
import UserForm from "@/components/UserForm";
import useDeleteUser from "@/modules/Users/components/TableActions/useDeleteUser";
import useEditUser from "@/modules/Users/components/TableActions/useEditUser";

const { confirm } = Modal;

interface TableActionsProps {
  user: User;
}

const TableActions: FC<TableActionsProps> = ({ user }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [modal, setModal] = useState(false);

  const onDeleteError = (message: string) => {
    notification.error({
      message: message,
    });
  };

  const onEditSuccess = () => setModal(false);

  const { deleteUser, loading: deleteLoading } = useDeleteUser(onDeleteError);
  const {
    editUser,
    loading: editLoading,
    error: editError,
  } = useEditUser(onEditSuccess);

  const handleEdit = async (values: CreateUserPayload) => {
    editUser(user.userUUID, values);
  };

  const submit = () => {
    form.submit();
  };

  const openModal = () => {
    form.setFieldsValue({
      ...user,
      userTypeUUID: user.userType.userTypeUUID,
    });
    setModal(true);
  };

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => deleteUser(user.userUUID),
    });
  };

  return (
    <Space className="table-actions" size="middle">
      <Button size="small" icon={<EditOutlined />} onClick={openModal} />
      <Button
        size="small"
        icon={<DeleteOutlined />}
        loading={deleteLoading}
        danger
        onClick={handleDeleteButtonClick}
      />
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={
          <div style={{ marginBottom: 20 }}>
            {t("Редагування співробітника")}
          </div>
        }
        open={modal}
        onCancel={() => setModal(false)}
        onOk={submit}
        okButtonProps={{ loading: editLoading }}
      >
        <UserForm form={form} handleSubmit={handleEdit} error={editError} />
      </Modal>
    </Space>
  );
};

export default TableActions;
