import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, notification, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

import { Pan } from "@/root/models/pan";
import useDeletePan from "./useDeletePan";
import useEditPan from "./useEditPan";
import { PanForm } from "@/components";
import { CreatePanPayload } from "@/api/admin/pans";

const { confirm } = Modal;

interface TableActionsProps {
  pan: Pan;
}

const TableActions: FC<TableActionsProps> = ({ pan }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [modal, setModal] = useState(false);

  const onEditSuccess = () => {
    setModal(false);
  };

  const onDeleteError = (message: string) => {
    notification.error({
      message: message,
    });
  };

  const { deletePan, loading: deleteLoading } = useDeletePan(onDeleteError);
  const {
    editPan,
    loading: editLoading,
    error: editError,
  } = useEditPan(onEditSuccess);

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => deletePan(pan.panUUID),
    });
  };

  const handleEdit = (payload: CreatePanPayload) => {
    editPan(pan.panUUID, payload);
  };

  const submit = () => {
    form.submit();
  };

  const openModal = () => {
    form.setFieldsValue(pan);
    setModal(true);
  };

  return (
    <Space className="table-actions" size="middle">
      <Button size="small" icon={<EditOutlined />} onClick={openModal} />
      <Button
        size="small"
        icon={<DeleteOutlined />}
        loading={deleteLoading}
        danger
        onClick={handleDeleteButtonClick}
      />
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={<div style={{ marginBottom: 20 }}>{t("Редагування лотка")}</div>}
        open={modal}
        onCancel={() => setModal(false)}
        onOk={submit}
        okButtonProps={{ loading: editLoading }}
      >
        <PanForm form={form} handleSubmit={handleEdit} error={editError} />
      </Modal>
    </Space>
  );
};

export default TableActions;
