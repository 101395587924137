import React, { FC, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dropdown, MenuProps } from "antd";

import { ITooth } from "@/components/TeethWidget/components/Tooth";
import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import { Tooth } from "@/components/TeethWidget/components";
import ClickDropdown from "@/components/CaseForm/components/Teeth/ClickDropdown";
import ChooseProduct from "../../ChooseProduct";
import { Block } from "@/components/common";
import useClickOutside from "@/hooks/useClickOutside";
import { Product } from "@/root/models/product";

interface CaseFormToothProps {
  tooth: ITooth;
}

const CaseFormTooth: FC<CaseFormToothProps> = ({ tooth }) => {
  const { t } = useTranslation();
  const { state, dispatch } = useCaseFormContext();
  const [chooseProductModal, setChooseProductModal] = useState<
    "copy" | "move" | "change" | null
  >(null);

  const { job, jobProduct } = useMemo(() => {
    const job = state.jobs.find((job) => job.jobID === state.activeJobID);
    const jobProduct = job?.products.find(
      (product) => product.jobProductUUID === job.activeProductID
    );
    return { job, jobProduct };
  }, [state.jobs, state.activeJobID]);

  const handleClick = () => {
    if (state.onlyDetailsEdit) {
      return;
    }

    const toothInJaw =  jobProduct?.product &&
      (jobProduct.teeth?.includes(tooth.id) ||
        ((jobProduct.teeth?.includes("FDI_AUJ") ||
            jobProduct.teeth?.includes("FDI_CUJ")) &&
          tooth.jaw === "upper") ||
        ((jobProduct.teeth?.includes("FDI_ALJ") ||
            jobProduct.teeth?.includes("FDI_CLJ")) &&
          tooth.jaw === "lower"))

    if (
      toothInJaw
    ) {
      dispatch({
        type: ActionCreatorTypes.SetJobSelectedTooth,
        payload: { tooth: tooth.id },
      });
      return;
    }

    // if upper or lower jaw is chosen, can't choose tooth from this jaw
    if (
      (tooth.jaw === "upper" && jobProduct?.teeth?.includes("FDI_AUJ")) ||
      jobProduct?.teeth?.includes("FDI_CUJ")
    ) {
      return;
    }

    if (
      (tooth.jaw === "lower" && jobProduct?.teeth?.includes("FDI_ALJ")) ||
      jobProduct?.teeth?.includes("FDI_CLJ")
    ) {
      return;
    }

    dispatch({
      type: ActionCreatorTypes.SetTooth,
      payload: { tooth: tooth.id },
    });
  };

  const items: MenuProps["items"] = [
    {
      label: t("Перемкнути властивість комбінованого продукту"),
      key: "1",
      onClick: () => {
        if (!jobProduct) {
          return;
        }

        dispatch({
          type: ActionCreatorTypes.SetCombinedProductChecked,
          payload: {
            checked: !jobProduct.combinedProduct,
          },
        });
      },
    },
    {
      label: t("Обрати або змінити виріб"),
      onClick: () => {
        setChooseProductModal("change");
      },
      key: "6",
    },
    {
      label: t("Додати новий виріб"),
      key: "2",
      onClick: () => {
        if (!job) {
          return;
        }

        dispatch({
          type: ActionCreatorTypes.AddProduct,
          payload: {
            activeJobID: job.jobID,
          },
        });
      },
    },
    {
      label: t("Видалити поточний виріб"),
      key: "4",
      onClick: () => {
        if (!job || !jobProduct) {
          return;
        }

        dispatch({
          type: ActionCreatorTypes.DeleteProduct,
          payload: {
            activeJobID: job.jobID,
            productUUID: jobProduct.jobProductUUID,
          },
        });
      },
    },
  ];

  job?.selectedTeeth?.includes(tooth.id) &&
    items.push(
      ...[
        {
          label: t("Видалити зуб з обраного виробу"),
          key: "7",
          onClick: () => {
            if (!job || !jobProduct) {
              return;
            }
            dispatch({
              type: ActionCreatorTypes.SetTooth,
              payload: {
                activeJobID: job.jobID,
                activeProductID: tooth.productUUID,
                tooth: tooth.id,
              },
            });
          },
        },
        {
          label: t("Перенести зуби в новий виріб"),
          key: "8",
          onClick: () => {
            setChooseProductModal("move");
          },
        },
        {
          label: t("Скопіювати зуби в новий виріб"),
          key: "9",
          onClick: () => {
            setChooseProductModal("copy");
          },
        },
      ]
    );

  const handleContextMenuOpen = (open: boolean) => {
    if (open && job && jobProduct?.product && !tooth.selected) {
      dispatch({
        type: ActionCreatorTypes.SetJobSelectedTooth,
        payload: {
          activeJobID: job.jobID,
          tooth: tooth.id,
        },
      });
    }
  };

  const handleSelect = ({
    product,
    teethColor,
  }: {
    product: Product;
    teethColor: string | undefined;
  }) => {
    if (!job) {
      return;
    }

    if (chooseProductModal === "copy") {
      dispatch({
        type: ActionCreatorTypes.AddProductWithJobSelectedTeeth,
        payload: {
          activeJobID: job.jobID,
          product,
          teethColor,
          move: false,
        },
      });
    } else if (chooseProductModal === "move") {
      dispatch({
        type: ActionCreatorTypes.AddProductWithJobSelectedTeeth,
        payload: {
          activeJobID: job.jobID,
          product,
          teethColor,
          move: true,
        },
      });
    } else if (chooseProductModal === "change") {
      dispatch({
        type: ActionCreatorTypes.SetProduct,
        payload: { product, teethColor },
      });
    }

    setChooseProductModal(null);
  };

  const chooseProductRef = useRef<HTMLDivElement>(null);

  useClickOutside(chooseProductRef, () => setChooseProductModal(null));

  return (
    <Dropdown
      menu={{ items }}
      trigger={["contextMenu"]}
      destroyPopupOnHide
      onOpenChange={handleContextMenuOpen}
    >
      <g>
        <Dropdown
          dropdownRender={() =>
            !jobProduct?.product ? <ClickDropdown /> : <div />
          }
          trigger={["click"]}
          destroyPopupOnHide
        >
          <Dropdown
            open={!!chooseProductModal}
            dropdownRender={() => (
              <div ref={chooseProductRef}>
                <Block>
                  <ChooseProduct onSelect={handleSelect} />
                </Block>
              </div>
            )}
            destroyPopupOnHide
          >
            <Tooth tooth={tooth} onClick={handleClick} />
          </Dropdown>
        </Dropdown>
      </g>
    </Dropdown>
  );
};

export default CaseFormTooth;
