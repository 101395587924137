import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Space, Table } from "antd";

import useUsers from "@/hooks/useUsers";
import { CreateUser, TableActions } from "@/modules/Users/components";
import { User } from "@/root/models/user";
import { UserType } from "@/root/models/userType";

const Users: FC = () => {
  const { t } = useTranslation();
  const { users, getUsersLoading } = useUsers();

  const usersColumn = [
    {
      title: t("Ім'я"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Пошта"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("Тип користувача"),
      dataIndex: "userType",
      key: "userType",
      render: (type: UserType) => <span>{type.name}</span>,
    },
    {
      title: t("Контрагент"),
      dataIndex: "client",
      key: "client",
      render: (client: User["client"]) => <span>{client?.name}</span>,
    },
    {
      title: "",
      key: "action",
      render: (_: any, user: User) => <TableActions user={user} />,
    },
  ];

  return (
    <Space direction="vertical">
      <CreateUser />
      <Table
        columns={usersColumn}
        dataSource={users.map((p) => ({ ...p, key: p.userUUID }))}
        pagination={false}
        loading={getUsersLoading}
      />
    </Space>
  );
};

export default Users;
