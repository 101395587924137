import React, { FC, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Layout, Menu, MenuProps } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { ItemType } from "antd/es/menu/hooks/useItems";

import styles from "./DashboardPage.module.scss";

import { apiBaseurl } from "@/api/baseurl";
import { logout } from "@/store/reducers/user/asyncActionCreators";
import { useAppDispatch } from "@/hooks/redux";
import { pages } from "@/root/consts";

interface DashboardPageProps {
  navigation: MenuProps["items"];
  children: ReactNode;
}

const DashboardPage: FC<DashboardPageProps> = ({ navigation, children }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const logoutItem: ItemType = {
    key: "logout",
    label: t("Вийти"),
    icon: <LogoutOutlined />,
  };

  const handleSelect = (key: string) => {
    if (key === "logout") {
      dispatch(logout());
      navigate(pages.login.path);
    } else {
      if (key.includes('dashboard')) {
        setCollapsed(true)
      }
      navigate(key);
    }
  };

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
        theme="light"
        collapsed={collapsed}
      >
        <Button
          className={styles.collapseButton}
          size="small"
          onClick={toggleCollapsed}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
        {collapsed ? null : (
          <img
            style={{ minHeight: 110 }}
            className={styles.logo}
            src={apiBaseurl + "/logo"}
            onError={(e) => {
              // @ts-ignore
              e.target.style.display = "none";
            }}
            alt="logo"
          />
        )}
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[location.pathname]}
          items={navigation && [...navigation, logoutItem]}
          onSelect={(item) => handleSelect(item.key)}
        />
      </Sider>
      <Layout
        style={{
          marginLeft: collapsed ? 80 : 200,
          minHeight: "100vh",
          transition: "ease 0.2s",
        }}
      >
        <Content className={styles.content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default DashboardPage;
