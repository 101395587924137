import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { Button, Modal, notification, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

import { Task } from "@/root/models/task";
import { CreateTaskPayload } from "@/api/tasks";
import { TaskForm } from "@/components";
import useTasks from "@/hooks/useTasks";

const { confirm } = Modal;

interface TableActionsProps {
  task: Task;
  direction?: "horizontal" | "vertical";
  caseUUID?: string;
  page?: number;
}

const TableActions: FC<TableActionsProps> = ({
  task,
  caseUUID,
  page,
  direction,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [modal, setModal] = useState(false);

  const onDeleteError = (message: string) => {
    notification.error({
      message,
    });
  };

  const onEditSuccess = () => {
    setModal(false);
  };

  const { deleteTask, deleteLoading, editTask, editLoading, editError } =
    useTasks({
      caseUUID,
      onDeleteError,
      onEditSuccess,
      page: page || 1,
      pageLimit: 25,
    });

  const handleDelete = async () => {
    await deleteTask(task.taskUUID);
  };

  const handleEdit = async (values: CreateTaskPayload) => {
    editTask(task.taskUUID, values);
  };

  const submit = () => {
    form.submit();
  };

  const openModal = () => {
    form.setFieldsValue({
      ...task,
      taskStatusUUID: task.taskStatus.taskStatusUUID,
      trackingStatusUUID: task.trackingStatus.trackingStatusUUID,
      caseUUID: task.case.caseUUID,
      courierUUID: task.courier.courierUUID,
      dueDate: task.dueDate && dayjs(new Date(task.dueDate)),
      completionDate:
        task.completionDate && dayjs(new Date(task.completionDate)),
    });
    setModal(true);
  };

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  return (
    <Space className="table-actions" direction={direction} size="middle">
      <Button size="small" icon={<EditOutlined />} onClick={openModal} />
      <Button
        size="small"
        icon={<DeleteOutlined />}
        loading={deleteLoading}
        danger
        onClick={handleDeleteButtonClick}
      />
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={
          <div style={{ marginBottom: 20 }}>{t("Редагування завдання")}</div>
        }
        open={modal}
        onCancel={() => setModal(false)}
        onOk={submit}
        okButtonProps={{ loading: editLoading }}
      >
        <TaskForm
          type="edit"
          form={form}
          handleSubmit={handleEdit}
          error={editError}
        />
      </Modal>
    </Space>
  );
};

export default TableActions;
