import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import {
  AdminPage,
  ClientPage,
  CourierPage,
  ErrorPage,
  LabPage,
  LoginPage,
} from "@/pages";

const Router: FC = () => {
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="client/*" element={<ClientPage />} />
      <Route path="admin/*" element={<AdminPage />} />
      <Route path="lab/*" element={<LabPage />} />
      <Route path="courier/*" element={<CourierPage />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default Router;
