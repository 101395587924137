import React, { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Form, Input, Radio } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import styles from "./Detailes.module.scss";

import { Block } from "@/components/common";
import {
  CaseDate,
  DueDate,
  SearchClientEmployee,
  SearchPatient,
} from "@/components/CaseForm/components";
import { isLaptop } from "@/utils/isLaptop";
import SelectTeethFormulaType from "@/components/CaseForm/components/SelectTeethFormulaType";
import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import useClickOutside from "@/hooks/useClickOutside";

const Details: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { state, dispatch } = useCaseFormContext();
  const [tab, setTab] = useState("case");
  const containerRef = useRef<HTMLDivElement>(null);

  const clickOutside = () => {
      const allInputsFilled =
        state.patient &&
        state.clientsEmployee &&
        state.arrival &&
        state.dueDate &&
        state.teethFormulaUUID;

      if (state.onlyDetailsEdit && allInputsFilled) {
        dispatch({
          type: ActionCreatorTypes.SetOnlyDetailsEdit,
          payload: false,
        });
      }
  };

  useClickOutside(containerRef, clickOutside, true);

  const isEditForm = location.pathname.endsWith("/cases/edit");

  const setCollapsed = (collapse: boolean) => {
    dispatch({
      type: ActionCreatorTypes.SetOnlyDetailsEdit,
      payload: collapse,
    });
  };

  const handleClick = () =>{
    if (!state.onlyDetailsEdit) {
      dispatch({
        type: ActionCreatorTypes.SetOnlyDetailsEdit,
        payload: true,
      });
    }
  }

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.collapse}>
        {state.onlyDetailsEdit ? (
          <UpOutlined onClick={() => setCollapsed(false)} />
        ) : (
          <DownOutlined onClick={() => setCollapsed(true)} />
        )}
      </div>
      <Radio.Group
        value={tab}
        onChange={(e) => setTab(e.target.value)}
        style={{ marginBottom: 8 }}
      >
        <Radio.Button value="case">{t("Замовлення_one")}</Radio.Button>
      </Radio.Group>
      {tab === "case" && (
        <Block className={styles.details} onClick={handleClick}>
          {state.onlyDetailsEdit ? (
            <>
              {isEditForm && (
                <Form.Item
                  style={{ width: "calc(100% - 35px)" }}
                  className={styles.formItem}
                  labelCol={{ span: 10 }}
                  label={t("Номер замовлення")}
                >
                  <Input value={state.caseNumber || undefined} disabled />
                </Form.Item>
              )}
              <SearchPatient />
              <SearchClientEmployee />
              {isLaptop() ? (
                <>
                  <CaseDate />
                  <DueDate />
                </>
              ) : (
                <div style={{ display: "flex", width: "100%" }}>
                  <CaseDate />
                  <DueDate />
                </div>
              )}
              <SelectTeethFormulaType />
              {isEditForm && (
                <Form.Item
                  style={{ width: "calc(100% - 35px)" }}
                  className={styles.formItem}
                  labelCol={{ span: 10 }}
                  label={t("Статус")}
                >
                  <Input value={state.status?.name || undefined} disabled />
                </Form.Item>
              )}
            </>
          ) : (
            <SearchPatient />
          )}
        </Block>
      )}
    </div>
  );
};

export default Details;
