import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal, notification, Upload } from "antd";
import { UploadFile, UploadProps } from "antd/es/upload/interface";
import { RcFile } from "antd/lib/upload";

import styles from "./FileUpload.module.scss";

import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import { FilesApi } from "@/api/files";

const { confirm } = Modal;

const FileUpload: FC = () => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const { state, dispatch } = useCaseFormContext();

  useEffect(() => {
    (async () => {
      if (state.attachments.length && state.caseUUID) {
        setFileList(
          await Promise.all(
            state.attachments.map(async (att) => {
              if (!state.caseUUID) {
                return {
                  uid: att.fileUUID,
                  name: att.filename,
                  status: "error",
                  url: "",
                };
              }
              const file = await FilesApi.getFile(state.caseUUID, att.fileUUID);
              const url = URL.createObjectURL(file);
              return {
                uid: att.fileUUID,
                name: att.filename,
                status: "done",
                url,
              };
            })
          )
        );
      }
    })();
  }, []);

  const handleCancel = () => setPreviewImage("");

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = URL.createObjectURL(file.originFileObj as Blob);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleUploadChange: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    setFileList(newFileList);
  };

  const isFileNameDuplicate = (file: RcFile) => {
    const isDuplicate = fileList.some(
      (item) => item.status !== "uploading" && item.name === file.name
    );
    if (isDuplicate) {
      notification.error({ message: t("Файл з таким ім'ям вже існує") });
      return true;
    }
    return false;
  };

  const uploadImage: UploadProps["customRequest"] = async ({
    onSuccess,
    onError,
    file,
    onProgress,
  }) => {
    try {
      const rcFile = file as RcFile;
      if (isFileNameDuplicate(rcFile)) {
        setFileList((prev) => prev.filter((f) => f.uid !== rcFile.uid));
        return;
      }
      const res = await FilesApi.createFile(rcFile, (event: any) => {
        onProgress?.({ percent: (event.loaded / event.total) * 100 });
      });
      onSuccess?.("Ok");
      dispatch({
        type: ActionCreatorTypes.AddAttachment,
        payload: { fileUUID: res.fileUUID, filename: rcFile.name },
      });
    } catch (error) {
      if (error instanceof Error) {
        onError?.(error);
      } else {
        onError?.(new Error(t("Невідома помилка") as string));
      }
    }
  };

  const handleDelete = async (file: UploadFile) => {
    const isConfirmed = await new Promise((res) => {
      confirm({
        title: t("Ви дійсно бажаєте видалити файл?"),
        okText: t("Так"),
        okType: "danger",
        cancelText: t("Ні"),
        onOk: () => {
          res(true);
        },
        onCancel: () => {
          res(false);
        },
      });
    });

    if (isConfirmed) {
      dispatch({
        type: ActionCreatorTypes.DeleteAttachment,
        payload: file.name,
      });
    } else {
      return false;
    }
  };

  return (
    <>
      <Upload
        disabled={state.onlyDetailsEdit}
        className={styles.uploadFiles}
        listType="picture-card"
        multiple
        fileList={fileList}
        customRequest={uploadImage}
        onPreview={handlePreview}
        onChange={handleUploadChange}
        onRemove={handleDelete}
      >
        {t("Завантажити файли")}
      </Upload>
      <Modal
        maskClosable={false}
        open={Boolean(previewImage)}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default FileUpload;
