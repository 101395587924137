import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingStatus } from "@/root/types";
import { User } from "@/root/models/user";

export type UserState = {
  user: User | null;
  loginLoading: LoadingStatus;
  loginError: string;
  getMeLoading: LoadingStatus;
  getMeError: string;
};

const initialState: UserState = {
  user: null,
  loginLoading: LoadingStatus.DEFAULT,
  loginError: "",
  getMeLoading: LoadingStatus.DEFAULT,
  getMeError: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoginLoading: (state, action: PayloadAction<LoadingStatus>) => {
      state.loginLoading = action.payload;
    },
    setLoginError: (state, action: PayloadAction<string>) => {
      state.loginError = action.payload;
    },
    setGetMeLoading: (state, action: PayloadAction<LoadingStatus>) => {
      state.getMeLoading = action.payload;
    },
    setGetMeError: (state, action: PayloadAction<string>) => {
      state.getMeError = action.payload;
    },
    setUserData: (state, action: PayloadAction<User>) => {
      state.user = {
        name: action.payload.name,
        userUUID: action.payload.userUUID,
        userType: action.payload.userType,
        client: action.payload.client,
        language: action.payload.language
      }
    },
    reset: () => initialState,
  },
});

export const {
  setLoginLoading,
  setLoginError,
  setUserData,
  setGetMeLoading,
  setGetMeError,
  reset: resetUserState,
} = userSlice.actions;

export default userSlice.reducer;
