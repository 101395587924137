import React, { FC, useReducer } from "react";
import { useTranslation } from "react-i18next";

import { Button, Form, Space } from "antd";
import { useForm } from "antd/es/form/Form";

import styles from "./CaseForm.module.scss";

import {
  ActionCreatorTypes,
  CaseFormContext,
  caseFormInitialState,
  caseFormReducer,
  CaseFormState,
} from "./CaseFormContext";
import { LeftPanel, MiddlePanel, Results } from "./components";
import { CreateCasePayload } from "@/api/client/cases";
import useFilters from "@/hooks/useFilters";
import formJobToJob from "./formJobToJob";

interface CaseFormProps {
  initialState?: CaseFormState;
  loading: boolean;
  onFinish: (data: CreateCasePayload, close: boolean) => void;
  onCancel: () => void;
}

const CaseForm: FC<CaseFormProps> = ({
  onFinish,
  onCancel,
  initialState,
  loading,
}) => {
  const { t } = useTranslation();
  const { filters } = useFilters();
  const [state, dispatch] = useReducer(
    caseFormReducer,
    initialState || caseFormInitialState
  );

  const [form] = useForm();

  const checkError = () => {
    for (let job of state.jobs as CaseFormState.Job[]) {
      for (let product of job.products) {
        if (!product.productsParametersGroups) {
          return {
            jobID: job.jobID,
            product: product.jobProductUUID,
            message: t("Оберіть виріб"),
          };
        } else if (!product.teeth?.length) {
          return {
            jobID: job.jobID,
            product: product.jobProductUUID,
            message: t("Оберіть зуби"),
          };
        } else {
          for (let group of product.productsParametersGroups) {
            for (let parameter of group.productsParameters) {
              if (parameter.required && !parameter.value) {
                const toothText = parameter.tooth ? ` ${parameter.tooth}` : "";
                return {
                  jobID: job.jobID,
                  message: t("Заповніть обов'язкові параметри") + toothText,
                };
              }
            }
          }
        }
      }
    }
  };

  const handleFinish = async (close: boolean = true) => {
    await form.validateFields();
    const error = checkError();
    if (error) {
      dispatch({ type: ActionCreatorTypes.SetError, payload: error });
      return;
    }
    const data = {
      clientUUID: state.client?.clientUUID,
      clientsEmployeeUUID: state.clientsEmployee?.clientsEmployeeUUID || null,
      activeHandlerUUID: state.activeHandler?.activeHandlerUUID,
      patientUUID: state.patient?.patientUUID,
      description: state.description,
      teethFormulaUUID: state.teethFormulaUUID,
      arrival: state.arrival,
      dueDate: state.dueDate,
      attachments: state.attachments.map((att) => ({
        ...att,
        description: state.description,
      })),
      jobs: state.jobs.map((job: CaseFormState.Job) =>
        formJobToJob(job, filters)
      ),
    } as CreateCasePayload;
    dispatch({ type: ActionCreatorTypes.SetSaveButtonActive, payload: false });
    onFinish(data, close);
  };

  return (
    <CaseFormContext.Provider value={{ state, dispatch }}>
      <Form
        form={form}
        className={styles.form}
        name="caseForm"
        requiredMark={false}
      >
        <Space className={styles.actionButtons}>
          <Button htmlType="button" disabled={loading} onClick={onCancel}>
            {t("Скасувати")}
          </Button>
          <Button
            disabled={!state.saveButtonActive}
            onClick={() => handleFinish(false)}
            loading={loading}
          >
            {t("Зберегти")}
          </Button>
          <Button onClick={() => handleFinish(true)} loading={loading}>
            {t("Зберегти і закрити")}
          </Button>
        </Space>
        <div className={styles.container}>
          <LeftPanel />
          <MiddlePanel />
          <Results />
        </div>
      </Form>
    </CaseFormContext.Provider>
  );
};

export default CaseForm;
